import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import {
  FORM_VALUE_ENUM,
  GENDER_OPTION_LIST,
  LANGUAGE_OPTION_LIST,
} from './constant';
import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import { SelectElem } from '../../common/select';
import { HeaderSecondary } from '../../common/header-secondary';
import { TIMEZONE_OPTION_LIST } from '../../data/user/timezone';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { FileItemUploadContainer } from '../file-item-upload';
import { AGE_OPTION_LIST, CITY_OPTION_LIST } from '../psycho-filter/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue?: Function;
  onChangeSelect: (name: string, values: any) => void;
  isUploading?: boolean;
  deleteAction: Function;
  isSuccessAction: boolean;
  photoUrl?: string;
  onRefetch: Function;
  isSuccessDelete: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setValue = () => {},
  setFieldValue,
  onChangeSelect,
  isUploading,
  deleteAction,
  isSuccessAction,
  photoUrl,
  onRefetch,
  isSuccessDelete,
}) => {
  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  const handleChange = (e: any) => {
    formik.handleChange(e);
  };

  return (
    <FormElem gap={7} onSubmit={formik.handleSubmit}>
      <HeaderSecondary
        titleTid="USER.USER.TITLE.NAME"
        prefixTid="USER.USER.TITLE.PREFIX"
        sufixTid="USER.USER.TITLE.SUFIX"
        postfixTid="USER.USER.TITLE.POSTFIX"
      />
      {isUploading && <LoaderElem />}
      {isLoading && <SkeletonFormElem />}
      {isSuccessDelete && (
        <AlertActionElem type="success" tid="USER.FILE.DELETE" />
      )}

      {isSuccess && (
        <>
          <ContentConteinerStyled>
            <TextElem
              tid="USER.USER.PHOTO_FROFILE"
              type="medium"
              size="main"
              color="buttonText"
            />
            <FileItemUploadContainer
              type="photo"
              defaultValue={photoUrl}
              onSuccess={onRefetch}
              onReset={() => {
                deleteAction();
                onRefetch();
              }}
              isDot
              title="COMMON.FILE.IMAGE_TITLE"
            />
          </ContentConteinerStyled>
          <ContentConteinerStyled>
            <TextElem
              tid="USER.USER.INFORMATION"
              type="medium"
              size="main"
              color="buttonText"
            />
            <GridElem spacing={4}>
              <DoubleContainerElem>
                <FieldTextElem
                  isDot
                  name={FORM_VALUE_ENUM.NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.USER.NAME"
                  placeholder="USER.USER.NAME_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.NAME)}
                />
                <FieldTextElem
                  isDot
                  type="number"
                  name={FORM_VALUE_ENUM.AGE}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={(e: any) => e.target.blur()}
                  title="USER.USER.AGE"
                  placeholder="USER.USER.AGE_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.AGE)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.AGE)}
                  error={isFieldError(FORM_VALUE_ENUM.AGE)}
                />
              </DoubleContainerElem>
              <DoubleContainerElem>
                <SelectElem
                  placeholder="USER.USER.GENDER_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.GENDER}
                  onChange={setFieldValue}
                  options={GENDER_OPTION_LIST}
                  title="USER.USER.GENDER"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.GENDER)}
                  error={isFieldError(FORM_VALUE_ENUM.GENDER)}
                  value={setValue(GENDER_OPTION_LIST, FORM_VALUE_ENUM.GENDER)}
                />
                <SelectElem
                  placeholder="USER.USER.TIMEZONE_PLACEHOLDER"
                  isDot
                  name={FORM_VALUE_ENUM.TIMEZONE}
                  onChange={setFieldValue}
                  options={TIMEZONE_OPTION_LIST}
                  title="USER.USER.TIMEZONE"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.TIMEZONE)}
                  error={isFieldError(FORM_VALUE_ENUM.TIMEZONE)}
                  value={setValue(
                    TIMEZONE_OPTION_LIST,
                    FORM_VALUE_ENUM.TIMEZONE,
                  )}
                />
              </DoubleContainerElem>

              <DoubleContainerElem>
                <FieldTextElem
                  isDot
                  type="phone"
                  name={FORM_VALUE_ENUM.PHONE}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.USER.PHONE"
                  placeholder="USER.USER.PHONE_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
                  error={isFieldError(FORM_VALUE_ENUM.PHONE)}
                />
                <SelectElem
                  isDot
                  name={FORM_VALUE_ENUM.LANGUAGE}
                  onChange={onChangeSelect}
                  options={LANGUAGE_OPTION_LIST}
                  // dynamic
                  isMulti
                  title="USER.PSYCHOLOGIST.LANGUAGE"
                  placeholder="USER.PSYCHOLOGIST.LANGUAGE_PLACEHOLDER"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.LANGUAGE)}
                  error={isFieldError(FORM_VALUE_ENUM.LANGUAGE)}
                  value={getFieldValue(FORM_VALUE_ENUM.LANGUAGE)}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  customComponents={{
                    MultiValueContainer: multiValueContainer,
                  }}
                />
              </DoubleContainerElem>

              <DoubleContainerElem>
                {/* <FieldTextElem
                  isDot
                  name={FORM_VALUE_ENUM.CITY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.PSYCHOLOGIST.CITY"
                  placeholder="USER.PSYCHOLOGIST.CITY_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.CITY)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
                  error={isFieldError(FORM_VALUE_ENUM.CITY)}
                /> */}
                <SelectElem
                  name={FORM_VALUE_ENUM.CITY}
                  onChange={setFieldValue}
                  title="USER.PSYCHOLOGIST.CITY"
                  placeholder="USER.PSYCHOLOGIST.CITY_PLACEHOLDER"
                  value={setValue(CITY_OPTION_LIST, FORM_VALUE_ENUM.CITY)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
                  error={isFieldError(FORM_VALUE_ENUM.CITY)}
                  options={CITY_OPTION_LIST}
                />
                <FieldTextElem
                  isDot
                  name={FORM_VALUE_ENUM.COUNTRY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.PSYCHOLOGIST.COUNTRY"
                  placeholder="USER.PSYCHOLOGIST.COUNTRY_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.COUNTRY)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.COUNTRY)}
                  error={isFieldError(FORM_VALUE_ENUM.COUNTRY)}
                />
              </DoubleContainerElem>
              <FieldTextElem
                name={FORM_VALUE_ENUM.ADDRESS_INFO}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="USER.USER.ADDRESS_INFO"
                placeholder="USER.USER.CONTACT_INFO_PLACEHOLDER"
                value={getFieldValue(FORM_VALUE_ENUM.ADDRESS_INFO)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.ADDRESS_INFO)}
                error={isFieldError(FORM_VALUE_ENUM.ADDRESS_INFO)}
              />
            </GridElem>
            {/* <TextElem
            tid="USER.USER.PSYCHO_INFORMATION"
            type="medium"
            size="main"
            color="buttonText"
          /> */}
            <GridElem spacing={4}>
              {/* <TextElem
                tid="USER.USER.ANIMAL_TITLE"
                type="medium"
                size="main"
                color="buttonText"
              />

              <TextElem
                tid="USER.USER.CARETAKER_INFORMATION"
                type="medium"
                size="main"
                color="buttonText"
              /> */}
              {/* <SelectElem
                name={FORM_VALUE_ENUM.NEED_LANGUAGE}
                onChange={onChangeSelect}
                options={LANGUAGE_OPTION_LIST}
                isMulti
                title="USER.USER.NEED_LANGUAGE"
                placeholder="USER.USER.NEED_LANGUAGE_PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.NEED_LANGUAGE)}
                error={isFieldError(FORM_VALUE_ENUM.NEED_LANGUAGE)}
                value={getFieldValue(FORM_VALUE_ENUM.NEED_LANGUAGE)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                customComponents={{ MultiValueContainer: multiValueContainer }}
              /> */}
              {/* <DoubleContainerElem>
                <SelectElem
                  placeholder="USER.USER.NEED_GENDER_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.NEED_GENDER}
                  onChange={setFieldValue}
                  options={GENDER_OPTION_LIST}
                  title="USER.USER.NEED_GENDER"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NEED_GENDER)}
                  error={isFieldError(FORM_VALUE_ENUM.NEED_GENDER)}
                  value={setValue(
                    GENDER_OPTION_LIST,
                    FORM_VALUE_ENUM.NEED_GENDER,
                  )}
                />
                <SelectElem
                  name={FORM_VALUE_ENUM.NEED_AGE}
                  onChange={setFieldValue}
                  title="USER.USER.NEED_AGE"
                  placeholder="USER.USER.NEED_AGE_PLACEHOLDER"
                  value={setValue(AGE_OPTION_LIST, FORM_VALUE_ENUM.NEED_AGE)}
                  options={AGE_OPTION_LIST}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NEED_AGE)}
                  error={isFieldError(FORM_VALUE_ENUM.NEED_AGE)}
                />
              </DoubleContainerElem> */}
              {/* <DoubleContainerElem>
                <SelectElem
                  placeholder="USER.USER.NEED_PLACEMENT_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.NEED_PLACEMENT}
                  onChange={setFieldValue}
                  options={NEED_PLACEMENT_OPTION_LIST}
                  title="USER.USER.NEED_PLACEMENT"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NEED_PLACEMENT)}
                  error={isFieldError(FORM_VALUE_ENUM.NEED_PLACEMENT)}
                  value={setValue(
                    NEED_PLACEMENT_OPTION_LIST,
                    FORM_VALUE_ENUM.NEED_PLACEMENT,
                  )}
                />
                <SelectElem
                  placeholder="USER.USER.NEED_PREVIOUS_EXPERIENCE_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.NEED_PREVIOUS_EXPERIENCE}
                  onChange={setFieldValue}
                  options={YES_NO_OPTION_LIST}
                  title="USER.USER.NEED_PREVIOUS_EXPERIENCE"
                  errorMessage={getFieldError(
                    FORM_VALUE_ENUM.NEED_PREVIOUS_EXPERIENCE,
                  )}
                  error={isFieldError(FORM_VALUE_ENUM.NEED_PREVIOUS_EXPERIENCE)}
                  value={setValue(
                    YES_NO_OPTION_LIST,
                    FORM_VALUE_ENUM.NEED_PREVIOUS_EXPERIENCE,
                  )}
                />
              </DoubleContainerElem>

              <SelectElem
                isMulti
                placeholder="USER.USER.NEED_SPECIALIST_PLACEHOLDER"
                name={FORM_VALUE_ENUM.NEED_SPECIALIST}
                onChange={onChangeSelect}
                options={SPECIALIST_OPTION_LIST}
                title="USER.USER.NEED_SPECIALIST"
                errorMessage={getFieldError(FORM_VALUE_ENUM.NEED_SPECIALIST)}
                error={isFieldError(FORM_VALUE_ENUM.NEED_SPECIALIST)}
                value={getFieldValue(FORM_VALUE_ENUM.NEED_SPECIALIST)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                customComponents={{ MultiValueContainer: multiValueContainer }}
              />
              <SelectElem
                placeholder="USER.USER.NEED_COMPATIBILITY_PLACEHOLDER"
                name={FORM_VALUE_ENUM.NEED_COMPATIBILITY}
                onChange={setFieldValue}
                options={YES_NO_OPTION_LIST}
                title="USER.USER.NEED_COMPATIBILITY"
                errorMessage={getFieldError(FORM_VALUE_ENUM.NEED_COMPATIBILITY)}
                error={isFieldError(FORM_VALUE_ENUM.NEED_COMPATIBILITY)}
                value={setValue(
                  YES_NO_OPTION_LIST,
                  FORM_VALUE_ENUM.NEED_COMPATIBILITY,
                )}
              /> */}
              {/* <TextElem
                tid="USER.USER.ANIMAL_REMARKS"
                type="medium"
                size="main"
                color="buttonText"
              /> */}

              {/* <SelectElem
                isDot
                placeholder="USER.USER.COMPATIBILITY_ANIMAL_PLACEHOLDER"
                name={FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL}
                onChange={setFieldValue}
                options={YES_NO_OPTION_LIST}
                title="USER.USER.COMPATIBILITY_ANIMAL"
                errorMessage={getFieldError(
                  FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL,
                )}
                error={isFieldError(FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL)}
                value={setValue(
                  YES_NO_OPTION_LIST,
                  FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL,
                )}
              />
              <FieldTextAreaElem
                name={FORM_VALUE_ENUM.IMPORTANT_HABITS}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.IMPORTANT_HABITS)}
                title="USER.USER.IMPORTANT_HABITS"
                placeholder="USER.USER.IMPORTANT_HABITS_PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.IMPORTANT_HABITS)}
                error={isFieldError(FORM_VALUE_ENUM.IMPORTANT_HABITS)}
              />
              <FieldTextAreaElem
                name={FORM_VALUE_ENUM.CONCERNS}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.CONCERNS)}
                title="USER.USER.CONCERNS"
                placeholder="USER.USER.CONCERNS_PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.CONCERNS)}
                error={isFieldError(FORM_VALUE_ENUM.CONCERNS)}
              />
              <FieldTextAreaElem
                name={FORM_VALUE_ENUM.DIET}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.DIET)}
                title="USER.USER.DIET"
                placeholder="USER.USER.DIET_PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.DIET)}
                error={isFieldError(FORM_VALUE_ENUM.DIET)}
              />
              <FieldTextAreaElem
                name={FORM_VALUE_ENUM.REQUIRE_CARE}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.REQUIRE_CARE)}
                title="USER.USER.REQUIRE_CARE"
                placeholder="USER.USER.REQUIRE_CARE_PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.REQUIRE_CARE)}
                error={isFieldError(FORM_VALUE_ENUM.REQUIRE_CARE)}
              />
              <FieldTextAreaElem
                name={FORM_VALUE_ENUM.MEDICATION}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.MEDICATION)}
                title="USER.USER.MEDICATION"
                placeholder="USER.USER.MEDICATION_PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.MEDICATION)}
                error={isFieldError(FORM_VALUE_ENUM.MEDICATION)}
              />
              <SelectElem
                isDot
                isMulti
                placeholder="USER.USER.VACCINATION_PLACEHOLDER"
                name={FORM_VALUE_ENUM.VACCINATION}
                onChange={onChangeSelect}
                options={VACCINATION_OPTION_LIST}
                title="USER.USER.VACCINATION"
                errorMessage={getFieldError(FORM_VALUE_ENUM.VACCINATION)}
                error={isFieldError(FORM_VALUE_ENUM.VACCINATION)}
                value={getFieldValue(FORM_VALUE_ENUM.VACCINATION)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                customComponents={{ MultiValueContainer: multiValueContainer }}
              /> */}

              <TitleContainer>
                <Dot />
                <TextElem
                  tid="USER.USER.REQUIRED"
                  type="light"
                  size="input"
                  color="textSecondary"
                />
              </TitleContainer>

              <ButtonElem
                disabled={isSubmitDisabled()}
                type="submit"
                tid="CATEGORY.DATA.BUTTON"
              />
            </GridElem>
            {isError && <AlertActionElem text={errorMessage} />}
            {isSuccessAction && (
              <AlertActionElem type="success" tid="CATEGORY.DATA.SUCCESS" />
            )}
          </ContentConteinerStyled>
        </>
      )}
    </FormElem>
  );
};

const ContentConteinerStyled = styled(ContentContainerElem)`
  width: 100%;
`;

const StyleSelectElem = styled(SelectElem)`
  & .Select__placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[
      SIZE_FONT_WEIGHT_ENUM.LIGHT
    ]} !important;
  }
  & .Select__control--menu-is-open .Select__placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

import React from 'react';
import { useQuery } from 'react-query';

import {
  ACTION_ERROR_INTER,
  MODULE_NAME,
  SCHEDULE_REGULAR_RAW_INTER,
} from './constant';
import { Component } from './component';
import { getDateAction } from './action';
import { convert } from './convert';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';

export const Container: React.FC = () => {
  const preFetch = useQuery([MODULE_NAME], getDateAction);

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const offsetInMinute = new Date().getTimezoneOffset();
  const offsetInHour = -offsetInMinute / 60;

  const timezone = auth.user ? Number(auth.user.timezone) : offsetInHour;

  const getData = () => {
    const data = preFetch.data as unknown as SCHEDULE_REGULAR_RAW_INTER;

    if (data) {
      return convert(data, timezone);
    }
  };

  const isSubmitDisabled = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      data={getData()}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};

import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
} from './constant';
import { Component } from './component';
import { updateAction, createWeekendAction } from './action';
import { SCHEDULE_LIST_MODULE_NAME } from '../schedule-list';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { required, requiredArray } from '../../lib/validation/service';
import { convert } from './convert';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC = () => {
  const [isWeekend, setIsWeekend] = useState(false);
  const queryClient = useQueryClient();

  const setWeekend = () => {
    setIsWeekend((prev) => !prev);
  };

  const config = {
    [FORM_VALUE_ENUM.DATE]: [required],
    [FORM_VALUE_ENUM.TIME]: [requiredArray],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.DATE]: '',
    [FORM_VALUE_ENUM.TIME]: [],
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: () => {},
  });

  const onSuccess = () => {
    queryClient.invalidateQueries(SCHEDULE_LIST_MODULE_NAME);
    formik.resetForm();
  };

  const action = useMutation(isWeekend ? createWeekendAction : updateAction, {
    onSuccess,
  });

  const isSubmitDisabled = () => {
    if (isWeekend) {
      return false;
    }

    if (action.isLoading) {
      return true;
    }

    if (!formik.dirty) {
      return true;
    }
    if (!formik.isValid) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const timezone = auth.user ? auth.user.timezone : null;

  const handleSuccess = () => {
    if (isWeekend) {
      const date = formik.values[FORM_VALUE_ENUM.DATE];

      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1,
      ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

      action.mutate([formattedDate]);
    } else {
      const convertedData = convert(formik.values, timezone);

      action.mutate(convertedData);
    }
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  const isWeekendDisabled =
    action.isLoading || !formik.values[FORM_VALUE_ENUM.DATE];

  return (
    <Component
      isWeekendDisabled={isWeekendDisabled}
      setWeekend={setWeekend}
      isWeekend={isWeekend}
      formik={formik}
      onChangeSelect={onChangeSelect}
      getFieldValue={getFieldValue}
      handleSuccess={handleSuccess}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};

import { SCHEDULE_REGULAR_RAW_INTER } from './constant';

function convertTimeToTimezone(time: string, timezoneOffset: number): string {
  const [hour, minute] = time.split(':').map(Number);

  const date = new Date(Date.UTC(2000, 0, 1, hour, minute));

  date.setHours(date.getHours() + timezoneOffset);

  const convertedHour = String(date.getUTCHours()).padStart(2, '0');
  const convertedMinute = String(date.getUTCMinutes()).padStart(2, '0');

  return `${convertedHour}:${convertedMinute}`;
}

function sortTime(timeArr: string[], timezoneOffset: number): string[] {
  return timeArr
    .map((time) => convertTimeToTimezone(time, timezoneOffset))
    .sort((a, b) => {
      const [aHour, aMinute] = a.split(':').map(Number);
      const [bHour, bMinute] = b.split(':').map(Number);
      return aHour === bHour ? aMinute - bMinute : aHour - bHour;
    });
}

export function convert(
  data: SCHEDULE_REGULAR_RAW_INTER,
  timezoneOffset: number,
) {
  return {
    ...data,
    schedule: data.schedule.map((item) => ({
      ...item,
      timeSlot: sortTime(item.timeSlot, timezoneOffset),
    })),
  };
}

import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { ModalTimeChange } from './frame/modal';
import { FormikValues } from 'formik';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { USER_ROLE } from '../../data/auth/constant';

export const Component: React.FC<{
  dayArr: any;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  currentDate?: {
    timeView: string;
    dayView: string;
  };
  isTimeLoading: boolean;
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  timezone: number;
  isFieldDisabled: boolean;
  timeOption: {
    label: string;
    value: string;
  }[];
  isSuccess: boolean;
  role?: USER_ROLE;
  isChangeTimeDisabled: boolean;
  isRessignDisabled?: Function;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isFieldDisabled,
  isTimeLoading,
  isSuccess,
  role,
  currentDate,
  isChangeTimeDisabled,
  timezone,
  isRessignDisabled,
  dayArr
}) => {

  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SETTINGS.CONSULTATION.SUCCESS" />
      )}
      <Block>
        <FlexContainer>
          <TextContainer>
            <Title
              size="input"
              color="textSecondary"
              tid="CONSULTATION.DATE.DAY"
            />
            <Description
              size="label"
              type="medium"
              color="textPrimary"
              tid={currentDate?.dayView}
            />
          </TextContainer>
          <TextContainer>
            <Title
              size="input"
              color="textSecondary"
              tid="CONSULTATION.DATE.TIME_TITLE"
            />
            <Description
              size="label"
              type="medium"
              color="textPrimary"
              tid="CONSULTATION.DATE.TIME"
              tvalue={{ time: currentDate?.timeView }}
            />
          </TextContainer>
        </FlexContainer>

        <ModalTimeChange
          isChangeTimeDisabled={isChangeTimeDisabled}
          isFieldError={isFieldError}
          getFieldError={getFieldError}
          getFieldValue={getFieldValue}
          setFieldValue={setFieldValue}
          setValue={setValue}
          formik={formik}
          isSubmitDisabled={isSubmitDisabled}
          timeOption={timeOption}
          isFieldDisabled={isFieldDisabled}
          isTimeLoading={isTimeLoading}
          isRessignDisabled={isRessignDisabled}
        />
      </Block>
    </>
  );
};

const Title = styled(TextElem)`
  @media screen and (max-width: 720px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.CHIP]}px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 720px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
  gap: ${Spacing(4)};
`;

const TextContainer = styled.div`
  display: flex;
  gap: 9px;
  flex-direction: column;
  align-items: start;
`;

const Block = styled.div`
  display: flex;
  align-items: start;
  gap: ${Spacing(5)};
  justify-content: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  min-height: 94px;

  @media screen and (max-width: 720px) {
    padding: ${Spacing(5)};
    flex-direction: column;
  }
`;

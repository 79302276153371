import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ButtonElem } from '../../common/button';
import { SelectElem } from '../../common/select';
import { FORM_VALUE_ENUM } from './constant';
import { FormikValues } from 'formik';
import { TextElem } from '../../common/text';
import { FieldTextElem } from '../../common/field-text';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  formik: FormikValues;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  getFieldError: Function;
  isFieldError: Function;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  isSubmitDisabled,
  setFieldValue,
  getFieldError,
  isFieldError,
  setValue,
  formik,
  getFieldValue,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem
          type="success"
          tid="CONSULTATION.ADMIN.ADD_USER.SUCCESS"
        />
      )}
      <MainContainer>
        <TextElem
          lineHeight="0.8em"
          tid="CONSULTATION.ADMIN.ADD_USER.TITLE"
          type="medium"
          size="main"
          color="textPrimary"
        />

        <Block>
          <FieldTextElemStyled
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="CONSULTATION.ADMIN.ADD_USER.FIELD"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
            type="email"
          />
          <ButtonElemStyled
            disabled={isSubmitDisabled()}
            type="button"
            onClick={() => {
              formik.handleSubmit();
            }}
            tid="CONSULTATION.ADMIN.ADD_USER.SAVE"
          />
        </Block>
      </MainContainer>
    </>
  );
};

const FieldTextElemStyled = styled(FieldTextElem)`
  min-height: 46px;
  max-height: 46px;
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(4)} ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${Spacing(3)};
  padding: ${Spacing(6)};
  gap: ${Spacing(4)};
  height: max-content;
`;

const Block = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
  width: 100%;

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding: ${Spacing(5)};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;

    span {
      font-size: 14px;
    }
  }
`;

import { FormikValues } from 'formik';
import { i18n } from '../../lib/lang';
import { required } from '../../lib/validation/service';
import { validation } from '../../lib/validation';
import { SPECIALIZATION_ENUM } from '../../data/user/constant';

export const MODULE_NAME = 'PROFILE_PSYCHO_CREATE_CATEGORY_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  CATEGORY = 'category',
  SERVICE_TYPE = 'serviceType',
  PRICE = 'price',
  REQUIRE_HOURS = 'requireHours',
  OVER_NIGHT_AVAILABILITY = 'overNightAvailability',
  FULL_DAY_AVAILABILITY = 'fullDayAvailability',
  DESCRIPTION = 'description',
}

export enum CATEGORY_ENUM {
  WALK = 1,
  FEED = 2,
  HOLD = 3,
}

export const config = {
  [FORM_VALUE_ENUM.CATEGORY]: [required],
  [FORM_VALUE_ENUM.PRICE]: [required],
  [FORM_VALUE_ENUM.REQUIRE_HOURS]: [required],
  [FORM_VALUE_ENUM.OVER_NIGHT_AVAILABILITY]: [required],
  [FORM_VALUE_ENUM.FULL_DAY_AVAILABILITY]: [required],
  [FORM_VALUE_ENUM.DESCRIPTION]: [],
};

export const validate = (values: FormikValues) => validation(values, config);

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export const CATEGORY_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.WALK'),
    value: 1,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.FEED'),
    value: 2,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.HOLD'),
    value: 3,
  },
];

export const SERVICE_TYPE_OPTION_LIST = [
  {
    label: 'Прогулянка',
    value: SPECIALIZATION_ENUM.WALK,
  },
];
export const OVER_NIGHT_AVAILABILITY_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.NO'),
    value: 'false',
  },
];
export const FULL_DAY_AVAILABILITY_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.NO'),
    value: 'false',
  },
];

export const CATEGORY_VIEW = {
  [CATEGORY_ENUM.WALK]: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.WALK'),
  [CATEGORY_ENUM.FEED]: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.FEED'),
  [CATEGORY_ENUM.HOLD]: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.HOLD'),
};

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.CATEGORY]: string;
  [FORM_VALUE_ENUM.PRICE]: string;
  [FORM_VALUE_ENUM.REQUIRE_HOURS]: number;
  [FORM_VALUE_ENUM.OVER_NIGHT_AVAILABILITY]: string;
  [FORM_VALUE_ENUM.FULL_DAY_AVAILABILITY]: string;
  [FORM_VALUE_ENUM.DESCRIPTION]: string;
}

export const API = {
  MAIN: {
    TYPE: 'POST',
    URL: 'category/create',
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export interface FILE_STATE_INTER {
  id: string | number;
  file: File;
}

import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { Markdown } from '../../common/markdown/markdown';
import { Spacing } from '../../theme';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem background="active">
      <LayoutAppElem oneColumn>
        <Container>
          <Markdown tid="USER_AGREEMENT.CONTENT" />
        </Container>
      </LayoutAppElem>
    </PageElem>
  );
};

const Container = styled.div`
  padding: ${Spacing(25)} ${Spacing(5)};
  max-width: 800px;
  width: 100%;
  margin: 0px auto;
`;

import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ACTION_ERROR_INTER, PROPS_TYPE } from './constant';
import { Component } from './component';
import { confirmAction, getDateAction } from './action';
import { CONSULTATION_ITEM_MODULE_NAME } from '../consultation-item';
import { USER_ROLE } from '../../data/auth/constant';
import moment from 'moment';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC<PROPS_TYPE> = ({
  role,
  consultationId,
  url,
  timezone,
  duration,
  isCompleteClient,
  isCompletePsycho,
  isPaid,
  isAfterConsultation,
  isStartConsultation,
}) => {
  const queryClient = useQueryClient();

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const isAuth = auth ? auth.logged && auth?.isConfirmEmail : null;

  const consultationTime = useQuery(
    'CONSULTATION_TIME',
    () => getDateAction({ consultationId }),
    {
      onSuccess: (data) => {
        const now = moment();

        // Часовой пояс пользователя в минутах
        const userTimezoneOffset = timezone;

        // Начало консультации с учетом часового пояса
        const consultationStartTime = moment(data?.data).utcOffset(
          userTimezoneOffset,
        );

        const differenceInMinutes = consultationStartTime.diff(now, 'minutes');
      },
      refetchInterval: 60000,
    },
  );

  const onSuccess = () => {
    queryClient.invalidateQueries(CONSULTATION_ITEM_MODULE_NAME);
  };

  const action = useMutation(confirmAction, { onSuccess });

  const isConfirmBlock =
    consultationTime.isSuccess &&
    isStartConsultation &&
    isAfterConsultation &&
    isPaid &&
    isAuth;

  const isSubmitDisabled = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const handleSuccess = () => {
    if (role === USER_ROLE.USER) {
      action.mutate({ consultationId, payload: { isCompleteClient: true } });
    }
    if (role === USER_ROLE.CARETAKER) {
      action.mutate({ consultationId, payload: { isCompletePsycho: true } });
    }
  };

  return (
    <Component
      isConfirmBlock={isConfirmBlock}
      isAfterConsultation={isAfterConsultation}
      handleSuccess={handleSuccess}
      isCompleteClient={isCompleteClient}
      isCompletePsycho={isCompletePsycho}
      url={url}
      isStartConsultation={isStartConsultation}
      role={role}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};

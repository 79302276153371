import {
  PAYMENT_ITEM_LIST_DATA_RAW_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
} from '../../data/payment/constant';

export const MODULE_NAME = 'SCHEDULE_LIST_REGULAR_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: '/user/schedule-regular',
  },
};

export interface ACTION_RESPONSE_INTER
  extends PAYMENT_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends PAYMENT_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export enum DAY_ENUM {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const SCHEDULE_LIST = [
  { tid: 'Понеділок', id: DAY_ENUM.MONDAY },
  { tid: 'Вівторок', id: DAY_ENUM.TUESDAY },
  { tid: 'Середа', id: DAY_ENUM.WEDNESDAY },
  { tid: 'Четвер', id: DAY_ENUM.THURSDAY },
  { tid: 'Пʼятниця', id: DAY_ENUM.FRIDAY },
  { tid: 'Субота', id: DAY_ENUM.SATURDAY },
  { tid: 'Неділя', id: DAY_ENUM.SUNDAY },
];

export type SCHEDULE_REGULAR_TYPE = {
  day: DAY_ENUM;
  timeSlot: string[];
  isWeekend: boolean;
};

export interface SCHEDULE_REGULAR_RAW_INTER {
  schedule: SCHEDULE_REGULAR_TYPE[];
}

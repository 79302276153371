import { CONSULTATION_RAW_INTER } from '../../data/consultation/constant';
import {
  ANIMAL_SIZE_VIEW,
  ANIMAL_TYPE_VIEW,
  GENDER_ENUM,
  LANGUAGE_VIEW,
  methodViewObj,
  PLACEMENT_VIEW,
  requestViewObj,
  SPECIALIST_VIEW,
  VACCINATION_VIEW,
  YES_NO_VIEW,
} from '../../data/user/constant';
import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';
import { convertDateToLocal } from '../../lib/util/dateConvert';
import { getYearLabel } from '../../lib/util/yearConvert';
import { CATEGORY_VIEW } from '../profile-psycho-create-category/constant';
import { GENDER_VIEW } from '../profile-psycho/constant';

export const convertArray = (
  array: string[] | any | null,
  viewObj: Record<string, string>,
) => {
  if (!array || array.length <= 0) {
    return [];
  }
  return array.map((elem: string) => {
    return viewObj[elem];
  });
};

export const convert = (
  values: CONSULTATION_RAW_INTER,
  timezone: number,
): any => {
  // на доработку

  const { time, day } = convertDateToLocal(values.date, timezone);

  const data = {
    ...values,
    timeView: time,
    dayView: day,
    category: CATEGORY_VIEW[values?.category],
    user: {
      ...values.user,
      timezone:
        values?.user?.timezone &&
        TIMEZONE_VIEW[values.user.timezone as TIMEZONE_ENUM],
      numberTimezone: values?.user?.timezone && values.user.timezone,
      gender: GENDER_VIEW[values.user.gender as GENDER_ENUM],
      age: getYearLabel(values.user.age || 0),
    },
    psychologist: {
      ...values.psychologist,
      gender:
        values?.psychologist?.gender &&
        GENDER_VIEW[values.psychologist.gender as GENDER_ENUM],
      timezone:
        values?.psychologist?.timezone &&
        TIMEZONE_VIEW[values.psychologist.timezone as TIMEZONE_ENUM],
      age: getYearLabel(values.psychologist.age || 0),
    },
  };

  return data;
};

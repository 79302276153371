import {
  OPTION_INTER,
  USER_ITEM_DATA_RAW_INTER,
} from '../../data/user/constant';
import { i18n } from '../../lib/lang';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'PROFILE_USER_UPDATE_DATA_MODULE_NAME';
export const MODULE_DATA_NAME = 'PROFILE_USER_DATA_MODULE_NAME'; // На перенос в соответсвующий модуль
export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface USER_DATA_RAW
  extends Omit<
    USER_ITEM_DATA_RAW_INTER,
    'id' | 'createDate' | 'email' | 'role' | 'password'
  > {}

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  PHONE = 'phone',
  AGE = 'age',
  GENDER = 'gender',
  TIMEZONE = 'timezone',
  CITY = 'city',
  COUNTRY = 'country',
  LANGUAGE = 'language',
  ADDRESS_INFO = 'addressInfo',
  PHOTO = 'photo',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.AGE]: number | null;
  [FORM_VALUE_ENUM.GENDER]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.TIMEZONE]: string | number;
  [FORM_VALUE_ENUM.LANGUAGE]: OPTION_INTER[];
  [FORM_VALUE_ENUM.CITY]: string;
  [FORM_VALUE_ENUM.COUNTRY]: string;
  [FORM_VALUE_ENUM.ADDRESS_INFO]: string;
}

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 2,
  },
];
export const LANGUAGE_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.UA'),
    value: 'UA',
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.ENG'),
    value: 'ENG',
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.POL'),
    value: 'POL',
  },
];
export const NEED_PLACEMENT_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.PLACEMENT.OWNER'),
    value: 'owner',
  },
  {
    label: i18n.t('USER.USER.SELECT.PLACEMENT.CARETAKER'),
    value: 'caretaker',
  },
];
export const SPECIALIST_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.SPECIALIST.ANGRY'),
    value: 'angry',
  },
  {
    label: i18n.t('USER.USER.SELECT.SPECIALIST.BLIND'),
    value: 'blind',
  },
  {
    label: i18n.t('USER.USER.SELECT.SPECIALIST.DEAF'),
    value: 'deaf',
  },
];
export const ANIMAL_SIZE_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.MIDGET'),
    value: 'midget',
  },
  {
    label: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.SMALL'),
    value: 'small',
  },
  {
    label: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.MEDIUM'),
    value: 'medium',
  },
  {
    label: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.BIG'),
    value: 'big',
  },
];

export const VACCINATION_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.VACCINATION.YES'),
    value: 'yes',
  },
  {
    label: i18n.t('USER.USER.SELECT.VACCINATION.NO'),
    value: 'no',
  },
];
export const YES_NO_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.USER.SELECT.NO'),
    value: 'false',
  },
];

export const METHOD_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.METHOD.COGNITIVE_BEHAVIORAL_THERAPY'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.METHOD.GESTALTTHERAPY'),
    value: 2,
  },
  {
    label: i18n.t('USER.USER.SELECT.METHOD.PSYCHOANALYSIS'),
    value: 3,
  },
];

export const REQUEST_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.ANXIETY'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.STRESS'),
    value: 2,
  },
  {
    label: i18n.t('USER.USER.SELECT.REQUEST.FAMILY_CONFLICT'),
    value: 3,
  },
];

export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: 'user/data-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/data/profile',
  },
  POST: {
    TYPE: 'POST',
    URL: 'user/photo',
  },
  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'KNOWLEDGE_LANDING_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const CATALOG_ITEM_LIST = [
  {
    id: 1,
    imgSrc: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.1.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.1.IMG_ALT')}`,
    text: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.1.TEXT')}`,
  },
  {
    id: 2,
    imgSrc: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.2.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.2.IMG_ALT')}`,
    text: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.2.TEXT')}`,
  },
  {
    id: 3,
    imgSrc: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.3.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.3.IMG_ALT')}`,
    text: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.3.TEXT')}`,
  },
  {
    id: 4,
    imgSrc: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.4.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.4.IMG_ALT')}`,
    text: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.4.TEXT')}`,
  },
  {
    id: 5,
    imgSrc: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.5.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.5.IMG_ALT')}`,
    text: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.5.TEXT')}`,
  },
  {
    id: 6,
    imgSrc: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.6.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.6.IMG_ALT')}`,
    text: `${i18n.t('KNOWLEDGE_LANDING.ADVANTAGE_LIST.6.TEXT')}`,
  },
];

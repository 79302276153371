import React, { useState } from 'react';
import { FormikValues } from 'formik';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { USER_ROLE } from '../../data/auth/constant';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';

export const Component: React.FC<{
  price: number;
  duration: number;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isPaid: boolean;
  isCancel: boolean;
  handlePay: Function;
  handleCancel: (cancelInfo: string) => void;
  role?: USER_ROLE;
  isSuccess: boolean;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  formik: FormikValues;
  isSubmitDisabled?: boolean;
  isChangeTimeDisabled?: boolean;
  isCancelDisabled?: boolean;
}> = ({
  isLoading,
  errorMessage,
  isPaid,
  isCancel,
  handlePay,
  role,
  isSuccess,
  isError,
  isSubmitDisabled,
}) => {
  const isPaymentButton = !isCancel && !isPaid;
  const isSuccessChip = isPaid && !isCancel;

  const onOpenSpChat = () => {
    const spLiveChat = document.querySelector('sp-live-chat');
    if (spLiveChat && spLiveChat.shadowRoot) {
      const widgetFab = spLiveChat.shadowRoot.querySelector('.widget-fab');
      if (widgetFab) {
        (widgetFab as HTMLElement).click();
      }
    }
  };

  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem
          type="success"
          tid="CONSULTATION.USER.CANCEL_SUCCESS"
        />
      )}

      <MainContainer>
        {/* {!isPaid && !isCancel && role !== USER_ROLE.USER && (
          <ChipError>
            <TextElem
              tid="CONSULTATION.USER.NO_PAID"
              color="error"
              size="semiSmall"
            />
          </ChipError>
        )} */}
        <TextElem
          tid="CONSULTATION.USER.PAYMENT.TITLE"
          type="medium"
          size="main"
          color="textPrimary"
        />
        <TextElem
          tid="CONSULTATION.USER.PAYMENT.DESCRIPTION"
          size="input"
          type="light"
          color="textSecondary"
        />

        <ButtonContainer>
          <div>
            {isCancel && (
              <ChipError>
                <TextElem
                  tid="CONSULTATION.USER.CANCELLATION"
                  color="error"
                  size="semiSmall"
                />
              </ChipError>
            )}
            {isPaymentButton && (
              <ButtonElemStyled
                tid="CONSULTATION.USER.PAY"
                type="submit"
                onClick={handlePay}
                disabled={isSubmitDisabled}
              />
            )}
            {isSuccessChip && (
              <ChipSuccess>
                <TextElem
                  tid="CONSULTATION.USER.PAID"
                  color="success"
                  size="semiSmall"
                />
              </ChipSuccess>
            )}
          </div>
          <ButtonElemStyled
            tid="CONSULTATION.USER.PAYMENT.HELP"
            onClick={onOpenSpChat}
          />
        </ButtonContainer>
      </MainContainer>
      {/* <ModalElemStyled onClose={handleClose} open={isOpen}>
        <FieldTextElem
          name={FORM_VALUE_ENUM.CANCELLATION_REASON}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="CONSULTATION.USER.CANCEL_INFO"
          placeholder="CONSULTATION.USER.CANCEL_INFO_PLACEHOLDER"
          value={getFieldValue(FORM_VALUE_ENUM.CANCELLATION_REASON)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.CANCELLATION_REASON)}
          error={isFieldError(FORM_VALUE_ENUM.CANCELLATION_REASON)}
        />

        <ButtonElemCancel
          disabled={isCancelDisabled || isChangeTimeDisabled}
          type="submit"
          onClick={() => {
            handleCancel(getFieldValue(FORM_VALUE_ENUM.CANCELLATION_REASON));
            setIsOpen(false);
          }}
        >
          <TextElem tid="CONSULTATION.USER.CANCEL" />

          <TooltipElem tid="CONSULTATION.USER.CANCEL_TOOLTIP">
            <img src={infoSvg} alt="tooltip icon" />
          </TooltipElem>
        </ButtonElemCancel>
      </ModalElemStyled> */}
    </>
  );
};

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${Spacing(3)};
  padding: ${Spacing(6)};
  gap: ${Spacing(3)};
  height: max-content;
`;

// const ButtonElemCancel = styled(ButtonElem)`
//   margin-top: ${Spacing(4)};
// `;

// const ModalElemStyled = styled(ModalElem)`
//   ::part(content) {
//     padding: ${Spacing(6)};
//     overflow: visible !important;

//     @media screen and (max-width: 720px) {
//       padding: ${Spacing(5)};
//     }
//   }
// `;

const ButtonElemStyled = styled(ButtonElem)`
  width: 100%;
  padding: ${Spacing(4)} ${Spacing(8)} !important;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD
  ]} !important;

  span {
    font-size: 14px;
  }
`;

const Chip = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  padding: 13.5px ${Spacing(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing(2)};

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

const ChipSuccess = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
`;

const ChipError = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.ERROR]};
`;

// const TextContainer = styled.div`
//   display: flex;
//   gap: ${Spacing(2.5)};
//   flex-direction: column;
//   // align-items: center;

//   @media screen and (max-width: 720px) {
//     gap: ${Spacing(2)};
//     .price {
//       font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;
//     }
//     span {
//       font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
//     }
//   }
// `;

// const InfoContainer = styled.div`
//   display: flex;
//   gap: ${Spacing(5)};
//   flex-direction: row;
// `;

// const Block = styled.div`
//   display: grid;
//   align-items: center;
//   justify-content: space-between;
//   grid-template-columns: 1fr 1fr;
//   background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
//   padding: ${Spacing(6)};
//   border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;

//   @media screen and (max-width: 720px) {
//     grid-template-columns: 1fr;
//     // flex-direction: column;
//     width: 100%;
//     // align-items: start;
//     gap: ${Spacing(5)};
//     padding: ${Spacing(5)};
//   }
// `;

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (id?: string, isAuth?: boolean) =>
      !isAuth ? `consultation/item-unlogged/${id}` : `consultation/item/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export const tabListUser = [
  { tid: 'CONSULTATION.USER.TAB.CONSULTATION', value: 1 },
  { tid: 'CONSULTATION.USER.TAB.CLIENT', value: 2 },
  { tid: 'CONSULTATION.USER.TAB.CHAT', value: 3 },
];

export const tabListPsycho = [
  { tid: 'CONSULTATION.PSYCHOLOGIST.TAB.CONSULTATION', value: 1 },
  { tid: 'CONSULTATION.PSYCHOLOGIST.TAB.CLIENT', value: 2 },
  { tid: 'CONSULTATION.PSYCHOLOGIST.TAB.CHAT', value: 3 },
];

import { ANIMAL_TYPE_VIEW, GENDER_ENUM } from '../../data/user/constant';
import { TIMEZONE_ENUM, TIMEZONE_VIEW } from '../../data/user/timezone';
import { getYearLabel } from '../../lib/util/yearConvert';
import { convertArray } from '../consultation-user-item/convert';
import { CATEGORY_VIEW } from '../profile-psycho-create-category/constant';
import { GENDER_VIEW } from '../profile-psycho/constant';
import { DATA_INTER, PSYCHO_ITEM_LIST_DATA_RAW_INTER } from './constant';

export const convert = (list: PSYCHO_ITEM_LIST_DATA_RAW_INTER): DATA_INTER => {
  return {
    list: list.list?.map((e) => ({
      ...e,
      timezone: TIMEZONE_VIEW[e.timezone as TIMEZONE_ENUM],
      age: getYearLabel(Number(e.age)),
      gender: GENDER_VIEW[e.gender as GENDER_ENUM],
      yearExperience: getYearLabel(Number(e.yearExperience)),
      categoryView: e?.category
        ? e?.category.map((e) => CATEGORY_VIEW[e?.category])
        : [],
      workAnimalType: e?.workAnimalType
        ? convertArray(e?.workAnimalType, ANIMAL_TYPE_VIEW)
        : [],
    })),
    isEmpty: !list.list?.length,
  };
};

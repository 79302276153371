import styled from 'styled-components';

import { UseMutationResult } from 'react-query';
import uploadIcon from '../../../asset/svg/upload/upload.svg';
import { FILE_ITEM_DATA_RAW_INTER } from '../../../data/file/constant';

export const FileComponent: React.FC<{
  data?: FILE_ITEM_DATA_RAW_INTER;
  handleClick: (e: any) => Promise<void>;
  uploadFile: UseMutationResult;
  defaultValue?: string;
  getInputProps: any;
  getRootProps: any;
  error: string;
  progress: number;
  handleReselect: (e: any) => void;
  handleReset: (e: any) => void;
  variant?: 'consultation' | 'default' | 'icon';
}> = ({ handleClick }) => {
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  return (
    <UploadIconStyled
      src={uploadIcon}
      alt="upload"
      onClick={handleClick}
      className="file-upload-wrapper"
    />
  );
};

const UploadIconStyled = styled.img`
  cursor: pointer;
`;

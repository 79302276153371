import { CONSULTATION_STATUS } from '../../data/consultation/constant';
import {
  PAYMENT_ITEM_LIST_DATA_RAW_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
} from '../../data/payment/constant';

export const MODULE_NAME = 'CONSULTATION_ADMIN_ADD_USER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  PATCH: {
    TYPE: 'PATCH',
    URL: (id?: string | number) => `/consultation/update/${id}`,
  },
};

export interface ACTION_RESPONSE_INTER
  extends PAYMENT_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends PAYMENT_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface PROPS_TYPE {
  consultationId: string | number;
}

export enum FORM_VALUE_ENUM {
  EMAIL = 'email',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.EMAIL]: string;
}

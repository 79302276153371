import React from 'react';

import { FormikValues } from 'formik';
import styled from 'styled-components';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { CODE_VALUE_ENUM } from './constant';
import { ResendComponent } from './frame/resend.component.tsx';
import { AUTH_TYPE } from '../../data/auth/constant';
import { FormElem } from '../../common/form';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  login: string;
  type: AUTH_TYPE;
  isOrderPage?: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  login,
  isOrderPage,
  type,
}) => {
  return (
    <Form onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridStyled isOrderPage={isOrderPage} size="mod">
        <TextElemStyled
          size="semiSmall"
          color="textPrimary"
          type="regular"
          tid="AUTH.VERIFICATION.TEXT"
          tvalue={{ type, login }}
        />
        <GridElem spacing={6} size="input">
          <FieldTextElem
            name={CODE_VALUE_ENUM.CODE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="AUTH.VERIFICATION.CODE"
            value={getFieldValue(CODE_VALUE_ENUM.CODE)}
            errorMessage={getFieldError(CODE_VALUE_ENUM.CODE)}
            error={isFieldError(CODE_VALUE_ENUM.CODE)}
          />
          <ResendComponent email={login} />
        </GridElem>

        <ButtonElem
          disabled={isSubmitDisabled()}
          type="submit"
          tid="AUTH.VERIFICATION.BUTTON"
        />
        {isError && <AlertActionElem text={errorMessage} />}
      </GridStyled>
    </Form>
  );
};

const TextElemStyled = styled(TextElem)`
  text-align: center;
  line-height: 1.7em;
`;

const GridStyled = styled(GridElem)<{ isOrderPage?: boolean }>`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  padding: ${({ isOrderPage }) => (isOrderPage ? '' : Spacing(6))};
  gap: 20px;
  box-shadow: 0px 0px 50px 0px #24231e0d;
  justify-items: center;
  max-width: ${({ isOrderPage }) => (isOrderPage ? '' : 366)}px;
`;

const Form = styled(FormElem)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(10)};
  align-items: center;
`;

export const MODULE_NAME = 'PSYCHO_LIST_SCHEDULE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  POST: {
    TYPE: 'POST',
    URL: (isAuth: boolean) =>
      isAuth ? 'consultation/create' : 'consultation/create-unlogged',
  },
};

import {
  CONSULTATION_STATUS,
  CONSULTATION_TYPE,
} from '../../data/consultation/constant';
import { PAYMENT_STATUS } from '../../data/payment/constant';
import { COLOR_ENUM } from '../../theme/color';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'CONSULTATION_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (pageParam: string) => `/consultation/list/?${pageParam}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface PAYMENT_ITEM_DATA_INTER {
  isPaid: boolean | null;
}

export interface CONSULTATION_DATA_RAW_INTER {
  id: string;
  createDate: string;
  updateDate: string;
  price: string;
  status: string;
  date: string;
  duration: number;
  comment: string;
  category: CATEGORY_ENUM;
  psychologist: { name: string };
  user: { name: string };
  payment: PAYMENT_ITEM_DATA_INTER | null;
  type: CONSULTATION_TYPE;
  consultationBalance: number;
  consultationCount: number;
}

export interface CONSULTATION_ITEM_DATA_INTER
  extends Omit<CONSULTATION_DATA_RAW_INTER, 'category' | 'payment'> {
  paymentStatus: 'PAID' | 'NOT_PAID';
  category: string;
  time: string;
  typeView?: string;
}
export interface CONSULTATION_ITEM_LIST_DATA_INTER {
  list: CONSULTATION_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export const CHIP_STATUS_PAYMENT_COLOR_BG = {
  // [PAYMENT_STATUS.PENDING]: '#2E94CD1A',
  [PAYMENT_STATUS.PAID]: '#2ECDB01A',
  [PAYMENT_STATUS.NOT_PAID]: '#A82F2F1A',
};

export const CHIP_STATUS_PAYMENT_COLOR_TEXT = {
  // [PAYMENT_STATUS.PENDING]: COLOR_ENUM.PROGRESS_STATUS,
  [PAYMENT_STATUS.PAID]: COLOR_ENUM.SUCCESS,
  [PAYMENT_STATUS.NOT_PAID]: COLOR_ENUM.ERROR,
};
export const CHIP_STATUS_CONSULTATION_COLOR_BG = {
  [CONSULTATION_STATUS.COMPLETED]: '#2ECDB01A',
  [CONSULTATION_STATUS.CANCEL]: '#A82F2F1A',
  [CONSULTATION_STATUS.PENDING]: '#2E94CD1A',
};

export const CHIP_STATUS_CONSULTATION_COLOR_TEXT = {
  [CONSULTATION_STATUS.COMPLETED]: COLOR_ENUM.SUCCESS,
  [CONSULTATION_STATUS.CANCEL]: COLOR_ENUM.ERROR,
  [CONSULTATION_STATUS.PENDING]: COLOR_ENUM.PROGRESS,
};

import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Spacing } from '../../theme';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { AlertActionElem } from '../../common/alert-action';
import { TabSecondaryElem } from '../../common/tab-secondary';
import { UserHeader } from './frame/user-header';
import { ConsultationReassignContainer } from '../consultation-reassign';
import { USER_ROLE } from '../../data/user/constant';
import {
  CONSULTATION_DATA_INTER,
  CONSULTATION_STATUS,
} from '../../data/consultation/constant';
import { ConsultationPaymentLinkContainer } from '../consultation-payment-link';
import { tabListPsycho, tabListUser } from './constant';
import { ConsultationConfirmContainer } from '../consultation-confirm';
import { ClientContainer } from './frame/client';
import { BackHeaderElem } from '../../common/back-header';
import { InfoConsultation } from './frame/info';
import { PackOrder } from './frame/pack-oder';
import { UserNextConsultationContainer } from '../user-next-consultation';
import { OrderAuthSignupContainer } from '../order-auth-signup';
import { AuthVerificationContainer } from '../auth-verification';
import { AUTH_TYPE } from '../../data/auth/constant';
import { ConsultationItemChatContainer } from '../consultation-item-chat';
import { ModalElem } from '../../common/modal';

export const Component: React.FC<{
  role?: USER_ROLE | any;
  data: CONSULTATION_DATA_INTER;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
  isLogged: boolean | null;
  isConfirmEmail: boolean | null;
  isReasign: boolean;
  isConfirm: boolean;
  isPackOrder: boolean;
  isNextOrder: boolean;
  isPsycho: boolean;
  isChangeTimeDisabled: boolean;
  isCancel: boolean;
  timezone: number;
  isAfterConsultation: boolean;
  isStartConsultation: boolean;
}> = ({
  data,
  isSuccess,
  isLoading,
  isError,
  errorMessage,
  role,
  isLogged,
  isConfirmEmail,
  isReasign,
  isConfirm,
  isPackOrder,
  isNextOrder,
  isPsycho,
  isChangeTimeDisabled,
  isCancel,
  timezone,
  isAfterConsultation,
  isStartConsultation,
}) => {
  const [active, setActive] = useState(1);
  const isModalSignup = !isLogged || !isConfirmEmail;

  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && (
        <>
          <BackHeaderElem id={data.id} />
          <Container overflow={active === 3} className="chat">
            <UserHeader role={role} {...data} />
            <TabSecondaryElem
              isFullWith={false}
              tabList={role === USER_ROLE.USER ? tabListUser : tabListPsycho}
              active={active}
              setActive={setActive}
            />
            {active === 1 && (
              <Wrapper>
                <GridWrapper>
                  <InfoConsultation {...data} />
                  <ModalElem
                    canDismiss={!isModalSignup}
                    open={isModalSignup}
                    onClose={() => {}}
                  >
                    {!isLogged && <OrderAuthSignupContainer id={Number(data.id)} />}

                    {!isConfirmEmail && isLogged && (
                      <AuthVerificationContainer
                        isOrderPage
                        type={AUTH_TYPE.EMAIL}
                        withoutRedirect
                      />
                    )}
                  </ModalElem>

                  {!data?.payment?.isPaid && !isPsycho && (
                    <ConsultationPaymentLinkContainer
                      isChangeTimeDisabled={isChangeTimeDisabled}
                      role={role}
                      paymentId={data?.id}
                      price={data?.price}
                      duration={data?.duration}
                      isPaid={data?.payment?.isPaid}
                      isCancel={isCancel}
                      isStartConsultation={isStartConsultation}
                    />
                  )}

                  {isReasign && (
                    <ConsultationReassignContainer
                      isChangeTimeDisabled={isChangeTimeDisabled}
                      isStartConsultation={isStartConsultation}
                      clientTimezone={data?.user?.numberTimezone}
                      psychologistId={data?.psychologist?.id}
                      currentDate={{
                        timeView: data?.timeView,
                        dayView: data?.dayView,
                      }}
                      consultationId={data?.id}
                      role={role}
                      duration={data?.duration}
                    />
                  )}

                  {isConfirm && (
                    <ConsultationConfirmContainer
                      isStartConsultation={isStartConsultation}
                      isPaid={data?.payment?.isPaid}
                      role={role}
                      timezone={timezone}
                      consultationId={data?.id}
                      url={data.eventLink ?? ''}
                      duration={data?.duration}
                      isCompleteClient={data?.isCompleteClient}
                      isCompletePsycho={data?.isCompletePsycho}
                      isAfterConsultation={isAfterConsultation}
                    />
                  )}
                  {/* {isPackOrder && <PackOrder id={data.categoryId} />}
                  {isNextOrder && (
                    <UserNextConsultationContainer
                      isChangeTimeDisabled={isLoading}
                      psychologistId={data.psychologist.id}
                      duration={data.duration}
                      clientTimezone={timezone}
                      consultationId={data.id}
                    />
                  )} */}
                </GridWrapper>
              </Wrapper>
            )}
            {active === 2 && (
              <ClientContainer
                {...data?.user}
                file={data.file}
                category={data?.category}
                comment={data?.comment}
              />
            )}
            {active === 3 && (
              <ConsultationItemChatContainer
                consultationId={data.id}
                isAdmin={role === USER_ROLE.ADMIN}
                disabled={!role}
                isNotPaid={!data?.payment?.isPaid}
                isOver={data.status === CONSULTATION_STATUS.COMPLETED}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
  margin-top: ${Spacing(1)};

  @media screen and (max-width: 1160px) {
    margin-bottom: 199px;
    margin-top: unset;
    gap: ${Spacing(3)};
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 1350px) {
    display: flex;
    flex-direction: column;
    gap: ${Spacing(3)};
  }
`;

const Container = styled.div<{ overflow?: boolean }>`
  display: grid;
  grid-gap: ${Spacing(3)};

  ${({ overflow }) =>
    overflow &&
    css`
      @media screen and (min-width: 500px) {
        height: 100%;
        grid-template-rows: min-content min-content auto;
      }
    `}

  @media screen and (max-width: 1110px) {
    grid-gap: ${Spacing(3)};
  }

  @media screen and (max-width: 1160px) {
    padding-bottom: 0;
  }
`;

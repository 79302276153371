import { History } from 'history';

export function addParamToUrl(
  name: string,
  value: string | number | null,
  history: History<unknown>,
) {
  const location = window.location;

  const searchParam = new URLSearchParams(location.search);

  if (value === '' || value === null || value === undefined) {
    searchParam.delete(name);
  } else {
    let paramValue = String(value);
    paramValue = paramValue.replace(/,/g, ',');

    searchParam.set(name, paramValue);
  }

  const encodeUri = decodeURIComponent(searchParam.toString()).replace(
    /%20/g,
    '+',
  );

  history.replace(`${location.pathname}?${encodeUri}`);
}

export function getUrlParam(name: string): string | null {
  const searchParam = new URLSearchParams(window.location.search);
  return searchParam.get(name);
}

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { HomeCatalogContainer } from '../../epic/home-catalog';
import { HomeHeaderContainer } from '../../epic/home-header';
import { HomeHeroContainer } from '../../epic/home-hero';
import { HomeWorkContainer } from '../../epic/home-work';
import { HomeFooterContainer } from '../../epic/home-footer';
import { LANDING_ID_ENUM } from '../../epic/home-header/constant';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { HomeFagContainer } from '../../epic/home-faq';
import { HomeAboutContainer } from '../../epic/home-about';
import { LandingAdvantageContainer } from '../../epic/landing-advantage';
import { HomeReviewContainer } from '../../epic/home-review';
import { ThemesOfTherapy } from '../../epic/themes-of-therapy';
import { TITLE } from '../../epic/themes-of-therapy/constant';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem background="active">
      <LayoutAppElem oneColumn>
        <SectionHome id={LANDING_ID_ENUM.HOME}>
          <HomeHeaderContainer />
        </SectionHome>
        <SectionHome id={LANDING_ID_ENUM.HOME}>
          <HomeHeroContainer />
        </SectionHome>
        <SectionService id={LANDING_ID_ENUM.SERVICES}>
          <HomeCatalogContainer />
        </SectionService>
        <SectionWork id={LANDING_ID_ENUM.ABOUT}>
          <HomeWorkContainer />
        </SectionWork>
        <SectionAbout>
          <HomeAboutContainer />
        </SectionAbout>
        <SectionAdvantage>
          <LandingAdvantageContainer />
        </SectionAdvantage>
        <section>
          <HomeReviewContainer />
        </section>
        <SectionThemeOfTherapy>
          <ThemesOfTherapy title={TITLE.KNOWLEDGE_LANDING} />
        </SectionThemeOfTherapy>

        <SctionQuestions>
          <HomeFagContainer />
        </SctionQuestions>
        <SectionFooter id={LANDING_ID_ENUM.ABOUT}>
          <HomeFooterContainer />
        </SectionFooter>
      </LayoutAppElem>
    </PageElem>
  );
};

const SectionThemeOfTherapy = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  margin-top: ${Spacing(11)};
  @media screen and (max-width: 768px) {
    margin-top: ${Spacing(12)};
  }
`;

const SectionAdvantage = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(26)} 0 ${Spacing(24)};
  @media screen and (max-width: 1024px) {
    padding: ${Spacing(24)} 0 ${Spacing(22)};
  }
  @media screen and (max-width: 768px) {
    padding: ${Spacing(6)} 0;
    margin-bottom: ${Spacing(14)};
  }
`;

const SectionHome = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const SectionService = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionWork = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
`;

const SectionAbout = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(14)} 0;
`;

const SctionQuestions = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionFooter = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
`;

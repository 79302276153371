import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { AlertActionElem } from '../../common/alert-action';
import {
  CONSULTATION_DATA_INTER,
  CONSULTATION_STATUS,
} from '../../data/consultation/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { ConsultationAdminStatusContainer } from '../consultation-admin-status';
import { ConsultationAdminReassignContainer } from '../consultation-admin-reassign';
import { BackHeaderElem } from '../../common/back-header';
import { InforamationContainer } from './frame/information';
import { UserHeader } from './frame/user-header';
import { ConsultationAdminAddUserContainer } from '../consultation-admin-add-user';
import { InviteContainer } from './frame/invite';
import { USER_ROLE } from '../../data/user/constant';

export const Component: React.FC<{
  data: CONSULTATION_DATA_INTER;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
  role?: USER_ROLE;
  timezone?: any;
  isPsycho: boolean;
  isChangeTimeDisabled: boolean;
  isCancel: boolean;
  isStartConsultation: boolean;
  isAfterConsultation: boolean;
  isReasign: boolean;
  isConfirm: boolean;
  isPackOrder: boolean;
  isNextOrder: boolean;
  // isLogged?: boolean;
  // isConfirmEmail?: boolean;
}> = ({
  data,
  isSuccess,
  isLoading,
  isError,
  errorMessage,
  role,
  timezone,
  isPsycho,
  isChangeTimeDisabled,
  isCancel,
  isStartConsultation,
  isAfterConsultation,
  isReasign,
  isConfirm,
  isPackOrder,
  isNextOrder,
  // isLogged,
  // isConfirmEmail,
}) => {
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const userTimezone = auth.user ? auth?.user?.timezone : timezone;

  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && (
        <>
          <BackHeaderElem id={data.id} />
          <Container>
            <UserHeader {...data} />
            <InforamationContainer {...data} />
            <Double>
              <ConsultationAdminReassignContainer
                isPaid={data?.payment?.isPaid}
                consultationId={data?.id}
                userTimezone={userTimezone}
                psychologistId={data?.psychologist?.id}
                startDate={data?.date}
                status={data.status}
                duration={data.duration}
              />

              <ConsultationAdminStatusContainer
                consultationId={data.id}
                status={data.status}
              />
              {!data.user && (
                <ConsultationAdminAddUserContainer consultationId={data.id} />
              )}
              <InviteContainer id={data?.joinId} isUser={!!data.user} />
            </Double>
          </Container>
        </>
      )}
    </>
  );
};


const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(5)};
  margin-top: 38px;

  @media screen and (max-width: 1160px) {
    margin-bottom: ${Spacing(25)};
  }
`;

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

import React from 'react';
import { useDispatch } from '../../lib/store';
import { ACTION_ERROR_INTER, ACTION_RESPONSE_INTER } from './constant';

import { useMutation } from 'react-query';

import { fetch } from './action';
import { useHistory } from 'react-router';
import { resetAuthData, setAuthData } from '../../data/auth/action';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import appleIcon from '../../asset/svg/auth/apple.svg';
import AppleSignin, {
  AppleAuthOptions,
  AppleAuthResponse,
} from 'react-apple-signin-auth';

import { CONSULTATION_LIST_PAGE_PATH } from '../../page/consultation-list';
import { AUTH_LOGIN_ORDER_LOCAL_STORE } from '../order-auth-signup/constant';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { SIZE_FONT_DATA, SIZE_FONT_ENUM } from '../../theme/size';
import { appPreloadAllLocalStateData } from '../../data/app';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';

let options: AppleAuthOptions = {
  clientId: 'siayvo.net.auth',
  redirectURI: 'https://siayvo.net/konsultatsiya',
  scope: 'email name',
  state: '12345',
  nonce: 'nonce',
  usePopup: true,
};

export const Container: React.FC<{ id?: number }> = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSuccess = (d: any, values: any) => {
    const data: ACTION_RESPONSE_INTER = d as unknown as ACTION_RESPONSE_INTER;

    dispatch(setAuthData(data.accessToken)).then(async () => {
      const authLoginOrderPath = localStorage.getItem(
        AUTH_LOGIN_ORDER_LOCAL_STORE,
      );

      await dispatch(appPreloadAllLocalStateData()).then(() => {
        if (!id) {
          history.push(CONSULTATION_LIST_PAGE_PATH);
        }

        if (authLoginOrderPath) {
          history.push(authLoginOrderPath);
        } else if (id) {
          history.push(CONSULTATION_PAGE_PATH_DYNAMIC(id));
        } else {
          // history.push(CONSULTATION_LIST_PAGE_PATH);

          history.push('/specialist-list');
        }
      });
    });
  };

  const action = useMutation((...props) => fetch(...props, id), { onSuccess });

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <>
      <AppleSignin
        authOptions={options}
        uiType="light"
        onSuccess={(response: AppleAuthResponse) => {
          console.log(response, 'applesignInResponse');
          action.mutate(response);
        }}
        // skipScript={true}
        onError={(error: any) => console.log(error, 'applesignInError')}
        render={(props: any) => (
          <Button
            {...props}
            tid="AUTH.LOGIN.APPLE"
            iconLeft={appleIcon}
            type="button"
            padding="15px 20px"
            // onClick={signIn}
          />
        )}
      />

      {isLoading() && <LoaderElem />}
      {isError() && <AlertActionElem type="error" tid={getErrorMessage()} />}
    </>
  );
};

const Button = styled(ButtonElem)`
  background: #f2f2f2 !important;

  div {
    justify-content: center;
  }
  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]} !important;
  }
`;

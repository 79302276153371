import { HttpRequest } from '../../lib/http';

export const API = {
  GET: {
    TYPE: 'GET',
    URL: ({
      selectedDate,
      psychologistId,
      duration,
    }: {
      selectedDate: string;
      psychologistId: string;
      id?: string;
      duration?: number;
    }) =>
      `consultation/available-time?selectedDate=${selectedDate}&psychologistId=${psychologistId}${
        duration ? `&duration=${duration}` : ''
      }`,
  },
  GET_DAY: {
    TYPE: 'GET',
    URL: ({
      psychologistId,
      duration,
    }: {
      psychologistId: string;
      id?: string;
      duration?: number;
    }) =>
      `consultation/available-day?psychologistId=${psychologistId}${
        duration ? `&duration=${duration}` : ''
      }`,
  },
};

export const actionTime = ({
  selectedDate,
  psychologistId,
  duration,
}: {
  selectedDate: string;
  psychologistId: string;
  duration?: number;
}) => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL({ selectedDate, psychologistId, duration }),
  });
};

export const actionDay = ({
  psychologistId,
  duration,
}: {
  psychologistId: string;
  duration?: number;
}) => {
  return HttpRequest({
    method: API.GET_DAY.TYPE,
    url: API.GET_DAY.URL({ psychologistId, duration }),
  });
};

import { USER_DATA_RAW } from '../../epic/profile-user-update-data/constant';
import { FILE_INTER } from '../file/constant';
import { GENDER_ENUM } from '../user/constant';

export enum ANIMAL_AGE {
  TWO_TO_SIX_MONTHS = '2-6m',
  SIX_MONTHS_TO_ONE_YEAR = '6m-1y',
  ONE_TO_SEVEN_YEARS = '1y-7y',
  SEVEN_YEARS_AND_ABOVE = '7y and above',
}

export enum ANIMAL_TYPE_ENUM {
  DOG = 'dog',
  CAT = 'cat',
  BIRD = 'bird',
  RAT = 'rat',
  FERRET = 'ferret',
  REPTILE = 'reptile',
  RABBIT = 'rabbit',
  GUINEA_PIG = 'guinea_pig',
  HAMSTER = 'hamster',
  CHINCHILLA = 'chinchilla',
  OTHER = 'other',
}

export interface ANIMAL_RAW_INTER {
  id: string;
  user: USER_DATA_RAW;
  updateDate: string;
  createDate: string;
  type: ANIMAL_TYPE_ENUM;
  gender: GENDER_ENUM;
  photo?: FILE_INTER | null;
  description?: string;
  hasSpecialNeeds: boolean;
  specialNeedsDescription?: string;

  // Dog-specific fields
  dogBreed?: string;
  dogAge?: ANIMAL_AGE;
  dogWeightOverThirtykg: boolean;
  dogPullsLeash: boolean;
  dogPicksFromGround: boolean;
  dogCantTakeAway: boolean;
  dogCantStayAlone: boolean;
  dogFear?: string;
  dogIsAggressive: boolean;
  dogAggressionReason?: string;
  dogVaccinationPassport?: string;

  // Cat-specific fields
  catBreed?: string;
  canBite: boolean;
  catCanStayAlone: boolean;
  catAge?: ANIMAL_AGE;
  catVaccinationPassport?: string;
}

import React, { useState } from 'react';
import { Component } from './component';
import { useHistory, useLocation } from 'react-router';

import { USER_ROLE } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { data } from './constant';
import { useSwipeable } from 'react-swipeable';
// import { ComponentNew } from './component';
// import useThemeAndAuth from '../../lib/util/useThemeAndAuth';

export const Container: React.FC<{}> = ({}) => {
  const history = useHistory();
  const router = useLocation();

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const handleDropdownToggle = (path: string) => {
    setOpenDropdown(openDropdown === path ? null : path);
  };
  const swipeHandlers = useSwipeable({
    onSwipedDown: () => setOpenDropdown(null),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const redirect = (path: string) => {
    history.push(path);
  };

  // const pathname = router.pathname;

  const isActivePath = (...path: string[]) =>
    path.some((el) => router.pathname.includes(el));

  // const isActive = (path: string) => pathname.includes(path);
  const role = auth.user?.role;

  return (
    <Component
      redirect={redirect}
      menuData={data}
      isActive={isActivePath}
      // isAdmin={role === USER_ROLE.ADMIN}
      role={role}
      openDropdown={openDropdown}
      swipeHandlers={swipeHandlers}
      toggleMenu={handleDropdownToggle}
    />
  );
};

import React from 'react';
import styled from 'styled-components';

import { DATA_INTER } from './constant';
import { HeaderProfile } from './frame/header';
import { Spacing } from '../../theme';
import { Profile } from './frame/profile';
import { FileComponent } from './frame/history';
import { Category } from './frame/category';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { AlertActionElem } from '../../common/alert-action';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}> = ({ data, isSuccess, isLoading, isError, errorMessage }) => {
  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && (
        <>
          <Container>
            <HeaderProfile />
            <Profile data={data} />
            <FileComponent data={data} />
            {data?.category &&
              data.category.map((e) => (
                <Category data={{ ...e, duration: data?.duration }} />
              ))}
          </Container>
        </>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  grid-gap: ${Spacing(4)};
  flex-direction: column;
  margin-bottom: ${Spacing(10)};
  // margin-top: 84px;
`;

import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const updateAction = (payload: string[]) => {
  return HttpRequest({
    method: API.POST.TYPE,
    url: API.POST.URL,
    data: { ...payload },
  });
};

export const createWeekendAction = (payload: string[]) => {
  return HttpRequest({
    method: API.POST_WEEKEND.TYPE,
    url: API.POST_WEEKEND.URL,
    data: { ...payload },
  });
};

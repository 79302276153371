import React from 'react';
import { RouteComponentProps } from 'react-router';
import ReactDOM from 'react-dom';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { PsychoFilterContainer } from '../../epic/psycho-filter';
import { PsychoListContainer } from '../../epic/psycho-list';
import { MobileMenuContainer } from '../../epic/mobile-menu';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <>
      {ReactDOM.createPortal(
        <PsychoFilterContainer />,
        document.getElementById('layoutApp') || document.body,
      )}
      <PageElem>
        <LayoutAppElem>
          <LayoutElem size="default" direction="column">
            <LayoutSectionElem spacing={6}>
              <PsychoListContainer />
            </LayoutSectionElem>
          </LayoutElem>
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};

import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';

export const Component: React.FC<{
  handleClick: Function;
  isErrorCategory: boolean;
  errorMessage?: string;
  isError: boolean;
}> = ({ handleClick, isErrorCategory, errorMessage, isError }) => {
  return (
    <>
      {isError && <AlertActionElem text={errorMessage} />}

      {isErrorCategory && (
        <AlertActionElem tid="CARETAKER_LIST.CATEGORY_NOT_SET" />
      )}

      <ButtonElem
        onClick={() => handleClick()}
        type="submit"
        tid="CARETAKER_LIST.CARD.ORDER"
      />
    </>
  );
};

import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import { AlertEmptyElem } from '../../common/alert-empty';

import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
// import { IonInfiniteScroll } from '@ionic/react';
import { SkeletonListElem } from '../../common/skeleton-list';
import {
  CONSULTATION_ITEM_DATA_INTER,
  CONSULTATION_ITEM_LIST_DATA_INTER,
} from './constant';
import { Card } from './frame/card';

export const Component: React.FC<{
  data?: CONSULTATION_ITEM_LIST_DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isFetching?: boolean;
  fetchNextPage: Function;
  isIdle?: boolean;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  isFetching,
  fetchNextPage,
  isIdle,
}) => {
  return (
    <Wrapper spacing={5}>
      {isError && <AlertActionElem text={errorMessage} />}
      <Container>
        {data && isSuccess && data.isEmpty && <AlertEmptyElem />}

        <GridStyled>
          {data && !data.isEmpty && (
            <>
              <Head>
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.NAME"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.CATEGORY"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.DURATION"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.DATE"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.PAYMENT_STATUS"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.TYPE"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  tid="CONSULTATION.LIST.HEAD.COUNT"
                  color="textSecondary"
                  size="semiSmall"
                />
              </Head>
              {isSuccess &&
                !isFetching &&
                data?.list.map((item: CONSULTATION_ITEM_DATA_INTER) => (
                  <Card
                    key={`${item.id}-${item?.psychologist?.name}-${item?.user?.name}`}
                    {...item}
                  />
                ))}
            </>
          )}
        </GridStyled>
        {isFetching && !data?.isEmpty && (
          <SkeletonWrapper>
            <SkeletonListElem />
          </SkeletonWrapper>
        )}
        {/* {data &&
          data.list &&
          data.list.length > 39 &&
          !data.isEmpty &&
          !isFetching && (
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                fetchNextPage();
                setTimeout(() => ev.target.complete(), 500);
              }}
            >
              {!isIdle && <PaymentSkeletonElem />}
            </IonInfiniteScroll>
          )} */}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(GridElem)`
  margin-bottom: ${Spacing(10)};
`;

const Head = styled.div`
  display: none;

  @media screen and (min-width: 1025px) {
    display: grid;
    grid-column: span 7;
    grid-template-columns: subgrid;
    justify-items: start;

    padding: ${Spacing(5)};
    width: 100%;
    align-items: center;
    display: grid;
    justify-content: start;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

    & > :last-child {
      text-align: center;
    }
  }
`;

const SkeletonWrapper = styled.div`
  margin-top: ${Spacing(4)};
  min-height: 600px;
  width: 100%;
`;

const Container = styled.div`
  /* overflow-x: auto; */
  scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
    transparent;
  scrollbar-width: thin;
  width: 100%;
`;

const GridStyled = styled(GridElem)`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: ${Spacing(4)} !important;

  @media screen and (max-width: 1222px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: ${Spacing(1)} !important;
  }

  @media screen and (max-width: 1025px) {
    grid-template-columns: 1fr;
  }
`;

import { i18n } from '../../lib/lang';
import { SUBTITLE_LIST } from '../home-about/constant';

export const MODULE_NAME = 'WELLCOME_LOGOUT_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const REVIEW_LIST = [
  {
    id: 3,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.IMG_ALT')}`,
    // title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.TITLE')}`,
    // subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.SUBTITLE')}`,
    name: 'Софія',
    surname: 'Зотова',
    subtitle: 'Панічні атаки, тривога, психосоматичні розлади',
    description:
      "Тривожний стан, психосоматичні розлади, панічні атаки. Я звернулася по допомогу в тяжкий період свого життя. Терапія допомогла мені в багатьох аспектах і покращила мій психологічний стан. Я відчуваю поліпшення, що безпосередньо впливає на якість мого соціального життя. Наталія Назаренко допомогла мені розібратися у своєму стані, зрозуміти в чому причина та як над цим працювати. Також вона чітко і цікаво розкривала теми і моменти, які важко зрозуміти для людини, яка раніше не цікавилась у психології. Наталія співчутлива та зацікавлена психологиня з якою приємно працювати, та яка обов'язково зможе Вам допомогти.",
  },
  {
    id: 1,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.IMG_ALT')}`,
    // title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.TITLE')}`,
    // subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.SUBTITLE')}`,
    name: 'Ніка',
    surname: 'Бражко',
    subtitle: 'Індивідуальна консультація, вигорання',
    description:
      'Доброго і мирного вечора! Хочу написати свій відгук про консультацію, яку сьогодні отримала від Павла Думанського. Найкраще, що зі мною сталося за останній період життя- це спілкування з паном Павлом! Я надзвичайно задоволена його роботою! Людина- професіонал своєї справи! З перших хвилин спілкування виникає довіра до пана Павла. Видно, що він відповідально ставиться до своєї роботи. Вміє вислухати, зрозуміти, пояснити, дати настанови і поради! Це- безцінно! В процесі розмови я почувалася вільною, відкритою і основне, що мене зрозуміли і підтримали! Я дуже-дуже задоволена і раджу пана Павла, як найкращого спеціаліста! Дякую Вам! Успіхів і процвітання!!!',
  },
  {
    id: 5,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.IMG_ALT')}`,
    // title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.TITLE')}`,
    // subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.SUBTITLE')}`,
    name: 'Ігор',
    surname: 'Лацук',
    subtitle: 'Емоційні труднощі, проблеми з самооцінкою',
    description:
      'Прийшов в дуже важкому психологічному стані, через 2 місяці терапії повернувся до життя. Надані техніки використовую кожного дня. Всі мої запити було почуто та опрацьовано. Дуже задоволенний співпрацею. Хороший спеціаліст. Легко було спілкуватись на будь які теми, підібрала індивідуальний підхід у вигляді творчих занять, що допомогло ще більше розслабитися та зрозуміти деякі моменти. Відчувалось значне полегшення після сеансів. Дякую спеціалісту Наталії Назаренко!',
  },

  // {
  //   id: 2,
  //   imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.IMG_SRC')}`,
  //   imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.IMG_ALT')}`,
  //   title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.TITLE')}`,
  //   subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.SUBTITLE')}`,
  // },
  // {
  //   id: 4,
  //   imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.IMG_SRC')}`,
  //   imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.IMG_ALT')}`,
  //   title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.TITLE')}`,
  //   subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.SUBTITLE')}`,
  // },
  // {
  //   id: 6,
  //   imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.IMG_SRC')}`,
  //   imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.IMG_ALT')}`,
  //   title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.TITLE')}`,
  //   subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.SUBTITLE')}`,
  // },
];

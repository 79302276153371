import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const getDateAction = () => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL,
  });
};

export const removeAction = (date: string[]) => {
  return HttpRequest({
    method: API.DELETE.TYPE,
    url: API.DELETE.URL,
    data: { ...date },
  });
};

export const removeWeekendAction = (date: string[]) => {
  return HttpRequest({
    method: API.DELETE_WEEKEND.TYPE,
    url: API.DELETE_WEEKEND.URL,
    data: { ...date },
  });
};

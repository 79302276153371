import React, { useState } from 'react';
import styled from 'styled-components';
import { TextElem } from '../text';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../theme/value';
import { PROPS_TYPE } from '../text/constant';
import { SIZE_FONT_WEIGHT_DATA, SIZE_FONT_WEIGHT_TYPE } from '../../theme/size';

export const Elem: React.FC<{
  textLimit?: number;
  text: string | string[];
  onToggle?: Function;
  color?: COLOR_TYPE;
  isUnderline?: boolean;
  lineHeight?: string;
  onClick?: () => void;
  textType?: SIZE_FONT_WEIGHT_TYPE;
}> = ({
  textLimit = 150,
  text,
  onToggle,
  color = 'textSecondary',
  isUnderline,
  lineHeight = '1.6em',
  onClick,
  textType = 'regular',
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = (e: any) => {
    if (onClick) {
      onClick();

      return;
    }
    e.stopPropagation();
    setIsReadMore(!isReadMore);
    onToggle && onToggle();
  };

  const localText = i18n.t(text);
  const isReadMoreText = localText.length > textLimit;

  const isModalOpen = !!onClick;

  return (
    <TextElemInfo
      isModalOpen={isModalOpen}
      as="p"
      type={textType}
      color={color}
      size="input"
      lineHeight={lineHeight}
    >
      {isReadMore && isReadMoreText
        ? localText.slice(0, textLimit) + '...'
        : localText}

      {isReadMoreText && (
        <SpanStyled
          isModalOpen={isModalOpen}
          isUnderline={isUnderline}
          onClick={toggleReadMore}
          style={{ cursor: 'pointer', marginLeft: '4px' }}
        >
          {isReadMore ? 'Читати більше' : 'Згорнути'}
        </SpanStyled>
      )}
    </TextElemInfo>
  );
};

const TextElemInfo = styled(TextElem)<PROPS_TYPE & { isModalOpen: boolean }>`
  font-size: 13px;
  line-height: 1.6em;
  color: ${({ theme, color }) =>
    color ? theme[color] : theme[COLOR_ENUM.TEXT_SECONDARY]};
  font-weight: ${({ type }) => SIZE_FONT_WEIGHT_DATA[type || 'regular']};
  white-space: ${({ isModalOpen }) =>
    isModalOpen ? '' : 'pre-wrap'} !important;

  @media screen and (max-width: 1160px) {
    font-size: ${({ isModalOpen }) => (isModalOpen ? 13 : 12)}px;
  }
`;

const SpanStyled = styled.span<{ isUnderline?: boolean; isModalOpen: boolean }>`
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  text-decoration: ${({ isUnderline }) => isUnderline && 'underline'};
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media screen and (max-width: 1160px) {
    font-size: ${({ isModalOpen }) => (isModalOpen ? 13 : 12)}px;
  }
`;

import { useState } from 'react';
import { useQuery } from 'react-query';
import { actionTime } from '../../data/time/action';
import { OPTION_ITEM_DATA } from '../../common/select/constant';
import { addHours, format, parseISO } from 'date-fns';

interface USE_FETCH_TIME_PROPS {
  selectedDate: string | null;
  psychologistId: string;
  clientTimezone: number;
  isShouldRefetch?: boolean;
  duration?: number;
}

export const TIME_MODULE_NAME = 'TIME_MODULE_NAME';

export const useFetchTime = ({
  selectedDate,
  psychologistId,
  clientTimezone,
  isShouldRefetch,
  duration,
}: USE_FETCH_TIME_PROPS) => {
  const [timeOption, setTimeOption] = useState<OPTION_ITEM_DATA[]>([]);

  const fetchTime = useQuery(
    [TIME_MODULE_NAME, isShouldRefetch, selectedDate, psychologistId],
    () =>
      actionTime({
        selectedDate: selectedDate || '',
        psychologistId,
        duration,
      }),
    {
      enabled: !!selectedDate,
      onSuccess: (data: string[]) => {
        const optionArr = data.map((isoString) => {
          const date = parseISO(isoString.replace('Z', ''));
          const localizedDate = convertToTimezone(date, clientTimezone);
          const time = format(localizedDate, 'HH:mm');

          return { label: `${time}`, value: isoString };
        });
        setTimeOption(optionArr);
      },
    },
  );

  return {
    timeOption,
    isLoading: fetchTime.isLoading,
    isError: fetchTime.isError,
    isSuccess: fetchTime.isSuccess,
    refetch: fetchTime.refetch,
    status: fetchTime.status,
  };
};

function convertToTimezone(date: Date, timezoneOffset: number): Date {
  return addHours(date, timezoneOffset);
}

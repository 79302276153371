import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { FieldTextElem } from '../../common/field-text';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { FORM_VALUE_ENUM } from './constant';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { DividerElem } from '../../common/divider';
import { GridElem } from '../../common/grid';
import { AuthGoogleContainer } from '../auth-google';
import { FlexLogin } from '../auth-login/component';
import { AuthAppleContainer } from '../auth-apple';
import { FlexElem } from '../../common/flex';
import smsSvg from '../../asset/svg/button/sms.svg';
import { i18n } from '../../lib/lang';
import { LinkElem } from '../../common/link';

export const Component: React.FC<{
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  isFieldError: Function;
  getFieldError: Function;
  formik: FormikValues;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  id: number;
  handleAuth: () => void;
}> = ({
  getFieldError,
  isFieldError,
  getFieldValue,
  formik,
  isSubmitDisabled,
  isLoading,
  isError,
  errorMessage,
  id,
  handleAuth,
}) => {
  return (
    <InfoContainer>
      <TextContainer>
        <FlexElem
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <TextElem
            tid="ORDER.ITEM.REGISTRATION.TITLE_ORDER_REGISTATION"
            type="medium"
            color="textPrimary"
            size="main"
          />
          <div>
            <TextElem
              type="regular"
              color="textPrimary"
              size="input"
              tid="Маєш аккаунт?"
            />{' '}
            <TextElem
              onClick={(e: any) => {
                e.preventDefault();
                handleAuth();
              }}
              type="regular"
              color="default"
              size="input"
              tid="Увійти"
            />
          </div>
        </FlexElem>

        <TextElem
          tid="ORDER.ITEM.REGISTRATION.TITLE_DESCRIPTION_REGISTATION"
          type="regular"
          color="textSecondary"
          size="input"
        />
      </TextContainer>
      <SelectContainer>
        <Column>
          {/* <FieldTextElem
            name={FORM_VALUE_ENUM.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="ORDER.ITEM.REGISTRATION.NAME"
            placeholder="ORDER.ITEM.REGISTRATION.NAME_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
            error={isFieldError(FORM_VALUE_ENUM.NAME)}
          /> */}
          <FieldTextElemStyled
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="ORDER.ITEM.REGISTRATION.EMAIL_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
            type="email"
          />
        </Column>
        {/* <Column>
          <SelectElem
            placeholder="ORDER.ITEM.REGISTRATION.TIMEZONE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TIMEZONE}
            onChange={setFieldValue}
            options={TIMEZONE_OPTION_LIST}
            dynamic
            title="ORDER.ITEM.REGISTRATION.TIMEZONE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIMEZONE)}
            error={isFieldError(FORM_VALUE_ENUM.TIMEZONE)}
            value={setValue(TIMEZONE_OPTION_LIST, FORM_VALUE_ENUM.TIMEZONE)}
          />
          <FieldPasswordElem
            name={FORM_VALUE_ENUM.PASSWORD}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="ORDER.ITEM.REGISTRATION.PASSWORD"
            value={getFieldValue(FORM_VALUE_ENUM.PASSWORD)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PASSWORD)}
            error={isFieldError(FORM_VALUE_ENUM.PASSWORD)}
          />
        </Column> */}

        <ButtonElemStyled
          iconLeft={smsSvg}
          disabled={isSubmitDisabled}
          type="button"
          onClick={formik.handleSubmit}
          tid="ORDER.ITEM.REGISTRATION.CONFIRM"
        />
      </SelectContainer>
      <GridElem spacing={4}>
        <FlexLogin alignItems="center">
          <DividerElem />
          <TextElem
            size="small"
            tid="AUTH.LOGIN.OR_LOGIN"
            color="textSecondary"
          />
          <DividerElem />
        </FlexLogin>
        <FlexElemStyled spacing={2}>
          <AuthGoogleContainer id={id} />
          <AuthAppleContainer id={id} />
        </FlexElemStyled>
      </GridElem>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
    </InfoContainer>
  );
};

const FlexElemStyled = styled(FlexElem)`
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

const FieldTextElemStyled = styled(FieldTextElem)`
  input {
    min-height: 46px;
    padding: 14px ${Spacing(10)} 14px ${Spacing(4)};
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(4)} ${Spacing(10)};
  min-height: 47px;
  max-height: 47px;

  div {
    justify-content: center;
  }

  @capacitor-community \apple-sign-in  max-height: 46px;
`;

const Column = styled.div`
  display: flex;
  gap: ${Spacing(3)};
`;

const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 585px) {
    grid-template-columns: 1fr;
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  flex-direction: column;
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

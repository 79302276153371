import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const fetch = (payload: any, id?: number) => {
  return HttpRequest({
    method: API.TYPE,
    url: API.URL(id),
    data: payload,
  });
};

import { CATEGORY_ENUM } from '../../epic/profile-psycho-create-category/constant';
import { i18n } from '../../lib/lang';
import { FILE_INTER } from '../file/constant';
import {
  PAYMENT_ITEM_DATA_RAW_INTER,
  PAYMENT_STATUS,
} from '../payment/constant';
import {
  PSYCHOLOGIST_ITEM_DATA_INTER,
  PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
  USER_ITEM_DATA_RAW_INTER,
} from '../user/constant';

export enum CONSULTATION_STATUS {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  CANCEL = 'Cancel',
}

export const CONSULTATION_STATUS_VIEW = {
  [CONSULTATION_STATUS.PENDING]: 'CONSULTATION.STATUS.SCHEDULED',
  [CONSULTATION_STATUS.COMPLETED]: 'CONSULTATION.STATUS.COMPLETED',
  [CONSULTATION_STATUS.CANCEL]: 'CONSULTATION.STATUS.CANCEL',
};

export interface CONSULTATION_RAW_INTER {
  id: string;
  createDate: string;
  eventLink: string;
  eventId: string;
  updateDate: string;
  price: number;
  status: CONSULTATION_STATUS;
  date: string;
  comment: string;
  isCompleteClient: boolean;
  isCompletePsycho: boolean;
  category: CATEGORY_ENUM;
  psychologist: PSYCHOLOGIST_ITEM_DATA_RAW_INTER;
  user: USER_ITEM_DATA_RAW_INTER;
  duration: number;
  payment: PAYMENT_ITEM_DATA_RAW_INTER;
  cancellationReason?: string;
  file?: FILE_INTER;
  type: CONSULTATION_TYPE;
  consultationCount: number | null;
  consultationBalance: number | null;
  categoryId: string;
  joinId: string;
}

export interface CONSULTATION_DATA_INTER
  extends Omit<CONSULTATION_RAW_INTER, 'psychologist'> {
  psychologist: PSYCHOLOGIST_ITEM_DATA_INTER;
  timeView: string;
  dayView: string;
  paymentTimeView: string;
  paymentDayView: string;
  paymentStatus: PAYMENT_STATUS;
  typeView: string;
  statusView?: string;
}

export enum CONSULTATION_TYPE {
  TRIAL = 'TRIAL',
  PACK = 'PACK',
  ONE_OFF = 'ONE_OFF',
}

export const CONSULTATION_TYPE_VIEW = {
  [CONSULTATION_TYPE.PACK]: 'CONSULTATION.TYPE.PACK',
  [CONSULTATION_TYPE.TRIAL]: 'CONSULTATION.TYPE.TRIAL',
  [CONSULTATION_TYPE.ONE_OFF]: 'CONSULTATION.TYPE.ONE_OFF',
};

export const CONSULTATION_TYPE_OPTION = [
  {
    value: 2,
    label: i18n.t('CONSULTATION.TYPE.SELECT.TWO'),
  },
  {
    value: 4,
    label: i18n.t('CONSULTATION.TYPE.SELECT.FOUR'),
  },
  {
    value: 8,
    label: i18n.t('CONSULTATION.TYPE.SELECT.EIGHT'),
  },
  {
    value: 12,
    label: i18n.t('CONSULTATION.TYPE.SELECT.TWELVE'),
  },
];

export const CONSULTATION_PACK_PRICE_CONFIG: Record<string, number> = {
  '2': 0,
  '4': 5,
  '8': 7,
  '12': 12,
};

export const getIsAfterDateNew = (targetDateStr: string) => {
  const targetDate = new Date(targetDateStr);

  targetDate.setMinutes(targetDate.getMinutes());

  const currentUtcDate = new Date();

  return currentUtcDate > targetDate;
};

export const getIsAfterDurationDate = (
  targetDateStr: string,
  duration: number,
) => {
  const targetDate = new Date(targetDateStr);

  targetDate.setMinutes(targetDate.getMinutes() + duration);

  const currentUtcDate = new Date();

  return currentUtcDate > targetDate;
};

export const getIsAfterDate = (
  targetDateStr: string,
  timeZoneOffset: number,
) => {
  // 2024-10-24T17:48:26
  const targetDateMillis = Date.parse(targetDateStr);

  const offsetMillis = timeZoneOffset * 60 * 60 * 1000;
  const targetDateWithOffsetMillis = targetDateMillis + offsetMillis;

  const currentDateMillis = Date.now();

  return currentDateMillis >= targetDateWithOffsetMillis;
};

import React from 'react';
import { useMutation } from 'react-query';

import { useDispatch } from '../../lib/store';
import {
  ACTION_ERROR_INTER,
  ACTION_RESPONSE_INTER,
  AUTH_LOGIN_ORDER_LOCAL_STORE,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';
import { Component } from './component';
import { email, required } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { action as fetch } from './action';
import { setAuthData } from '../../data/auth/action';
import { convertAuthDataToType } from '../../data/auth/convert';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { useHistory } from 'react-router';
import { AUTH_LOGIN_PAGE_PATH } from '../../page/auth-login';

export const Container: React.FC<{ id: number }> = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAuth = () => {
    localStorage.setItem(AUTH_LOGIN_ORDER_LOCAL_STORE, String(id));

    history.push(AUTH_LOGIN_PAGE_PATH);
  };

  const storedRequestList = localStorage.getItem('requestList');
  const storedMethodList = localStorage.getItem('methodList');
  const storedPsychoCategory = localStorage.getItem('psychoCategory');

  const requestList = storedRequestList ? JSON.parse(storedRequestList) : [];
  const methodList = storedMethodList ? JSON.parse(storedMethodList) : [];
  const psychoCategory = storedPsychoCategory
    ? JSON.parse(storedPsychoCategory)
    : null;

  const offsetInMinutes = new Date().getTimezoneOffset();
  const offsetInHours = -offsetInMinutes / 60;

  const initialValues = {
    [FORM_VALUE_ENUM.EMAIL]: '',
    [FORM_VALUE_ENUM.NAME]: '',
    [FORM_VALUE_ENUM.PASSWORD]: '',
    [FORM_VALUE_ENUM.TIMEZONE]: offsetInHours,
  };

  const validate = (values: FormikValues) =>
    validation(values, {
      [FORM_VALUE_ENUM.NAME]: [],
      [FORM_VALUE_ENUM.EMAIL]: [email, required],
      [FORM_VALUE_ENUM.PASSWORD]: [],
      [FORM_VALUE_ENUM.TIMEZONE]: [],
    });

  const onSuccess = (d: any) => {
    const data: ACTION_RESPONSE_INTER = d as unknown as ACTION_RESPONSE_INTER;

    dispatch(setAuthData(data.accessToken));
  };

  const action = useMutation((data: FORM_VALUE_INTER) => fetch(data, id), {
    onSuccess,
  });

  const formik: FormikValues = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(
        convertAuthDataToType({
          ...values,
          psychoCategory,
          methodList,
          requestList,
        }),
      );
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (action.isSuccess) {
      return true;
    }
    if (!formik.isValid) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  return (
    <Component
      handleAuth={handleAuth}
      id={id}
      setFieldValue={setFieldValue}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled()}
      getFieldValue={getFieldValue}
      formik={formik}
      isLoading={isLoading()}
      isError={isError()}
      errorMessage={getErrorMessage()}
      setValue={setValue}
    />
  );
};

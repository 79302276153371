export const MODULE_NAME = 'AUTH_SIGNUP_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const AUTH_ACTION_TYPE = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
};

export const API = {
  TYPE: 'POST',
  URL: (id?: number) => `/auth/auth-google${id ? `?order=${id}` : ''}`,
};

export interface ACTION_RESPONSE_INTER {
  accessToken: string;
  newUser?: boolean;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

import React from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../../theme/size';
import { useHistory } from 'react-router';
import { COLOR_ENUM } from '../../../theme/color';
import ArrowLeftSvg from '../../../asset/svg/button/arrow-left.svg';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';
import { PSYCHO_LIST_PAGE_PATH } from '../../../page/psycho-list';
import { TextElem } from '../../../common/text';
import { DATA_INTER } from '../constant';
import arrowRight from '../../../asset/svg/common/arrow-right-full.svg';
import { i18n } from '../../../lib/lang';
import { FlexElem } from '../../../common/flex';
import { HomeHeaderContainer } from '../../home-header';
import { STORE_INTER } from '../../../data/auth/constant';
import { useSelector } from '../../../lib/store';
import { AUTH_MODULE_NAME } from '../../../data/auth';
// import { ProgressStepBar } from '../../../common/progress-step-bar';
// import { STEP_LIST } from '../../../common/progress-step-bar/constant';

export const HeaderProfile: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  const history = useHistory<{ from?: string }>();

  const handleAction = (path?: string | boolean) => {
    if (typeof path === 'string') {
      history.push(`${path}`);
    } else if (path === true) {
      if (history.length > 1) {
        history.go(-1);
      } else {
        history.push(PSYCHO_LIST_PAGE_PATH);
      }
    }
  };

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isLogged = state ? state.logged : null;

  return (
    <>
      {ReactDOM.createPortal(
        <HeaderContainer>
          {!isLogged && <HomeHeaderContainer />}
          {/* <ProgressWrapper isLogged={!!isLogged}>
            <ProgressStepBar
              step={STEP_LIST.PSYCHOLOGIST}
              text={i18n.t('USER.PSYCHO.PROFILE.PROGRESS_BAR')}
            />
          </ProgressWrapper> */}
        </HeaderContainer>,
        document.getElementById('layoutApp') || document.body,
      )}
      <FlexElem alignItems="center" spacing={5} style={{ marginTop: '24px' }}>
        <ButtonComponent onClick={() => handleAction(true)}>
          <img src={ArrowLeftSvg} alt="arrow" />
        </ButtonComponent>
        <BreadCrumbs isLogged={isLogged}>
          <TextElem
            color="textSecondary"
            type="regular"
            size="semiSmall"
            tid="ORDER.BREAD_CRUMB_PSYCHO"
            lineHeight="14px"
          />
          <img src={arrowRight} alt="" />
          <TextElem
            color="textSecondary"
            type="regular"
            size="semiSmall"
            // tid={user?.name}
            lineHeight="14px"
          >
            {data?.name ?? i18n.t('COMMON.NO_NAME')}
            {data?.lastName && (
              <>
                {' '}
                <TextElem
                  color="textSecondary"
                  type="regular"
                  size="semiSmall"
                  tid={data.lastName}
                  lineHeight="14px"
                />
              </>
            )}
          </TextElem>
        </BreadCrumbs>
      </FlexElem>
    </>
  );
};

const ProgressWrapper = styled.div<{ isLogged: boolean }>`
  width: 100%;
  margin: 0 auto;
  padding: ${({ isLogged }) => (isLogged ? `0 ${Spacing(6)}` : Spacing(3))};

  max-width: ${({ isLogged }) =>
    isLogged
      ? `calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.MEDIUM_LARGE]}px + ${Spacing(
          6 * 2,
        )})`
      : `calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px + ${Spacing(
          3 * 2,
        )})`};

  margin-top: ${({ isLogged }) => (isLogged ? Spacing(6) : Spacing(27))};

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(6)} ${Spacing(3)};
  }
`;

const BreadCrumbs = styled.div<{ isLogged?: boolean | null }>`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: ${Spacing(1.5)};
`;

const TextComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacing(3)};
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: end;
  margin: 0 auto;
  align-items: center;
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px + ${Spacing(10 * 2)}
  );
`;

const ButtonComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  padding: ${Spacing(3)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { PaddingContainer } from '../home-footer/component';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { LANDING_QUESTION_ID_ENUM } from './constant';
import { GridElem } from '../../common/grid';
import { useHistory } from 'react-router';
import useIntersectionObserver from '../home-header/hook/useIntersectionObserver';
import {
  scrollToSectionAdaptive,
  scrollToSectionCenter,
} from '../../lib/util/scrollToSection copy';

export const Component: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = useState(true);

  const activeSection = useIntersectionObserver();

  const history = useHistory();

  const handleRedirect = () => {
    // history.push(
    //   `${PSYCHO_LIST_PAGE_PATH}?request=${
    //     requestSlugViewObj[REQUEST_ENUM.UNSTABLE_SELF_ESTIMATION]
    //   },${requestSlugViewObj[REQUEST_ENUM.LACK_OF_CONFIDENCE]}
    //   `,
    // );
  };

  const offerArrayText = [
    {
      text: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.FIRST',
      ),
      sub: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.FIRST_SUB',
      ),
    },
    {
      text: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.SECOND',
      ),
      sub: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.SECOND_SUB',
      ),
    },
    {
      text: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.THIRD',
      ),
      sub: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.THIRD_SUB',
      ),
    },
    {
      text: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.FOURTH',
      ),
      sub: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.FOURTH_SUB',
      ),
    },
    {
      text: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.FIFTH',
      ),
      sub: i18n.t(
        'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_LIST.FIFTH_SUB',
      ),
    },
  ];

  const selfEsteemArray = [
    i18n.t('KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_LIST.FIRST'),
    i18n.t('KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_LIST.SECOND'),
    i18n.t('KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_LIST.THIRD'),
    i18n.t('KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_LIST.FOURTH'),
    i18n.t('KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_LIST.FIFTH'),
  ];

  const treatmentArrayText = [
    i18n.t(
      'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.DEPRESSION_TREATMENT_LIST.FIRST',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.DEPRESSION_TREATMENT_LIST.SECOND',
    ),
    i18n.t(
      'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.DEPRESSION_TREATMENT_LIST.THIRD',
    ),
  ];

  return (
    <Wrapper>
      <TitleContainer>
        <TextElem
          className="title"
          color="textPrimary"
          size="title"
          type="medium"
          tid="KNOWLEDGE_LANDING.TITLE.SELF_ESTEEM_ABOUT"
          lineHeight="36.4px !important"
        />
      </TitleContainer>
      <BodyContainer>
        <Devider />
        <Question>
          {Object.values(LANDING_QUESTION_ID_ENUM).map((sectionId) => (
            <QuestionElem
              key={sectionId}
              active={activeSection == sectionId ? true : false}
              onClick={() => {
                scrollToSectionAdaptive(sectionId);
              }}
            >
              <TextElem
                color="textSecondary"
                type="regular"
                tid={`KNOWLEDGE_LANDING.SELF_ESTEEM.QUESTION.${sectionId.toUpperCase()}`}
              />
            </QuestionElem>
          ))}
        </Question>
        {/* <Question>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.SELF_ESTEEM);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.SELF_ESTEEM);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.SELF_ESTEEM.QUESTION.SELF_ESTEEM"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.HELPING);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.HELPING);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.SELF_ESTEEM.QUESTION.SELF_ESTEEM_HELPING"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.OFFER);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.OFFER);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.SELF_ESTEEM.QUESTION.SELF_ESTEEM_OFFER"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.REMARK);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.REMARK);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.SELF_ESTEEM.QUESTION.SELF_ESTEEM_REMARK"
            />
          </QuestionElem>
          <QuestionElem>
            <TextElem
              onClick={() => {
                if (!isOpen) {
                  handleOpenMoreText(LANDING_QUESTION_ID_ENUM.HELP);
                }
                scrollToSectionCenter(LANDING_QUESTION_ID_ENUM.HELP);
              }}
              color="textSecondary"
              type="regular"
              tid="KNOWLEDGE_LANDING.SELF_ESTEEM.QUESTION.SELF_ESTEEM_HELP"
            />
          </QuestionElem>
        </Question> */}
        <Devider />
        <Answer>
          <Section>
            <TextElem
              color="textPrimary"
              type="light"
              size="label"
              tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.FIRST"
              lineHeight="21px"
            />
            <TextElem color="textPrimary" type="medium" size="label">
              {i18n.t('KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SUBTITLE_BOLD')}
              <TextElem
                style={{ display: 'contents' }}
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SUBTITLE"
                lineHeight="21px"
              />
            </TextElem>
            <TextElem
              className="phrase"
              color="borderActive"
              type="medium"
              size="label"
              tid="KNOWLEDGE_LANDING.SELF_ESTEEM.PHRASE.SELF_ESTEEM"
              lineHeight="28.8px !important"
            />
          </Section>
          <ContainerMoreText isOpen={isOpen}>
            <Section id={LANDING_QUESTION_ID_ENUM.SELF_ESTEEM}>
              {/* <div id={LANDING_QUESTION_ID_ENUM.SELF_ESTEEM}> */}
              <TextElem
                className="subtitle"
                color="textPrimary"
                type="medium"
                size="title"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_FIRST"
                // style={{ paddingBottom: '8px' }}
              />
              {/* </div> */}
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                style={{ display: 'contents' }}
              >
                <TextElem
                  color="textPrimary"
                  type="light"
                  size="label"
                  tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_SECOND_SUB"
                  style={{ display: 'contents' }}
                />
                <TextElem
                  style={{ display: 'contents' }}
                  color="textPrimary"
                  type="medium"
                  size="label"
                  tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_SECOND_BOLD"
                />
                {i18n.t(
                  'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_SECOND',
                )}
              </TextElem>
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_THIRD"
              />
              <TextElem color="textPrimary" type="light" size="label">
                <TextElem
                  color="textPrimary"
                  type="medium"
                  size="label"
                  tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_FOURTH_BOLD_START"
                />
                {i18n.t(
                  'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_FOURTH',
                )}

                <TextElem
                  color="textPrimary"
                  type="medium"
                  size="label"
                  tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_FOURTH_BOLD_END"
                />
              </TextElem>
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_FIFTH"
              />
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_SIXTH"
              />
              <TextElem
                // className="subtitle"
                // style={{ padding: '8px 0' }}
                color="textPrimary"
                type="medium"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_SEVENTH"
              />
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_EIGHT"
              />
              <TextElem
                color="textPrimary"
                type="medium"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_NINTH"
              />
              <ul>
                {selfEsteemArray.map((elem) => (
                  <ListItemUl>
                    <TextElem
                      color="textPrimary"
                      type="light"
                      size="label"
                      tid={elem}
                    />
                  </ListItemUl>
                ))}
              </ul>
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_TEN"
              />
            </Section>
            {/* SYMPTOM */}
            <Section id={LANDING_QUESTION_ID_ENUM.HELPING}>
              <TextElem
                className="subtitle"
                color="textPrimary"
                type="medium"
                size="title"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.QUESTION.HELPING"
              />
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_HELPING_FIRST"
              />
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_HELPING_SECOND"
              />
            </Section>

            <Section id={LANDING_QUESTION_ID_ENUM.OFFER}>
              <TextElem
                className="subtitle"
                color="textPrimary"
                type="medium"
                size="title"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_FIRST"
              />
              <ul>
                {offerArrayText.map((elem) => (
                  <ListItemUl>
                    <TextElem color="textPrimary" type="medium" size="label">
                      {elem.text}
                      <TextElem
                        style={{ display: 'inline' }}
                        color="textPrimary"
                        type="light"
                        size="label"
                        tid={elem.sub}
                      />
                    </TextElem>
                  </ListItemUl>
                ))}
              </ul>
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.SELF_ESTEEM_OFFER_SECOND"
              />
            </Section>
            <Section id={LANDING_QUESTION_ID_ENUM.REMARK}>
              <TextElem
                className="subtitle"
                color="textPrimary"
                type="medium"
                size="title"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.QUESTION.REMARK"
              />
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.MENTAL_TRAUMA_REMARK_FIRST"
              />
              <GridElem spacing={4}>
                <TextElem color="textPrimary" type="light" size="label">
                  {i18n.t(
                    'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.MENTAL_TRAUMA_REMARK_SECOND',
                  )}
                  <TextElem
                    style={{ display: 'contents' }}
                    color="textPrimary"
                    type="medium"
                    size="label"
                    tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.MENTAL_TRAUMA_REMARK_SECOND_NAME"
                  />
                </TextElem>

                <TextElem color="textPrimary" type="light" size="label">
                  {i18n.t(
                    'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.MENTAL_TRAUMA_REMARK_THIRD',
                  )}
                  <TextElem
                    style={{ display: 'contents' }}
                    color="textPrimary"
                    type="medium"
                    size="label"
                    tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.MENTAL_TRAUMA_REMARK_THIRD_NAME"
                  />
                </TextElem>
              </GridElem>
              {/* <TextElem color="textPrimary" type="light" size="label">
              {i18n.t(
                'KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.MENTAL_TRAUMA_REMARK_FOURTH',
              )}
              <TextElem
                color="textPrimary"
                type="medium"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.MENTAL_TRAUMA_REMARK_FOURTH_NAME"
              />
            </TextElem> */}
            </Section>

            <Section>
              <TextElem
                className="phrase"
                color="borderActive"
                type="medium"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.PHRASE.SELF_ESTEEM_END"
              />
              <TextElem
                color="textPrimary"
                type="light"
                size="label"
                tid="KNOWLEDGE_LANDING.SELF_ESTEEM.TEXT.MENTAL_TRAUMA_HELP_FIRST"
              />
            </Section>

            <section id={LANDING_QUESTION_ID_ENUM.HELP}>
              <Button
                onClick={() => handleRedirect()}
                type="landing"
                color="white"
                sizeText="main"
                tid="KNOWLEDGE_LANDING.BUTTON_SPECIALIST"
              />
            </section>
          </ContainerMoreText>
        </Answer>
      </BodyContainer>
    </Wrapper>
  );
};

const TitleContainer = styled.div`
  padding: 0 ${Spacing(30)} ${Spacing(10)};

  @media (max-width: 1325px) {
    padding-right: clamp(0px, 5.1vw, 120px);
    padding-left: clamp(0px, 5.1vw, 120px);
  }

  @media (max-width: 1024px) {
    padding-bottom: ${Spacing(5)};
  }

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ArrowImg = styled.img`
  align-self: baseline;
  margin: auto 0;
`;

const ButtonMoreText = styled.div<{ isOpen: boolean }>`
  // display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
  // color: green;

  width: 100%;
  display: flex;
  flex-direction: row;
  // gap: ${Spacing(3)};
  justify-content: space-between;
  border-radius: 13px;
  border: 1px solid ${COLOR_DATA[COLOR_ENUM.BORDER]};
  padding: ${Spacing(4)} ${Spacing(5)};
`;

const ContainerMoreText = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
  width: 100%;
  gap: ${Spacing(6)};
`;

const Wrapper = styled.div`
  font-family: 'e-UkraineHead' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${Spacing(350)};
  padding: 0 ${Spacing(3)};

  .title {
    font-size: 26px !important;
  }

  @media (max-width: 768px) {
    .title {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px !important;
    }
    padding: 0 ${Spacing(4)};
  }
`;

const Devider = styled.div`
  display: none;
  width: 100%;
  height: 2px;
  background-color: ${COLOR_DATA[COLOR_ENUM.BORDER]};

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const Button = styled(ButtonElem)`
  span > .Apxup:not(:disabled) > * > * {
    font-weight: 500 !important;
  }
  padding: ${Spacing(5)} ${Spacing(7)};
  // max-width: 720px;
  &:not(:disabled) > * > * {
    font-weight: 500 !important;
  }
  span {
    line-height: normal !important;
  }
  max-height: ${Spacing(14)};
  width: 100%;

  @media (max-width: 1024px) {
    max-width: unset;
  }

  @media (max-width: 768px) {
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px !important;
    }
  }
`;

const Section = styled.section`
  display: grid;
  gap: ${Spacing(4)};

  // .subtitle {
  //   padding: ${Spacing(2)} 0;
  // }

  @media (max-width: 768px) {
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
    }
    .phrase {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
    }
    .subtitle {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;
    }
    // .phrase {
    //   font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
    // }
  }
`;

const BodyContainer = styled(PaddingContainer)`
  width: 100%;
  max-width: ${Spacing(350)};
  display: grid;
  grid-template-columns: max-content 1fr;
  // background: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: 0 ${Spacing(30)} ${Spacing(14)};
  gap: ${Spacing(18)};

  @media (max-width: 1325px) {
    padding-right: clamp(0px, 5.1vw, 120px);
    padding-left: clamp(0px, 5.1vw, 120px);
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    // gap: ${Spacing(8)};
    gap: 0;
    padding-bottom: ${Spacing(8)};
  }

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Question = styled.ol`
  display: flex;
  min-width: ${Spacing(86)};
  max-width: ${Spacing(86)};
  height: max-content;
  width: 100%;
  padding: 0;
  margin: 0;
  flex-direction: column;
  gap: ${Spacing(4)};
  counter-reset: my-counter;
  position: sticky;
  top: ${Spacing(26)};
  margin-top: ${Spacing(1)};

  // padding-top: ${Spacing(5)};
  // padding-left: ${Spacing(5.5)}; //компенсація ширини маркерів

  @media (max-width: 1024px) {
    padding-top: ${Spacing(7)};
    padding-bottom: ${Spacing(7)};
    margin-top: 0;
    min-width: unset;
    position: unset;
    top: unset;
  }
`;

const ListItemUl = styled.li`
  padding: 0;
  list-style: none;
  padding-left: ${Spacing(6)};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: ${Spacing(3)};
    // left: 12px;
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  }
`;

const ListOl = styled.ol`
  margin: 0;
  counter-reset: my-counter;
`;

const ListItemOl = styled.li`
  list-style: none;
  padding: 2px 0;
  padding-left: ${Spacing(5)};

  position: relative;

  &::before {
    content: counter(my-counter) '. '; /* Виведення значення лічильника */
    counter-increment: my-counter; /* Збільшення лічильника на 1 */
    position: absolute;
    left: 0;
    top: 6px;
    // transform: translateY(-50%);
  }
`;

const QuestionElem = styled.li<{ active: boolean }>`
  list-style: none;
  padding-left: ${Spacing(5)};

  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};

  position: relative;

  &::before {
    content: counter(my-counter) '. '; /* Виведення значення лічильника */
    counter-increment: my-counter; /* Збільшення лічильника на 1 */
    position: absolute;
    left: 0;
    top: 0px;
    // transform: translateY(-50%);
  }

  &:hover {
    span {
      cursor: pointer;
      color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
      text-decoration: underline;
    }
    &::before {
      cursor: pointer;
      color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
    }
  }
  ${({ active }) =>
    active &&
    css`
      span {
        cursor: pointer;
        color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
        text-decoration: underline;
      }
      &::before {
        cursor: pointer;
        color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
      }
    `}
`;

const Answer = styled(GridElem)`
  width: 100%;
  max-width: 720px;
  gap: ${Spacing(6)};

  span {
    line-height: 25.6px;
  }

  section > .title {
    // padding-bottom: ${Spacing(4)};
    font-size: 26px !important;
  }

  @media (max-width: 1024px) {
    section > .title {
      // padding-bottom: ${Spacing(3)};
    }
    padding-top: ${Spacing(5)};
    max-width: unset;
  }

  @media (max-width: 768px) {
    section > .title {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px !important;
    }

    gap: ${Spacing(5)};
  }
`;

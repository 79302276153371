import {
  CONSULTATION_STATUS_VIEW,
  CONSULTATION_TYPE_VIEW,
} from '../../data/consultation/constant';
import { TIMEZONE_ENUM } from '../../data/user/timezone';
import { convertDay, convertTime } from '../../lib/lang/service';
import {
  convertDateToLocal,
  convertDateToLocalShort,
} from '../../lib/util/dateConvert';
import { CATEGORY_VIEW } from '../profile-psycho/constant';
import {
  CONSULTATION_ITEM_DATA_INTER,
  CONSULTATION_ITEM_LIST_DATA_INTER,
  CONSULTATION_DATA_RAW_INTER,
  PAYMENT_ITEM_DATA_INTER,
} from './constant';

const convertPayment = (
  payment: PAYMENT_ITEM_DATA_INTER | null,
): 'PAID' | 'NOT_PAID' => {
  return payment?.isPaid ? 'PAID' : 'NOT_PAID';
};

export const convertConsultation = (
  consultation: CONSULTATION_DATA_RAW_INTER,
  timezone: TIMEZONE_ENUM,
): CONSULTATION_ITEM_DATA_INTER => {
  if (!consultation) {
    return {} as CONSULTATION_ITEM_DATA_INTER;
  }

  const { time, day } = convertDateToLocalShort(consultation.date, timezone);
  const { time: timeCreateDate, day: dayCreateDate } = convertDateToLocalShort(
    consultation?.createDate,
    timezone,
  );

  return {
    ...consultation,
    date: day,
    time: time,
    createDateView: dayCreateDate,
    createTimeView: timeCreateDate,
    typeView:
      CONSULTATION_TYPE_VIEW[consultation.type] || 'CONSULTATION.TYPE.ONE_OFF',
    category: CATEGORY_VIEW[consultation?.category],
    paymentStatus: convertPayment(consultation?.payment),
    statusView: CONSULTATION_STATUS_VIEW[consultation.status],
  };
};

export const convertConsultationList = (
  consultationList: CONSULTATION_DATA_RAW_INTER[],
  timezone: TIMEZONE_ENUM,
): CONSULTATION_ITEM_LIST_DATA_INTER => {
  return {
    list: consultationList?.map((consultation: CONSULTATION_DATA_RAW_INTER) => {
      return convertConsultation(consultation, timezone);
    }),
    isEmpty: !consultationList?.length,
  };
};

export const convertFormatDate = (dateStr: string | undefined) => {
  if (dateStr) {
    const [day, month, year] = dateStr.split('.');
    return `${year}-${month}-${day}`;
  }
  return dateStr;
};

import styled from 'styled-components';

import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import {
  CONSULTATION_DATA_INTER,
  CONSULTATION_TYPE,
} from '../../../data/consultation/constant';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';
import { PreviewFile } from '../../profile-psycho/frame/preview-file';
import {
  CHIP_STATUS_CONSULTATION_COLOR_TEXT,
  CHIP_STATUS_PAYMENT_COLOR_TEXT,
} from '../../consultation-list/constant';

export const InforamationContainer: React.FC<
  Partial<CONSULTATION_DATA_INTER>
> = ({
  timeView,
  dayView,
  duration,
  price,
  psychologist,
  payment,
  paymentDayView,
  paymentStatus,
  paymentTimeView,
  eventLink,
  cancellationReason,
  file,
  statusView,
  typeView,
  status,
  consultationBalance,
  consultationCount,
  type,
}) => {
  return (
    <>
      <Block>
        <Column>
          <Item>
            <Name
              tid="CONSULTATION.ADMIN.DATE"
              type="light"
              color="textSecondary"
              size="input"
            />
            <Description
              tid={`${dayView} о ${timeView}`}
              type="medium"
              color="textPrimary"
              size="semiSmall"
            />
          </Item>

          <Item>
            <Name
              tid="CONSULTATION.ADMIN.STATUS"
              type="light"
              color="textSecondary"
              size="input"
            />
            <TextElem
              className="paymentStatus"
              size="semiSmall"
              type="medium"
              tid={statusView}
              color={
                status
                  ? CHIP_STATUS_CONSULTATION_COLOR_TEXT[status]
                  : 'textPrimary'
              }
            />
          </Item>
          <Item>
            <Name
              tid="CONSULTATION.ADMIN.PRICE_TITLE"
              type="light"
              color="textSecondary"
              size="input"
            />
            <Description
              tid="CONSULTATION.ADMIN.PRICE"
              tvalue={{ value: String(price) }}
              type="medium"
              color="textPrimary"
              size="semiSmall"
            />
          </Item>
          {type === CONSULTATION_TYPE.PACK && (
            <Item>
              <Name
                tid="CONSULTATION.ADMIN.BALANCE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <Description
                tid="CONSULTATION.ADMIN.BALANCE_VALUE"
                tvalue={{ value: consultationBalance }}
                type="medium"
                color="textPrimary"
                size="semiSmall"
              />
            </Item>
          )}
          {cancellationReason && (
            <Item>
              <Name
                tid="CONSULTATION.ADMIN.CANCEL_TITLE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <Description
                tid={cancellationReason}
                type="medium"
                color="textPrimary"
                size="semiSmall"
              />
            </Item>
          )}

          {eventLink && (
            <LinkItem>
              <Name
                tid="CONSULTATION.ADMIN.URL_TITLE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <LinkMessage onClick={() => window.open(eventLink, '_blank')}>
                <TextElem
                  oneLine
                  tvalue={{ value: eventLink }}
                  tid="CONSULTATION.ADMIN.URL"
                  color="default"
                  size="semiSmall"
                />
              </LinkMessage>
            </LinkItem>
          )}
        </Column>
        <Column>
          {payment && (
            <GridItem>
              <Item>
                <Name
                  tid="CONSULTATION.ADMIN.PAYMENT_DATE"
                  type="light"
                  color="textSecondary"
                  size="input"
                />
                <Description
                  tid={`${paymentDayView} ${paymentTimeView}`}
                  type="medium"
                  color="textPrimary"
                  size="semiSmall"
                />
              </Item>
              <Item>
                <Name
                  tid="CONSULTATION.ADMIN.PAYMENT.MANUAL"
                  type="light"
                  color="textSecondary"
                  size="input"
                />

                <TextElem
                  className="paymentStatus"
                  size="semiSmall"
                  type="medium"
                  tid={`CONSULTATION.PAYMENT.${paymentStatus}`}
                  color={
                    CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus || 'PAID']
                  }
                />
              </Item>
            </GridItem>
          )}
          <Item>
            <Name
              tid="CONSULTATION.ADMIN.DURATION_TITLE"
              type="light"
              color="textSecondary"
              size="input"
            />
            <Description
              tid="CONSULTATION.ADMIN.DURATION"
              tvalue={{ value: String(duration) }}
              type="medium"
              color="textPrimary"
              size="semiSmall"
            />
          </Item>
          <Item>
            <Name
              tid="CONSULTATION.ADMIN.TYPE"
              type="light"
              color="textSecondary"
              size="input"
            />
            <Description
              tid={typeView}
              type="medium"
              color="textPrimary"
              size="semiSmall"
            />
          </Item>
          {type === CONSULTATION_TYPE.PACK && (
            <Item>
              <Name
                tid="CONSULTATION.ADMIN.AMOUNT"
                type="light"
                color="textSecondary"
                size="input"
              />
              <Description
                tid="CONSULTATION.ADMIN.AMOUNT_VALUE"
                tvalue={{ value: consultationCount }}
                type="medium"
                color="textPrimary"
                size="semiSmall"
              />
            </Item>
          )}
        </Column>

        {file && (
          <Column>
            <TitleStyled
              tid="CONSULTATION.ADMIN.FILE"
              type="medium"
              color="textPrimary"
              size="main"
            />
            <PreviewFile {...file} />
          </Column>
        )}
      </Block>
    </>
  );
};

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Spacing(5)};
`;

const LinkMessage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  padding: 15px;

  span {
    text-decoration: underline;
    line-height: 1em;
  }

  cursor: pointer;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const Name = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 14px;
  }
`;

const TitleStyled = styled(TextElem)`
  margin-bottom: ${Spacing(2)};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: ${Spacing(100)};
  overflow: hidden;
`;

const LinkItem = styled(Item)`
  @media screen and (max-width: 400px) {
    max-width: ${Spacing(60)};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(5)};
  flex-wrap: wrap;
  width: 100%;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(5)};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;

    @media screen and (max-width: 790px) {
      grid-template-columns: 1fr;
      gap: ${Spacing(4)};
    }
  }
`;

import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';

import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { LANDING_ID_ENUM } from '../home-header/constant';
import { GridElem } from '../../common/grid';
import { useHistory } from 'react-router';
// import { REQUEST_ENUM, requestSlugViewObj } from '../../data/user/constant';
import ArrowLeftSvg from '../../asset/svg/button/arrow-left.svg';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { PSYCHO_LIST_PAGE_PATH } from '../../page/psycho-list';

export const Component: React.FC<{}> = () => {
  const url = './assets/img/knowledge/landing/self_esteem_hero_full.jpg';
  const history = useHistory();

  const handleRedirect = () => {
    // history.push(
    //   `${PSYCHO_LIST_PAGE_PATH}?request=${
    //     requestSlugViewObj[REQUEST_ENUM.UNSTABLE_SELF_ESTIMATION]
    //   },${requestSlugViewObj[REQUEST_ENUM.LACK_OF_CONFIDENCE]}
    //   `,
    // );
  };

  const handleRedirectToKnowledge = () => {
    history.push('/knowledge');
  };

  return (
    <Wrapper>
      <HeroContainer>
        <HeroBlock url={url}>
          <HeroText>
            <ButtonComponent onClick={() => handleRedirectToKnowledge()}>
              <img src={ArrowLeftSvg} alt="arrow" />
            </ButtonComponent>
            <GridElem spacing={3}>
              <HeroTitle
                color="white"
                size="logo"
                tid="KNOWLEDGE_LANDING.TITLE.SELF_ESTEEM"
                type="regular"
              />
              <HeroSubtitle
                tid="KNOWLEDGE_LANDING.HERO.SELF_ESTEEM"
                color="white"
                type="light"
                size="main"
              />
            </GridElem>

            <Button
              onClick={() => handleRedirect()}
              type="landing"
              color="white"
              sizeText="main"
              tid="KNOWLEDGE_LANDING.HERO.BUTTON"
            />
          </HeroText>
        </HeroBlock>
      </HeroContainer>
    </Wrapper>
  );
};

const ButtonComponent = styled.div`
  width: ${Spacing(10)};
  height: ${Spacing(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  padding: ${Spacing(3)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 49px;
  }
`;

const Wrapper = styled.div`
  font-family: 'e-UkraineHead' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${Spacing(350)};
  padding: 0 ${Spacing(4)};
`;

const HeroContainer = styled.div`
  font-family: 'e-UkraineHead' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  // padding: 0 12px;
  padding-top: ${Spacing(24.5)}; // Щоб була відстань 12px між меню і блоком
  margin: 0 auto;
  max-height: ${Spacing(300)};
  // width: 100%;
  // max-width: ${Spacing(350)};
  max-width: ${Spacing(344)};

  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
`;

const HeroBlock = styled.div<{ url: string }>`
  // max-width: ${Spacing(344)};
  // margin: 0 12px;
  width: 100%;
  height: ${Spacing(100)};
  display: grid;
  gap: ${Spacing(8)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  background-repeat: no-repeat;
  background-position: 30% 43%;
  background-size: cover;

  overflow: hidden;

  // background-position: center;
  // background-image: linear-gradient(
  //   to right,
  //   rgba(0, 0, 0, 0.75),
  //   rgba(0, 0, 0, 0)
  // );

  // transform: scaleX(-1);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${({ url }) => `url(${url})`};
    background-repeat: inherit;
    background-position: inherit;
    background-size: inherit;
    background-color: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );

    transform: scaleX(1); /* Дзеркальне відображення фонового зображення */
    z-index: -1; /* Розміщення псевдоелемента позаду вмісту */
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
    transform: scaleX(-1);
    z-index: -1;
  }

  position: relative; /* Для коректної роботи псевдоелемента */
  z-index: 0;

  @media (max-width: 768px) {
    &::before {
      transform: scaleX(1.2) scaleY(1.2);
      // transform: ;
    }
    &::after {
      // transform: scaleX(1) scaleY(1.2);
      background-image: linear-gradient(
        to top left,
        rgba(0, 0, 0, 0.75),
        rgba(0, 0, 0, 0)
      );
      // background-position: center 100%;
    }

    background-position: 55% 100%;
  }
`;

const HeroText = styled.div`
  display: grid;
  // min-width: ${Spacing(120)};
  max-width: ${Spacing(103.5)};
  // max-height: ${Spacing(63)};
  gap: ${Spacing(8)};
  margin: ${Spacing(11)} 0 ${Spacing(11)} ${Spacing(30)};

  // margin-left: clamp(20px, 6.5vw, 120px);
  margin-left: clamp(20px, 8.6vw, 120px);

  @media (max-width: 1024px) {
    margin-left: clamp(20px, 6vw, 120px);
  }
  @media (max-width: 768px) {
    gap: ${Spacing(7)};
    margin-top: ${Spacing(5)};
    margin-bottom: ${Spacing(5)};
    margin-right: ${Spacing(7)};
  }
`;

const HeroTitle = styled(TextElem)`
  font-family: 'Oks-Free' !important;
  // line-height: 1.5em;
  line-height: 36px;

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
  @media (max-width: 768px) {
    font-size: ${Spacing(8)};
    line-height: 32px;
  }
`;

const HeroSubtitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};
  line-height: 25.6px;

  @media (max-width: 768px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px !important;
    line-height: 20.8px;
  }
`;
const Button = styled(ButtonElem)`
  max-height: ${Spacing(14)};
  &:not(:disabled) > * > * {
    font-weight: 500 !important;
  }
  div > span {
    font-weight: ${SIZE_FONT_WEIGHT_DATA[
      SIZE_FONT_WEIGHT_ENUM.MEDIUM
    ]} !important;
    line-height: ${Spacing(4)};
  }
  padding: ${Spacing(5)} ${Spacing(7)};
  @media (max-width: 768px) {
    &:not(:disabled) > * > * {
      font-weight: 400 !important;
    }
    span {
      line-height: 13px !important;
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px !important;
    }
    padding: ${Spacing(4)} ${Spacing(3.5)};
  }
`;

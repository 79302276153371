import styled from 'styled-components';
import { CONSULTATION_DATA_INTER } from '../../../data/consultation/constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import {
  CHIP_STATUS_PAYMENT_COLOR_TEXT,
  CHIP_STATUS_CONSULTATION_COLOR_TEXT,
} from '../../consultation-list/constant';

export const InfoConsultation: React.FC<CONSULTATION_DATA_INTER> = ({
  duration,
  timeView,
  dayView,
  price,
  typeView,
  paymentStatus,
  consultationCount,
  consultationBalance,
  statusView,
  status,
  payment,
}) => {
  return (
    <MainContainer>
      <TextElem
        tid="CONSULTATION.INFO.TITLE"
        type="medium"
        size="main"
        color="textPrimary"
      />
      <ColumnContainer isPack={!!consultationBalance}>
        <Column>
          <TextElem
            tid="CONSULTATION.INFO.DATE"
            type="light"
            color="textSecondary"
            size="input"
          />
          <TextElem
            tid="CONSULTATION.INFO.DATE_VALUE"
            tvalue={{ time: timeView, day: dayView }}
            type="medium"
            color="textPrimary"
            size="semiSmall"
          />
        </Column>
        <Column>
          <TextElem
            tid="CONSULTATION.INFO.DURATION"
            type="light"
            color="textSecondary"
            size="input"
          />
          <TextElem
            tid="CONSULTATION.INFO.DURATION_VALUE"
            tvalue={{ value: duration }}
            type="medium"
            color="textPrimary"
            size="semiSmall"
          />
        </Column>
        <Column>
          <TextElem
            tid="CONSULTATION.INFO.PRICE"
            type="light"
            color="textSecondary"
            size="input"
          />
          <TextElem
            tid="CONSULTATION.INFO.PRICE_VALUE"
            tvalue={{ value: price }}
            type="medium"
            color="textPrimary"
            size="semiSmall"
          />
        </Column>
        <Column>
          <TextElem
            tid="CONSULTATION.INFO.TYPE"
            type="light"
            color="textSecondary"
            size="input"
          />
          <TextElem
            tid={typeView}
            type="medium"
            color="textPrimary"
            size="semiSmall"
          />
        </Column>
        <Column>
          <TextElem
            tid="CONSULTATION.INFO.STATUS"
            type="light"
            color="textSecondary"
            size="input"
          />
          <TextElem
            tid={statusView}
            type="medium"
            color={CHIP_STATUS_CONSULTATION_COLOR_TEXT[status]}
            size="semiSmall"
          />
        </Column>

        <Column>
          <TextElem
            tid="CONSULTATION.INFO.PAYMENT"
            type="light"
            color="textSecondary"
            size="input"
          />
          <TextElem
            tid={`CONSULTATION.PAYMENT.${paymentStatus}`}
            color={
              CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus] || COLOR_ENUM.ERROR
            }
            type="medium"
            size="semiSmall"
          />
        </Column>
        {consultationCount && (
          <Column>
            <TextElem
              tid="CONSULTATION.INFO.AMOUNT"
              type="light"
              color="textSecondary"
              size="input"
            />

            <TextElem
              tid="CONSULTATION.INFO.AMOUNT_VALUE"
              tvalue={{ value: consultationCount }}
              type="medium"
              color="textPrimary"
              size="semiSmall"
            />
          </Column>
        )}
        {consultationBalance && (
          <Column>
            <TextElem
              tid="CONSULTATION.INFO.BALANCE"
              type="light"
              color="textSecondary"
              size="input"
            />

            <TextElem
              tid="CONSULTATION.INFO.BALANCE_VALUE"
              tvalue={{ value: consultationBalance }}
              type="medium"
              color="textPrimary"
              size="semiSmall"
            />
          </Column>
        )}
      </ColumnContainer>
    </MainContainer>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(1)};
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${Spacing(3)};
  padding: ${Spacing(6)};
  gap: ${Spacing(6)};
  grid-row: 1 / span 2;
  height: max-content;
`;

const ColumnContainer = styled.div<{ isPack: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${({ isPack }) =>
    isPack ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  row-gap: ${Spacing(4)};

  @media screen and (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { ModalElem } from '../../../common/modal';
import { SelectElem } from '../../../common/select';
import { FORM_VALUE_ENUM } from '../constant';
import { Spacing } from '../../../theme';
import { ButtonElem } from '../../../common/button';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../../theme/size';
import { DatePickerElem } from '../../../common/date-picker';
import { TextElem } from '../../../common/text';
import { COLOR_ENUM } from '../../../theme/color';
import { FlexElem } from '../../../common/flex';

export const ModalTimeChange: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  formik: FormikValues;
  isSubmitDisabled: Function;
  timeOption: {
    label: string;
    value: string;
  }[];
  isFieldDisabled: boolean;
  isTimeLoading: boolean;
  isChangeTimeDisabled: boolean;
  dayArr?: string[];
}> = ({
  isFieldError,
  getFieldError,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isTimeLoading,
  isFieldDisabled,
  isChangeTimeDisabled,
  dayArr,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ButtonElemStyled
        type="button"
        disabled={isChangeTimeDisabled || isSubmitDisabled()}
        onClick={handleOpen}
        tid="CONSULTATION.USER.REASSIGN"
      />
      <ModalElemStyled onClose={handleClose} open={isOpen}>
        <FlexElem flexDirection="column" spacing={6}>
          <FlexElem flexDirection="column" spacing={4}>
            <TextElem
              tid="CONSULTATION.USER.REASIGN.TITLE"
              type="medium"
              size="main"
              color="textPrimary"
            />
            <TextElem
              tid="CONSULTATION.USER.REASIGN.DESCRIPTION"
              size="input"
              type="light"
              color="textSecondary"
            />
          </FlexElem>
          <Double>
            <DatePickerElem
              isDisabled={isFieldDisabled}
              name={FORM_VALUE_ENUM.DATE}
              title="ORDER.ITEM.REGISTRATION.DATE"
              placeholder="ORDER.ITEM.REGISTRATION.DATE_PLACEHOLDER"
              availableDateArr={dayArr}
              handleChange={(e) => {
                if (e instanceof Date) {
                  const formattedDate = e.toLocaleDateString('en-CA');

                  formik.setFieldValue(FORM_VALUE_ENUM.DATE, formattedDate);
                }
              }}
            />
            <SelectElem
              isDisabled={
                isFieldDisabled || !formik.values[FORM_VALUE_ENUM.DATE]
              }
              dynamic
              isLoading={isTimeLoading}
              placeholder="ORDER.ITEM.REGISTRATION.TIME_PLACEHOLDER"
              name={FORM_VALUE_ENUM.TIME}
              onChange={setFieldValue}
              options={timeOption}
              title="ORDER.ITEM.REGISTRATION.TIME"
              errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
              error={isFieldError(FORM_VALUE_ENUM.TIME)}
              value={setValue(timeOption, FORM_VALUE_ENUM.TIME)}
            />
          </Double>
          <ButtonElem
            disabled={isSubmitDisabled()}
            type="submit"
            onClick={() => {
              formik.handleSubmit();
              setIsOpen(false);
            }}
            tid="CONSULTATION.USER.REASSIGN"
          />
        </FlexElem>
      </ModalElemStyled>
    </>
  );
};

const ModalElemStyled = styled(ModalElem)`
  ::part(content) {
    padding: ${Spacing(6)};
    overflow: visible !important;
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(4)} ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }
`;

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
  width: 100%;
`;

import React, { useState } from 'react';
import styled from 'styled-components';

import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

import uploadDocumentSvg from '../../../asset/svg/common/document-upload.svg';
import { ModalElem } from '../../../common/modal';

export const PreviewFile: React.FC<{
  url: string;
  id: string;
  mimetype?: string;
}> = ({ url, id, mimetype }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isImage = mimetype?.startsWith('image/');

  const handleClose = () => {
    setIsOpen(false);
  };

  const openFile = (url: string) => {
    if (isImage) {
      setIsOpen(true);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <ModalElem open={isOpen} onClose={handleClose}>
        <ImageWrapper>
          <Image src={url} />
        </ImageWrapper>
      </ModalElem>

      <FilePreview
        key={id}
        onClick={(event) => {
          event.stopPropagation();
          openFile(url);
        }}
      >
        <ImgStyled src={isImage ? url : uploadDocumentSvg} alt="document" />
      </FilePreview>
    </>
  );
};

const ImageWrapper = styled.div`
  display: flex;
  max-height: 50vh;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-height: 400px;
  object-fit: contain;
  max-width: 100%;
  margin: 0 auto;
`;

const ImgStyled = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  min-width: 98px;
  max-width: 98px;
  height: 70px;
  overflow: hidden;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media screen and (max-width: 1250px) {
    min-width: 77px;
    max-width: 77px;
    min-height: 56px;
  }
`;

import { ROLE_ENUM } from '../../common/button/constant';
import { ANIMAL_RAW_INTER, ANIMAL_TYPE_ENUM } from '../../data/animal/constant';
import { FILE_INTER } from '../../data/file/constant';
import {
  GENDER_ENUM,
  LANGUAGE_ENUM,
  OPTION_INTER,
  PLACEMENT_ENUM,
  SPECIALIST_ENUM,
  TRANSPORT_ENUM,
} from '../../data/user/constant';
import { TIMEZONE_ENUM } from '../../data/user/timezone';
import { CATEGORY_RAW_INTER } from '../profile-psycho/constant';

export const MODULE_NAME = 'CARETAKER_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface CARETAKER_ITEM_DATA_RAW_INTER {
  id: string;
  name: string;
  age?: string | null;
  gender?: GENDER_ENUM | null;
  city?: string;
  country?: string;
  profileDescription?: string | null;
  yearExperience?: number;
  timezone?: TIMEZONE_ENUM;
  language?: LANGUAGE_ENUM[];
  animal: ANIMAL_RAW_INTER;
  addressInfo?: string;
  veterinaryClinicInfo?: string;
  workAnimalType?: ANIMAL_TYPE_ENUM[];
  specialistWith?: SPECIALIST_ENUM[];
  transportation?: TRANSPORT_ENUM[];
  placement?: PLACEMENT_ENUM[];
  ableCompatibilityAnimal?: boolean;
  weightMoreThirty?: boolean;
  ageMoreEight?: boolean;
  withAggressive?: boolean;
  withNaughty?: boolean;
  withBite?: boolean;
  withPhysicalDisability?: boolean;
  district?: string;
  videoIntroduction?: FILE_INTER;
  certificate?: FILE_INTER[];
  aboutPerson?: string;
  category?: CATEGORY_RAW_INTER[];

  minPrice?: number;
  price?: number;
  role?: ROLE_ENUM;
  photo?: {
    name: string;
    id: string;
    url: string;
  };
}

export interface PSYCHO_ITEM_LIST_DATA_RAW_INTER {
  list: CARETAKER_ITEM_DATA_RAW_INTER[];
  isEmpty: boolean;
}

export interface ACTION_RESPONSE_INTER
  extends PSYCHO_ITEM_LIST_DATA_RAW_INTER {}

export interface CARETAKER_ITEM_DATA_INTER
  extends Omit<
    CARETAKER_ITEM_DATA_RAW_INTER,
    'timezone' | 'gender' | 'yearExperience'
  > {
  timezone: string;
  gender?: string;
  yearExperience?: string;
  categoryView?: string[];
}

export interface DATA_INTER {
  list: CARETAKER_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface filterOption {
  caretakerCategory: number;
  age: number;
  placement: PLACEMENT_ENUM;
  ableCompatibilityAnimal: string;
  medicationExperience: string;
  gender: GENDER_ENUM;
  city: string;
  workAnimalType: string;
  specialistWith: string;
  date: Date;
  time: string;
  price: number;
  priceOrder: OPTION_INTER;
  popularityOrder: OPTION_INTER;
  search: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `user/caretaker/list${params}`,
};

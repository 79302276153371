import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import ArrowLeftSvg from '../../asset/svg/button/arrow-left.svg';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { TextElem } from '../text';
import { STORE_INTER } from '../../data/auth/constant';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';

export const Elem: React.FC<{ title?: string; id?: number | string }> = ({
  id,
}) => {
  const history = useHistory();

  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const isLogged = state ? state.logged : null;

  const handleAction = (path?: string | boolean) => {
    if (typeof path === 'string') {
      history.push(`${path}`);
    }

    if (path === true) {
      history.go(-1);
    }
  };
  return (
    <>
      {ReactDOM.createPortal(
        <HeaderContainer isLogged={isLogged}>
          <ButtonComponent onClick={() => handleAction(true)}>
            <ImgStyled src={ArrowLeftSvg} alt="arrow" />
          </ButtonComponent>
          <TextElemStyled
            tid="CONSULTATION.ID"
            tvalue={{ id: String(id) }}
            size="medium"
            type="medium"
            color="textPrimary"
          />
        </HeaderContainer>,
        document.getElementById('layoutApp') || document.body,
      )}
    </>
  );
};

const ImgStyled = styled.img`
  @media screen and (max-width: 1160px) {
    width: 14px;
    height: 14px;
  }
`;

const TextElemStyled = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 15px;
  }
`;

const ButtonComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  padding: ${Spacing(3)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  @media screen and (max-width: 1160px) {
    padding: 10px;
  }

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const HeaderContainer = styled.div<{ isLogged: boolean | null }>`
  width: 100%;
  display: flex;
  gap: ${Spacing(4)};
  align-self: end;
  margin: 0 auto;
  margin-top: ${Spacing(10)};
  margin-bottom: ${Spacing(6)};

  padding: 0px ${({ isLogged }) => (isLogged ? '36px' : '12px')};
  align-items: center;
  max-width: ${({ isLogged }) =>
    isLogged
      ? `calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(
          8 * 2,
        )})`
      : `calc(${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.LARGE]}px + ${Spacing(
          3 * 2,
        )})`};
`;

import React from 'react';
import styled from 'styled-components';

import { DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import { ReadMoreElem } from '../../../common/read-more';
import { DividerElem } from '../../../common/divider';

export const FileComponent: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  const openFile = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <InfoContainer>
      <GridContainer>
        <TextContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHOLOGIST.TAB.SPECIALIZATION"
          />
          <ReadMoreElem
            textLimit={3}
            text={
              data
                ? [
                    `${data?.specialistWith[0]}`,
                    `${data?.specialistWith[1]}`,
                    `${data.specialistWith[2]}`,
                  ]
                : 'USER.PSYCHO.PROFILE.NOT_SET'
            }
          />
        </TextContainer>

        <TextContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.TYPE"
          />

          <ReadMoreElem
            textLimit={5}
            text={
              data
                ? [`${data?.workAnimalType[0]}`, `${data?.workAnimalType[1]}`]
                : 'USER.PSYCHO.PROFILE.NOT_SET'
            }
          />
        </TextContainer>

        <TextContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.HAS_SEVERAL_ANIMAL"
          />

          <ReadMoreElem
            textLimit={1}
            text={
              data
                ? [`${data?.ableCompatibilityAnimal}`]
                : 'USER.PSYCHO.PROFILE.NOT_SET'
            }
          />
        </TextContainer>

        {/* <DividerElem /> */}

        <TextContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.TRANSOPT"
          />

          <ReadMoreElem
            textLimit={3}
            text={
              data?.transportation
                ? [
                    data.transportation[0],
                    data.transportation[1],
                    data.transportation[2],
                  ]
                : ['USER.PSYCHO.PROFILE.NOT_SET']
            }
          />
        </TextContainer>

        <TextContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.SERVICE_PLACE"
          />

          <ReadMoreElem
            textLimit={440}
            text={
              data
                ? [`${data?.placement[0]}`, `${data.placement[1]}`]
                : 'USER.PSYCHO.PROFILE.NOT_SET'
            }
          />
        </TextContainer>

        {/* <DividerElem /> */}

        <TextContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.LANGUAGE_SPEAK"
          />

          <ReadMoreElem
            textLimit={3}
            text={
              data
                ? [
                    `${data?.language[0]}`,
                    `${data?.language[1]}`,
                    `${data?.language[2]}`,
                  ]
                : 'USER.PSYCHO.PROFILE.NOT_SET'
            }
          />
        </TextContainer>

        <TextContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.EXPERIENCE"
          />

          <ReadMoreElem
            textLimit={2}
            text={
              data
                ? [`${data?.yearExperience} років`]
                : 'USER.PSYCHO.PROFILE.NOT_SET'
            }
          />
        </TextContainer>

        <TextContainer>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.EXP_WITH_MEDICAMENT"
          />

          <ReadMoreElem
            textLimit={1}
            text={
              data
                ? [`${data?.medicationExperience}`]
                : 'USER.PSYCHO.PROFILE.NOT_SET'
            }
          />
        </TextContainer>
      </GridContainer>

      <Divider />

      {data?.workingMethods && (
        <WorkingMethodsText>
          <TextElem
            type="medium"
            color="textPrimary"
            size="main"
            tid="USER.PSYCHO.PROFILE.METHODS"
          />
          <ReadMoreElem
            textLimit={200}
            text={data?.workingMethods || 'USER.PSYCHO.PROFILE.NOT_SET'}
          />
        </WorkingMethodsText>
      )}
    </InfoContainer>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const WorkingMethodsText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: ${Spacing(3)};
`;

const Divider = styled(DividerElem)`
  background-color: #f5f6fa;
`;

// const CustomListItem = styled.li`
//   position: relative;
//   padding-left: ${Spacing(3)};

//   &::before {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 0;
//     transform: translateY(-50%);
//     width: 4px;
//     height: 4px;
//     background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
//     border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
//   }
// `;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  gap: ${Spacing(3)};
  padding: 22px ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  span {
    line-height: 1.6em !important;
  }
`;

import { USER_ROLE } from '../../data/auth/constant';
import {
  PAYMENT_ITEM_LIST_DATA_RAW_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
} from '../../data/payment/constant';

export const MODULE_NAME = 'SCHEDULE_ITEM_UPDATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  POST: {
    TYPE: 'POST',
    URL: 'user/schedule/update',
  },
};

export interface ACTION_RESPONSE_INTER
  extends PAYMENT_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends PAYMENT_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface PROPS_TYPE {
  role?: USER_ROLE;
  isPaid?: boolean;
  consultationId?: string | number;
  timezone: number;
  duration: number;
  url: string;
  isCompleteClient: boolean;
  isCompletePsycho: boolean;
}

export enum FORM_VALUE_ENUM {
  DATE = 'date',
  TIME = 'time',
}
export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.DATE]: string;
  [FORM_VALUE_ENUM.TIME]: string[];
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

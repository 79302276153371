import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';

import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';
import {
  CONSULTATION_DATA_INTER,
  CONSULTATION_RAW_INTER,
  CONSULTATION_STATUS,
  CONSULTATION_TYPE,
} from '../../data/consultation/constant';
import { convert } from './convert';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { CONSULTATION_DATA_RAW_INTER } from '../consultation-list/constant';
import { getUrlParam } from '../../lib/util/urlParamUtil';
import { checkTimeDifference } from '../../lib/util/checkTimeDifference';
import {
  getIsAfterDate,
  getIsAfterDateNew,
  getIsAfterDurationDate,
} from '../../lib/util/parseDate';
import { getAction } from './action';

export const Container: React.FC<{ role?: USER_ROLE }> = ({ role }) => {
  const { id } = useParams<{ id: string }>();

  const isAfterPayment = !!getUrlParam('isAfterPayment');
  const history = useHistory();

  const offsetInMinute = new Date().getTimezoneOffset();
  const offsetInHour = -offsetInMinute / 60;

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const isAuth = auth ? auth.logged && auth?.isConfirmEmail : null;
  const timezone = auth.user ? auth.user.timezone : offsetInHour;

  const onSuccess = (data: CONSULTATION_DATA_RAW_INTER) => {
    if (!isAfterPayment) return;
  };

  const preFetch = useQuery([MODULE_NAME, id], () => getAction(id, isAuth), {
    onSuccess: (data: CONSULTATION_DATA_RAW_INTER) => onSuccess(data),
    refetchOnWindowFocus: true,
  });

  const getData = (): CONSULTATION_DATA_INTER => {
    const data = preFetch.data as unknown as CONSULTATION_RAW_INTER;

    if (data) {
      const converedData = convert(data, timezone);

      return converedData;
    }

    return {} as CONSULTATION_DATA_INTER;
  };

  const data = getData();

  const getErrorMessage = () => {
    const error = preFetch.error as ACTION_ERROR_INTER;
    return error?.message;
  };

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const isPsycho = role === USER_ROLE.CARETAKER;
  const isCancel = data.status === CONSULTATION_STATUS.CANCEL;

  const isAfterDurationConsultation = getIsAfterDurationDate(
    data?.date,
    Number(data.duration),
  );
  const isChangeTimeDisabled = checkTimeDifference(data?.date, timezone);
  const isStartConsultation = getIsAfterDateNew(data?.date);

  const isReasign =
    isAuth &&
    !isAfterDurationConsultation &&
    data.payment?.isPaid &&
    !isChangeTimeDisabled &&
    data.status === CONSULTATION_STATUS.PENDING &&
    !isCancel;

  const isConfirm =
    data.payment?.isPaid &&
    data.status === CONSULTATION_STATUS.PENDING &&
    !isCancel &&
    isAuth;

  const getIsPackOrder = () => {
    if (isPsycho) {
      return false;
    }

    if (
      (data.type === CONSULTATION_TYPE.ONE_OFF ||
        data.type === CONSULTATION_TYPE.TRIAL) &&
      isAfterDurationConsultation
    ) {
      return true;
    }
    if (
      data.type === CONSULTATION_TYPE.TRIAL &&
      isAfterDurationConsultation &&
      data.consultationBalance === 0
    ) {
      return true;
    }

    return false;
  };

  const isPackOrder = getIsPackOrder();

  const isNextOrder =
    !isPsycho &&
    data.payment?.isPaid &&
    isAfterDurationConsultation &&
    data.type === CONSULTATION_TYPE.PACK &&
    data.consultationBalance &&
    data.consultationBalance > 0;

  return (
    <Component
      role={role}
      timezone={timezone}
      isPsycho={isPsycho}
      isChangeTimeDisabled={isChangeTimeDisabled}
      isCancel={isCancel}
      isStartConsultation={isStartConsultation}
      isAfterConsultation={isAfterDurationConsultation}
      isReasign={isReasign}
      isConfirm={isConfirm}
      isPackOrder={isPackOrder}
      isNextOrder={!!isNextOrder}
      isLogged={state.logged}
      isConfirmEmail={state?.isConfirmEmail}
      isError={preFetch.isError}
      errorMessage={getErrorMessage()}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess}
      data={data}
    />
  );
};
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
} from './constant';
import { Component } from './component';
import { updateAction } from './action';
import { DAY_ENUM } from '../schedule-list-regular/constant';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { requiredArray } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { OPTION_INTER } from '../../data/user/constant';

export const Container: React.FC<{
  time?: string[];
  day: string;
  id: DAY_ENUM;
  isWeekendDay?: boolean;
}> = ({ time, day, id, isWeekendDay }) => {
  const [isWeekend, setIsWeekend] = useState<boolean | undefined>(isWeekendDay);

  const onSuccess = () => {};

  const action = useMutation(updateAction, { onSuccess });

  const isSubmitDisabled = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const handleUpdate = () => {
    if (action.isLoading) return;

    return action.mutate({
      day: id,
      timeSlot:
        formik.values[FORM_VALUE_ENUM.TIME].length > 0
          ? formik.values[FORM_VALUE_ENUM.TIME].map(
              (e: OPTION_INTER) => e.value,
            )
          : [],
      isWeekend: isWeekend || false,
    });
  };

  const handleSetWeekend = () => {
    if (action.isLoading) return;

    setIsWeekend((prev) => {
      action.mutate({
        day: id,
        isWeekend: !prev,
      });

      return !prev;
    });
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], name: string) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == name,
    )[0];
    return optionList ? optionList : null;
  };

  const config = {
    [FORM_VALUE_ENUM.TIME]: [requiredArray],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.TIME]:
      Array.isArray(time) && time.map((e) => ({ label: e, value: e })),
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: () => {},
  });

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  useEffect(() => {
    if (formik.dirty) {
      handleUpdate();
    }
  }, [formik.values[FORM_VALUE_ENUM.TIME]]);

  return (
    <Component
      getFieldValue={getFieldValue}
      onChangeSelect={onChangeSelect}
      setValue={setValue}
      isWeekend={isWeekend}
      handleSetWeekend={handleSetWeekend}
      day={day}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};

import { extractTimeAndDate } from '../../lib/util/dateConvert';

export function convert(
  dateArray: string[],
  dateWeekendArray: string[],
): { date: string; oldDate: string[]; time: string[]; isWeekend: boolean }[] {
  const resultMap = new Map<
    string,
    { oldDate: string[]; time: string[]; day: string }
  >();

  const dateWeekend = dateWeekendArray ? dateWeekendArray : [];

  const dayArr = [...dateArray, ...dateWeekend];

  dayArr.forEach((dateString) => {
    const dateObj = extractTimeAndDate(dateString);

    const existingEntry = resultMap.get(dateObj.date) || {
      oldDate: [],
      time: [],
      day: '',
    };

    existingEntry.time.push(dateObj.time);
    existingEntry.oldDate.push(dateString);
    existingEntry.day = dateObj.day;

    sortEntry(existingEntry);

    resultMap.set(dateObj.date, existingEntry);
  });

  return Array.from(resultMap.entries()).map(
    ([date, { oldDate, time, day }]) => ({
      date,
      oldDate,
      time,
      day,
      isWeekend: Array.isArray(dateWeekendArray)
        ? dateWeekendArray?.some((weekendDate) => weekendDate.startsWith(day))
        : false,
    }),
  );
}
function sortEntry(entry: { oldDate: string[]; time: string[] }) {
  const combined = entry.time.map((timeValue, index) => ({
    time: timeValue,
    oldDate: entry.oldDate[index],
  }));

  combined.sort((a, b) => {
    const [aHour, aMinute] = a.time.split(':').map(Number);
    const [bHour, bMinute] = b.time.split(':').map(Number);
    return aHour === bHour ? aMinute - bMinute : aHour - bHour;
  });

  entry.time = combined.map((item) => item.time);
  entry.oldDate = combined.map((item) => item.oldDate);
}

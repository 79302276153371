import React from 'react';
import styled, { css } from 'styled-components';

import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../theme/value';
import tickSquare from '../../asset/svg/button/tick-square.svg';
import { FORM_VALUE_ENUM } from './constant';
import { timeOption } from '../../data/time/constant';
import { TextElem } from '../../common/text';
import { SelectMultiElem } from '../../common/select-multi';
import { DatePickerElem } from '../../common/date-picker';
import { FormikValues } from 'formik';
import { multiValueContainer } from '../../common/select/frame/multi-value';
import calendarWeekendActive from '../../asset/svg/button/calendar-weekend-active.svg';
import calendarWeekend from '../../asset/svg/button/calendar-weekend.svg';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  handleSuccess: () => void;
  onChangeSelect: Function;
  getFieldValue: Function;
  formik: FormikValues;
  isWeekend: boolean;
  setWeekend: () => void;
  isWeekendDisabled: boolean;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  handleSuccess,
  isSubmitDisabled,
  onChangeSelect,
  getFieldValue,
  formik,
  isWeekend,
  setWeekend,
  isWeekendDisabled,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SCHEDULE.WEEKEND.SUCCESS" />
      )}
      <Container>
        <TitleContainer>
          <Title
            type="medium"
            size="main"
            color="textPrimary"
            tid="SCHEDULE.WEEKEND.TITLE"
          />
          <Description
            type="light"
            size="small"
            color="textSecondary"
            tid="SCHEDULE.WEEKEND.DESCRIPTION"
          />
        </TitleContainer>

        <Card>
          <AddCardContainer>
            <DatePickerElem
              name={FORM_VALUE_ENUM.DATE}
              placeholder="SCHEDULE.CREATE.DATE_PLACEHOLDER"
              handleChange={(e) => {
                formik.setFieldValue(FORM_VALUE_ENUM.DATE, e);
              }}
            />
            {isWeekend && (
              <FakeInput>
                <TextElem
                  tid="SCHEDULE.WEEKEND_TITLE"
                  type="light"
                  color="textSecondary"
                  size="semiSmall"
                />
              </FakeInput>
            )}
            {!isWeekend && (
              <SelectMultiElem
                isMulti
                isClearable
                minHeight="48px"
                isDisabled={isLoading}
                dynamic
                options={timeOption}
                name={FORM_VALUE_ENUM.TIME}
                value={getFieldValue(FORM_VALUE_ENUM.TIME)}
                onChange={onChangeSelect}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                placeholder="SCHEDULE.CREATE.TIME_PLACEHOLDER"
                customComponents={{ MultiValueContainer: multiValueContainer }}
              />
            )}

            <CreateButton disabled={isSubmitDisabled()} onClick={handleSuccess}>
              <ImgStyled src={tickSquare} alt="calendar" />
            </CreateButton>

            <WeekendButton
              disabled={isWeekendDisabled}
              isWeekend={isWeekend}
              onClick={setWeekend}
            >
              <ImgStyled
                src={isWeekend ? calendarWeekendActive : calendarWeekend}
                alt="calendar"
              />
            </WeekendButton>
          </AddCardContainer>
        </Card>
      </Container>
    </>
  );
};

const FakeInput = styled.div`
  padding: 16px 20px;
  position: relative;
  height: 48px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
  width: 100%;

  overflow: hidden;

  display: flex;
  align-items: center;

  @media screen and (max-width: 1160px) {
    span {
      font-size: 13px;
    }
  }

  && > span {
    width: calc(100% - 40px);
    display: block;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    font-size: ${({ theme }) => theme[SIZE_FONT_ENUM.SEMI_SMALL]}px;

    @media screen and (max-width: 1160px) {
      font-size: 13px;
    }
  }
`;

const Title = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 15px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  margin-bottom: ${Spacing(3)};

  @media screen and (max-width: 1160px) {
    margin-bottom: ${Spacing(2)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
`;

const ImgStyled = styled.img`
  width: ${Spacing(5)};
  height: ${Spacing(5)};
`;

const CreateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
  padding: ${Spacing(1)} 14px;
  min-height: 48px;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :disabled {
    opacity: 0.7;
  }

  :hover:not(:disabled) {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const WeekendButton = styled(CreateButton)<{ isWeekend?: boolean }>`
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT_OPACITY]};

  ${({ isWeekend }) => {
    if (isWeekend) {
      return css`
        background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
      `;
    }
  }}
`;

const AddCardContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  grid-template-columns: 134px 1fr 48px 48px;

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;

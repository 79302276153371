import React, { useState } from 'react';
import styled from 'styled-components';
import fileIcon from '../../asset/svg/menu-app/file.svg';

import { Spacing } from '../../theme';
import { ModalElem } from '../modal';
import { COLOR_ENUM } from '../../theme/color';

import deleteIcon from '../../asset/svg/file/delete.svg';
import videoIcon from '../../asset/svg/video/video-icon.svg';
import imageLoadingIcon from '../../asset/svg/upload/image-loading.svg';

import { TextElem } from '../text';
import {
  allowedImageTypes,
  allowedVideoTypes,
} from '../../epic/file-item-icon-upload/constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Elem: React.FC<{
  file: { id: string; name: string; url: string; mimetype: string }[];
  action: (id: string) => void;
}> = ({ file, action }) => {
  const [openModalFile, setOpenModalFile] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const isImage = (mimetype: string) => allowedImageTypes.includes(mimetype);
  const isVideo = (mimetype: string) => allowedVideoTypes.includes(mimetype);
  const getFileIcon = (mimetype: string) => {
    if (isImage(mimetype)) {
      return imageLoadingIcon;
    }
    if (isVideo(mimetype)) {
      return videoIcon;
    }
    return fileIcon;
  };
  const handleFileClick = (fileItem: { url: string; mimetype: string }) => {
    if (isImage(fileItem.mimetype)) {
      setOpenModalFile(fileItem.url);
    } else if (isVideo(fileItem.mimetype)) {
      setVideoUrl(fileItem.url);

      // <VideoPlayerElem src={fileItem.url} autoPlay={true} muted={false} />;
    } else {
      const link = document.createElement('a');
      link.href = fileItem.url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const reversedFiles = [...file].reverse();

  return (
    <>
      {reversedFiles.map((fileItem) => (
        <FileContainer key={fileItem.id}>
          <FilePreview onClick={() => handleFileClick(fileItem)}>
            <FileIcon src={getFileIcon(fileItem.mimetype)} alt="file icon" />
            <TextElem
              oneLine
              type="medium"
              size="default"
              color="textPrimary"
              tid={fileItem.name}
            />
          </FilePreview>
          <DeleteIconStyled
            src={deleteIcon}
            alt="delete"
            onClick={() => action(fileItem.id)}
          />
        </FileContainer>
      ))}

      {openModalFile && (
        <ModalElem
          open={!!openModalFile}
          onClose={() => setOpenModalFile(null)}
        >
          <ImagePreview src={openModalFile} alt="Preview" />
        </ModalElem>
      )}

      {videoUrl && (
        <ModalElem open={!!videoUrl} onClose={() => setVideoUrl(null)}>
          <VideoStyled controls style={{ width: '100%', height: 'auto' }}>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </VideoStyled>
        </ModalElem>
      )}
    </>
  );
};

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  width: 100%;
  height: 100%;
  max-height: 480px;
  object-fit: cover;
  object-position: center;
`;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};
  border-radius: 8px;
  padding: ${Spacing(4)} ${Spacing(4)};
  gap: ${Spacing(2)};
  max-width: 200px;
  width: auto;
  min-width: 120px;
  height: 48px;
  position: relative;
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(1.5)};
  cursor: pointer;
  overflow: hidden;
`;

const FileIcon = styled.img`
  width: ${Spacing(4)};
  height: ${Spacing(4)};
`;

const DeleteIconStyled = styled.img`
  cursor: pointer;

  min-width: ${Spacing(3)};
`;

const ImagePreview = styled.img`
  max-width: max-content;
  max-height: 80vh;
  object-fit: contain;
  border-radius: ${Spacing(2)};
`;

import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME, SCHEDULE_RAW } from './constant';
import { Component } from './component';
import { getDateAction, removeAction, removeWeekendAction } from './action';
import { convert } from './convert';

export const Container: React.FC = () => {
  const [convertedData, setConvertedData] = useState<any>();

  const preFetch = useQuery(MODULE_NAME, getDateAction, {
    onSuccess: (data: SCHEDULE_RAW) => {
      const convertedData = convert(data.schedule, data.weekend);

      setConvertedData(convertedData);
    },
  });

  const onSuccess = () => {
    preFetch.refetch();
  };

  const deleteAction = useMutation(removeAction, { onSuccess });
  const deleteWeekendAction = useMutation(removeWeekendAction, { onSuccess });

  const handleDelete = (date: string[], isWeekend: boolean) => {
    if (!isWeekend) {
      deleteAction.mutate(date);
    } else {
      deleteWeekendAction.mutate(date);
    }
  };

  const isSubmitDisabled = () => {
    if (preFetch.isLoading) {
      return true;
    }

    if (deleteAction.isLoading) {
      return true;
    }

    if (deleteWeekendAction.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }

    if (deleteAction.isError && !deleteAction.isLoading && getErrorMessage()) {
      return true;
    }

    if (
      deleteWeekendAction.isError &&
      !deleteWeekendAction.isLoading &&
      getErrorMessage()
    ) {
      return true;
    }

    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    const deleteError: ACTION_ERROR_INTER =
      deleteAction.error as ACTION_ERROR_INTER;

    const deleteWeekendError: ACTION_ERROR_INTER =
      deleteWeekendAction.error as ACTION_ERROR_INTER;

    if (deleteError) {
      return deleteError.message;
    }

    if (deleteWeekendError) {
      return deleteWeekendError.message;
    }

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      handleDelete={handleDelete}
      data={convertedData}
      isLoading={
        preFetch.isLoading ||
        deleteAction.isLoading ||
        deleteWeekendAction.isLoading
      }
      isSuccess={deleteAction.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};

import { FormikValues } from 'formik';
import {
  PAYMENT_ITEM_LIST_DATA_RAW_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
} from '../../data/payment/constant';
import { i18n } from '../../lib/lang';
import { required } from '../../lib/validation/service';
import { validation } from '../../lib/validation';

export enum FORM_VALUE_ENUM {
  CANCELLATION_REASON = 'cancellationReason',
}

const config = {
  [FORM_VALUE_ENUM.CANCELLATION_REASON]: [required],
};

export const validate = (values: FormikValues) => validation(values, config);

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.CANCELLATION_REASON]: string;
}

export const MODULE_NAME = 'CONSULTATION_PAYMENT_LINK_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (id: string | number) => `/payment/link/liqpay/${id}`,
  },
  PATCH: {
    TYPE: 'PATCH',
    URL: (id: string | number) => `/consultation/update/${id}`,
  },
};

export interface ACTION_RESPONSE_INTER
  extends PAYMENT_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends PAYMENT_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const STEPPER_LIST = [
  { topic: i18n.t('PAYMENT.LIST.STATUS_NOT_SEEN') },
  { topic: i18n.t('PAYMENT.LIST.STATUS_BUTTON_NOT_PRESSED') },
  { topic: i18n.t('PAYMENT.LIST.STATUS_BUTTON_PRESSED') },
  { topic: i18n.t('PAYMENT.LIST.STATUS_SENT') },
  { topic: i18n.t('PAYMENT.LIST.STATUS_PAID') },
];

import { HttpRequest } from '../../lib/http';
import { API, filterOption } from './constant';

export const action = (filterOption?: filterOption) => {
  const url = new URL('https://example.com');

  console.log('filterOption', filterOption);
  if (filterOption?.caretakerCategory) {
    url.searchParams.append('category', String(filterOption.caretakerCategory));
  }
  if (filterOption?.age) {
    url.searchParams.append('age', String(filterOption.age));
  }
  if (filterOption?.gender) {
    url.searchParams.append('gender', String(filterOption.gender));
  }
  if (filterOption?.ableCompatibilityAnimal) {
    url.searchParams.append(
      'ableCompatibilityAnimal',
      filterOption.ableCompatibilityAnimal,
    );
  }
  if (filterOption?.medicationExperience) {
    url.searchParams.append(
      'medicationExperience',
      filterOption.medicationExperience,
    );
  }
  if (filterOption?.placement) {
    url.searchParams.append('placement', filterOption.placement);
  }
  if (filterOption?.workAnimalType) {
    url.searchParams.append('workAnimalType', filterOption.workAnimalType);
  }
  if (filterOption?.specialistWith) {
    url.searchParams.append('specialistWith', filterOption.specialistWith);
  }
  if (filterOption?.city) {
    url.searchParams.append('city', filterOption.city);
  }
  if (filterOption?.price) {
    url.searchParams.append('price', String(filterOption.price));
  }
  if (filterOption?.time) {
    url.searchParams.append('time', filterOption.time);
  }
  if (filterOption?.date) {
    url.searchParams.append('date', String(filterOption.date));
  }
  if (filterOption?.priceOrder) {
    url.searchParams.append(
      'priceOrder',
      String(filterOption.priceOrder.value),
    );
  }

  if (filterOption?.popularityOrder) {
    url.searchParams.append(
      'popularityOrder',
      String(filterOption.popularityOrder.value),
    );
  }

  if (filterOption?.search) {
    url.searchParams.append('search', String(filterOption.search));
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(url.search),
  });
};

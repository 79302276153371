import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';

import { Component } from './component';
import {
  ACTION_ERROR_INTER,
  CATEGORY_RAW_INTER,
} from '../profile-psycho/constant';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';
import { STORE_INTER } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { action } from './action';

export const Container: React.FC<{
  category?: CATEGORY_RAW_INTER;
  psychoId: string;
}> = ({ category, psychoId }) => {
  const [isErrorCategory, setIsErrorCategory] = useState(false);

  const history = useHistory();
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isAuth = state ? state.logged && state?.isConfirmEmail : null;

  const onSuccess = (data: any) => {
    history.push(CONSULTATION_PAGE_PATH_DYNAMIC(data.id));
  };

  const patchAction = useMutation(action, { onSuccess });


  const handleClick = (date?: string) => {
    if (category) {
      const payload = {
        psychologist: psychoId,
        category: category.category,
        time: '2025-04-01T05:00:00.000Z',
        duration: category.duration,
        price: category.price,
        isUnlogged: !isAuth,
      };

      return patchAction.mutate({ payload, isAuth: !!isAuth });
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = patchAction.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      errorMessage={getErrorMessage()}
      isError={patchAction.isError}
      isErrorCategory={isErrorCategory}
      handleClick={handleClick}
    />
  );
};

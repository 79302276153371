import ReactMarkdown from 'react-markdown';
import React, { ReactNode } from 'react';

import styles from './markdown.module.css';
import { i18n } from '../../lib/lang';

const MARKDOWN_CLASS = 'markdown_theme';

interface MarkdownProps {
  tid: string;
}

export const Markdown: React.FC<MarkdownProps> = ({ tid }) => {
  const components = {
    a: ({ href, children }: { href?: string; children: ReactNode }) => (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  };

  return (
    <div className={styles.mk_d}>
      <ReactMarkdown components={components} className={MARKDOWN_CLASS}>
        {i18n.t(tid)}
      </ReactMarkdown>
    </div>
  );
};

import { CATEGORY_ENUM } from '../../epic/profile-psycho-create-category/constant';
import { i18n } from '../../lib/lang';
import { ANIMAL_TYPE_ENUM } from '../animal/constant';

export enum REQUEST_ENUM {
  ANXIETY = 1,
  STRESS = 2,
  FAMILY_CONFLICT = 3,
}

export enum METHOD_ENUM {
  COGNITIVE_BEHAVIORAL_THERAPY = 1,
  PSYCHOANALYSIS = 2,
  GESTALTTHERAPY = 3,
}

export enum GENDER_ENUM {
  MALE = 1,
  FEMALE = 2,
}

export enum LGBT_FRIENDLY_ENUM {
  YES = 1,
  FEMALE = 2,
}
export enum SPECIALIZATION_ENUM {
  WALK = 'walk',
  CARE = 'care',
  TREATMENT = 'treatment',
}

export enum PLACEMENT_ENUM {
  OWNER = 'owner',
  CARETAKER = 'caretakeer',
}

export enum TRANSPORTATION_ENUM {
  WALK = 'walk',
  BIKE = 'bike',
  CAR = 'car',
}
export enum TRANSPORT_ENUM {
  WALK = 'walk',
  BIKE = 'bike',
  CAR = 'car',
}

export enum ANIMAL_SIZE_ENUM {
  MIDGET = 'midget', // карлик
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}
export enum SPECIALIST_ENUM {
  ANGRY = 'angry',
  BLIND = 'blind',
  DEAF = 'deaf',
}

export enum LANGUAGE_ENUM {
  UA = 'UA',
  ENG = 'ENG',
  POL = 'POL',
}

export enum VACCINATION_ENUM {
  YES = 'yes',
  NO = 'no',
}
export enum YES_NO_ENUM {
  TRUE = 'true',
  FALSE = 'false',
}
export const PLACEMENT_VIEW = {
  [PLACEMENT_ENUM.OWNER]: i18n.t('USER.USER.SELECT.PLACEMENT.OWNER'),
  [PLACEMENT_ENUM.CARETAKER]: i18n.t('USER.USER.SELECT.PLACEMENT.CARETAKER'),
};

export const YES_NO_VIEW = {
  [YES_NO_ENUM.FALSE]: i18n.t('USER.USER.SELECT.NO'),
  [YES_NO_ENUM.TRUE]: i18n.t('USER.USER.SELECT.YES'),
};

export const TRANSPORTATION_VIEW = {
  [TRANSPORTATION_ENUM.BIKE]: i18n.t('USER.USER.SELECT.TRANSPORTATION.BIKE'),
  [TRANSPORTATION_ENUM.WALK]: i18n.t('USER.USER.SELECT.TRANSPORTATION.WALK'),
  [TRANSPORTATION_ENUM.CAR]: i18n.t('USER.USER.SELECT.TRANSPORTATION.CAR'),
};

export const ANIMAL_TYPE_VIEW: { [key: string]: string } = {
  [ANIMAL_TYPE_ENUM.CAT]: i18n.t('USER.USER.SELECT.ANIMAL_TYPE.CAT'),
  [ANIMAL_TYPE_ENUM.DOG]: i18n.t('USER.USER.SELECT.ANIMAL_TYPE.DOG'),
};

export const ANIMAL_SIZE_VIEW = {
  [ANIMAL_SIZE_ENUM.MIDGET]: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.MIDGET'),
  [ANIMAL_SIZE_ENUM.SMALL]: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.SMALL'),
  [ANIMAL_SIZE_ENUM.MEDIUM]: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.MEDIUM'),
  [ANIMAL_SIZE_ENUM.BIG]: i18n.t('USER.USER.SELECT.ANIMAL_SIZE.BIG'),
};
export const SPECIALIST_VIEW: { [key: string]: string } = {
  [SPECIALIST_ENUM.ANGRY]: i18n.t('USER.USER.SELECT.SPECIALIST.ANGRY'),
  [SPECIALIST_ENUM.BLIND]: i18n.t('USER.USER.SELECT.SPECIALIST.BLIND'),
  [SPECIALIST_ENUM.DEAF]: i18n.t('USER.USER.SELECT.SPECIALIST.DEAF'),
};

export const LANGUAGE_VIEW = {
  [LANGUAGE_ENUM.UA]: i18n.t('USER.USER.SELECT.LANGUAGE.UA'),
  [LANGUAGE_ENUM.ENG]: i18n.t('USER.USER.SELECT.LANGUAGE.ENG'),
  [LANGUAGE_ENUM.POL]: i18n.t('USER.USER.SELECT.LANGUAGE.POL'),
};

export const VACCINATION_VIEW = {
  [VACCINATION_ENUM.YES]: i18n.t('USER.USER.SELECT.YES'),
  [VACCINATION_ENUM.NO]: i18n.t('USER.USER.SELECT.NO'),
};

export const methodViewObj = {
  [METHOD_ENUM.COGNITIVE_BEHAVIORAL_THERAPY]: 'Когнітивно-поведінкова терапія',
  [METHOD_ENUM.GESTALTTHERAPY]: 'Гештальт-терапія ',
  [METHOD_ENUM.PSYCHOANALYSIS]: 'Психоаналіз',
};

export const requestViewObj = {
  [REQUEST_ENUM.ANXIETY]: 'Тривога',
  [REQUEST_ENUM.STRESS]: 'Стрес ',
  [REQUEST_ENUM.FAMILY_CONFLICT]: 'Сімейний конфлікт',
};

export const genderViewObj = {
  [GENDER_ENUM.MALE]: {
    value: 1,
    label: 'Чоловіча',
  },
  [GENDER_ENUM.FEMALE]: {
    value: 2,
    label: 'Жіноча',
  },
};

export interface PSYCHOLOGIST_ITEM_DATA_RAW_INTER {
  id: string;
  name: string;
  phone?: string | null;
  about?: string | null;
  age?: number | null;
  gender?: GENDER_ENUM;
  timezone?: number;
  city?: string;
  country?: string;
  language?: LANGUAGE_ENUM[];
  email: string;
  role: USER_ROLE;
  photo?: {
    url: string;
    id: string;
    name: string;
  };
  // url?: string;

  //CONTACT INFO
  isFop?: boolean;
  bankNumber?: number;
  bankName?: string;
  // SPECIALIZE
  workAnimalType?: ANIMAL_TYPE_ENUM[];
  specialistWith?: SPECIALIST_ENUM[];
  transportation?: TRANSPORTATION_ENUM[];
  placement?: PLACEMENT_ENUM[];
  ableCompatibilityAnimal?: boolean;
  specialization?: SPECIALIST_ENUM[];
  // ДОСВІД
  yearExperience?: number;
  profileDescription?: string;
  aboutPerson?: string;
  myPath?: string;
  workingMethods?: string;
  medicationExperience?: string;
  // ПОСЛУГИ
  price?: number;
  requireHours?: number;
  overNightAvailability?: boolean;
  fullDayAvailability?: boolean;
  description?: string;
}

export interface PSYCHOLOGIST_ITEM_DATA_INTER
  extends Omit<
    PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
    | 'methodList'
    | 'requestList'
    | 'gender'
    | 'lgbtFrendly'
    | 'language'
    | 'isFop'
    | 'ableCompatibilityAnimal'
    | 'workAnimalType'
    | 'specialistWith'
    | 'transportation'
    | 'placement'
  > {
  methodList?: OPTION_INTER[] | null;
  requestList?: OPTION_INTER[] | null;
  lgbtFrendly?: string | null;

  gender?: OPTION_INTER;
  language?: OPTION_INTER[];

  isFop?: string | null;
  workAnimalType?: OPTION_INTER[];
  specialistWith?: OPTION_INTER[];
  transportation?: OPTION_INTER[];
  placement?: OPTION_INTER[];
  ableCompatibilityAnimal?: string | null;
}

export interface USER_ITEM_DATA_RAW_INTER {
  id: string;
  createDate: string;
  name: string;
  phone?: string | null;
  about?: string | null;
  age?: number | null;
  gender?: GENDER_ENUM;
  timezone?: number;
  city?: string;
  country?: string;
  language?: LANGUAGE_ENUM[];
  email: string;
  role: USER_ROLE;
  password: string;
  photo?: {
    url: string;
    id: string;
    name: string;
  };
  addressInfo?: string;
  numberTimezone?: number;
}

export interface USER_ITEM_LIST_DATA_RAW_INTER {
  list: USER_ITEM_DATA_RAW_INTER[];
}

export interface OPTION_INTER {
  value?: number | any | string;
  label?: string;
}

export interface USER_ITEM_DATA_INTER
  extends Omit<USER_ITEM_DATA_RAW_INTER, 'gender' | 'language'> {
  lgbtFrendly?: string | null;
  psychoCategoryView?: OPTION_INTER;

  gender?: OPTION_INTER;
  language?: OPTION_INTER[];
}

export interface USER_FILE_ITEM_DATA_RAW {
  photo?: {
    url: string;
    id: string;
    name: string;
  };
  animalPhoto?: {
    url: string;
    id: string;
    name: string;
  };
  animalPassportFile?: {
    url: string;
    id: string;
    name: string;
  };
  videoIntroduction?: {
    url: string;
    id: string;
    name: string;
  };
}

export interface USER_ITEM_LIST_DATA_INTER {
  list: USER_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: '/user/data',
  },
};

export enum USER_ROLE {
  CARETAKER = 'CARETAKER',
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export const CHIP_USER_VIEW = {
  [USER_ROLE.ADMIN]: 'rgba(32, 113, 187, 0.1)',
  [USER_ROLE.CARETAKER]: 'rgba(49, 173, 76, 0.1)',
  [USER_ROLE.USER]: 'rgba(170, 62, 255, 0.15)',
};

export const CHIP_TEXT_USER_VIEW = {
  [USER_ROLE.ADMIN]: '#2071BB',
  [USER_ROLE.CARETAKER]: '#31AD4C',
  [USER_ROLE.USER]: '#AA3EFF',
};

export const USER_ROLE_OPTION_LIST = [
  {
    value: USER_ROLE.CARETAKER,
    label: `${i18n.t('USER.ROLE.CARETAKER')}`,
  },

  {
    value: USER_ROLE.USER,
    label: `${i18n.t('USER.ROLE.USER')}`,
  },

  {
    value: USER_ROLE.ADMIN,
    label: `${i18n.t('USER.ROLE.ADMIN')}`,
  },
];

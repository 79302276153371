import { useState } from 'react';
import styled from 'styled-components';

import { ModalElem } from '../../../common/modal';
import { FlexElem } from '../../../common/flex';
import { TextElem } from '../../../common/text';
import { ReadMoreElem } from '../../../common/read-more';

export const ReadMoreModal: React.FC<{
  aboutMyPath?: string | null;
  aboutMe?: string | null;
  aboutMyService?: string | null;
}> = ({ aboutMyPath, aboutMe, aboutMyService }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ReadMoreElem
        onClick={() => setIsOpen(true)}
        color="textSecondary"
        lineHeight="1.6em"
        textType="light"
        textLimit={160}
        text={aboutMe || aboutMyPath || aboutMyService || ''}
      />
      <ModalElem
        titleTid="CARETAKER_LIST.CARD.MODAL_TITLE"
        isCloseButton
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <FlexElem flexDirection="column" spacing={6}>
          {aboutMe && (
            <FlexElem flexDirection="column" spacing={3}>
              <TextElem
                color="textPrimary"
                size="default"
                tid="CARETAKER_LIST.CARD.PATH_TITLE"
              />
              <TextElemStyled
                type="light"
                color="textSecondary"
                size="input"
                tid={aboutMe || ''}
              />
            </FlexElem>
          )}
          {aboutMyPath && (
            <FlexElem flexDirection="column" spacing={3}>
              <TextElem
                color="textPrimary"
                size="default"
                tid="CARETAKER_LIST.CARD.ME_TITLE"
              />
              <TextElemStyled
                type="light"
                color="textSecondary"
                size="input"
                tid={aboutMyPath || ''}
              />
            </FlexElem>
          )}

          {aboutMyService && (
            <FlexElem flexDirection="column" spacing={3}>
              <TextElem
                color="textPrimary"
                size="default"
                tid="CARETAKER_LIST.CARD.ME_SERVICE_TITLE"
              />
              <TextElemStyled
                type="light"
                color="textSecondary"
                size="input"
                tid={aboutMyService || ''}
              />
            </FlexElem>
          )}
        </FlexElem>
      </ModalElem>
    </>
  );
};

const TextElemStyled = styled(TextElem)`
  white-space: pre-wrap;
`;

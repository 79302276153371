import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({
  payload,
  isAuth,
}: {
  payload: any;
  isAuth: boolean;
}) => {
  return HttpRequest({
    method: API.POST.TYPE,
    url: API.POST.URL(isAuth),
    data: payload,
  });
};

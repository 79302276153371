import React from 'react';
import styled, { css } from 'styled-components';

import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { GridElem } from '../../common/grid';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { i18n } from '../../lib/lang';
import { LANDING_ID_ENUM, LANDING_ID_PROPS } from '../home-header/constant';
import useIntersectionObserver from './hook/useIntersectionObserver';
import { LINK } from './constant';
import { Link } from 'react-router-dom';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../theme/value';
import { FlexElem } from '../../common/flex';

export const Component: React.FC<LANDING_ID_PROPS> = ({ handleNavClick }) => {
  const activeSection = useIntersectionObserver();
  return (
    <PaddingContainer>
      <FooterContainer>
        <LogoContainer>
          <LogoImg src="./assets/svg/header/pets.svg" alt="pets Logo" />
          <FlexElem justifyContent="end" spacing={3}>
            <ASosial target="_blank" href={i18n.t(`LANDING.SOCIALS.TELEGRAM`)}>
              <img
                src="./assets/svg/telegram_logo_black.svg"
                alt="telegram_logo"
              />
            </ASosial>
            <ASosial target="_blank" href={i18n.t(`LANDING.SOCIALS.FACEBOOK`)}>
              <img
                src="./assets/svg/facebook_logo_black.svg"
                alt="facebook_logo"
              />
            </ASosial>
            <ASosial target="_blank" href={i18n.t(`LANDING.SOCIALS.INSTAGRAM`)}>
              <img
                src="./assets/svg/instagram_logo_black.svg"
                alt="instagram_logo"
              />
            </ASosial>
            <ASosial target="_blank" href={i18n.t(`LANDING.SOCIALS.YOUTUBE`)}>
              <IconStyled
                src="./assets/svg/youtube_logo_black.svg"
                alt="instagram_logo"
              />
            </ASosial>
            <ASosial target="_blank" href={i18n.t(`LANDING.SOCIALS.TIKTOK`)}>
              <IconStyled
                src="./assets/svg/tiktok_logo_black.svg"
                alt="instagram_logo"
              />
            </ASosial>
          </FlexElem>
        </LogoContainer>
        <HomeHeader>
          <GridContainer>
            <FlexContainer>
              <TextElem
                size="main"
                color="textPrimary"
                type="medium"
                tid="LANDING.HOME_FOOTER.TITLE.SERVICE"
              />

              {Object.values(LANDING_ID_ENUM).map((section) => (
                <NavLink
                  key={section}
                  href={`#${section}`}
                  onClick={handleNavClick(section)}
                  isActive={activeSection === section}
                >
                  <TextLink
                    color="textSecondary"
                    size="semiSmall"
                    tid={i18n.t(`LANDING.HOME_FOOTER.NAV_BAR.${section}`)}
                  />
                </NavLink>
              ))}
            </FlexContainer>
          </GridContainer>
          <FlexContainer>
            <TextElem
              size="main"
              color="textPrimary"
              type="medium"
              tid="LANDING.HOME_FOOTER.TITLE.POLICY"
            />
            {LINK.map((link) => {
              const hrefText = i18n.t(link.href);

              return (
                <LinkServices
                  key={link.tid}
                  target="_blank"
                  href={hrefText}
                  to={hrefText}
                >
                  <TextLink
                    oneLine
                    color="textSecondary"
                    size="semiSmall"
                    tid={link.tid}
                  />
                </LinkServices>
              );
            })}
          </FlexContainer>
          <ButtonsContainer>
            <FlexContainer>
              <TextElem
                size="main"
                color="textPrimary"
                type="medium"
                tid="LANDING.HOME_FOOTER.TITLE.CONTACT"
              />
              {/* <ContactContainer> */}
              <AMail href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL.MAIN')}`}>
                <TextElemMail
                  color="textSecondary"
                  size="semiSmall"
                  tid="LANDING.HOME_FOOTER.MAIL.MAIN"
                />
              </AMail>
              <AMail href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL.INFO')}`}>
                <TextElemMail
                  color="textSecondary"
                  size="semiSmall"
                  tid="LANDING.HOME_FOOTER.MAIL.INFO"
                />
              </AMail>

              {/* <Dot /> */}
              <AMail
                href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL.PARTNER')}`}
              >
                <TextElemMail
                  color="textSecondary"
                  size="semiSmall"
                  tid="LANDING.HOME_FOOTER.MAIL.PARTNER"
                />
              </AMail>
              <AMail
                href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL.SERVICE')}`}
              >
                <TextElemMail
                  color="textSecondary"
                  size="semiSmall"
                  tid="LANDING.HOME_FOOTER.MAIL.SERVICE"
                />
              </AMail>
              <PhoneNumberText href="tel:+380980119429">
                <TextElem
                  color="textSecondary"
                  size="semiSmall"
                  tid="LANDING.HOME_FOOTER.NUMBER"
                />
              </PhoneNumberText>
              {/* </ContactContainer> */}
            </FlexContainer>
          </ButtonsContainer>
          <LinkStyled to="/auth/signup">
            <LogRegButton type="register">
              {i18n.t('LANDING.HOME_HEADER.BUTTONS.REGISTER')}
            </LogRegButton>
          </LinkStyled>
        </HomeHeader>
      </FooterContainer>
    </PaddingContainer>
  );
};

const ASosial = styled.a`
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const IconStyled = styled.img`
  width: ${Spacing(6)};
  height: ${Spacing(6)};
  min-width: ${Spacing(6)};
  min-height: ${Spacing(6)};
`;

const AMail = styled.a`
  line-height: 1em;
`;

const TextElemMail = styled(TextElem)`
  text-decoration: underline;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
  }
`;
const PhoneNumberText = styled.a`
  span {
    // text-decoration: underline;
    transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};

    &:hover {
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
    }
  }
`;

const LinkServices = styled(Link)`
  text-decoration: none;
`;

export const PaddingContainer = styled.div`
  padding: ${Spacing(3)};
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  max-width: ${Spacing(350)};
  margin: 0 auto;
  display: grid;
  gap: ${Spacing(5)};
  display: flex;
`;

const LinkStyled = styled(Link)`
  width: max-content;

  @media (max-width: 730px) {
    width: 30%;
    margin: 0 auto;
    min-width: 150px;
  }

  @media (max-width: 430px) {
    margin: 0;
  }
`;

const LogRegButton = styled(ButtonElem)`
  background-color: ${COLOR_DATA[COLOR_ENUM.DEFAULT]};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
`;

const TextLink = styled(TextElem)`
  display: flex;
  white-space: nowrap;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.DEFAULT]};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};

  &:hover {
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  }

  @media (max-width: 768px) {
    white-space: nowrap;
    font-size: 13px;
  }

  @media (max-width: 480px) {
    white-space: nowrap;
    font-size: 13px;
  }
`;

const LogoImg = styled.img`
  min-width: 260px;
  width: 260px;
  height: auto;
`;

const GridContainer = styled(GridElem)`
  font-family: 'Raleway';
  display: grid;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: ${Spacing(5)};
  flex-direction: column;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${Spacing(5)};
  }

  * > * > * > {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]};
  }
`;

const LinkContainer = styled.a`
  display: flex;
  gap: ${Spacing(1)};
  text-decoration: none;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]};

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: ${Spacing(1)};
    justify-content: space-around;
    align-items: center;
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: ${Spacing(1)};
    padding: ${Spacing(3)} 0;
    width: 100%;
    align-items: center;
  }
`;

const NavBarFooter = styled.div`
  display: flex;
  height: ${Spacing(9)};
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    text-align: center;
    padding: 0 ${Spacing(2)};
    height: fit-content;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 28px;
  align-items: center;

  @media (max-width: 768px) {
    display: grid;
    gap: 10px;
    justify-content: space-around;
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 10px;
    padding: ${Spacing(3)} 0;
    width: 100%;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${Spacing(4)};
  width: 100%;

  @media (max-width: 768px) {
    gap: ${Spacing(2)};
  }
`;

const HomeHeader = styled.div`
  padding: ${Spacing(8)} 120px;
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: ${Spacing(350)};
  margin: 0 auto;
  display: flex;
  gap: ${Spacing(8)};
  justify-content: space-around;
  align-items: start;

  :hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    height: fit-content;
    display: grid;
    justify-content: space-around;
    padding: ${Spacing(8)} 242px;
  }

  @media (max-width: 480px) {
    display: flex;
    padding: 32px 120px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${Spacing(4)};
  padding: ${Spacing(10)} ${Spacing(15)};
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  max-width: ${Spacing(350)};
  margin: 0 auto;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;

  @media (max-width: 768px) {
    padding: ${Spacing(5)};
  }

  @media (max-width: 480px) {
    display: contents;
    padding: ${Spacing(2.5)};
  }
`;

const activeStyles = css`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
`;

const NavLink = styled.a<{ isActive: boolean }>`
  text-decoration: none;
  display: inline-block;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  }

  ${({ isActive }) => isActive && activeStyles};
`;

export const MODULE_NAME = 'KNOWLEDGE_LANDING_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const SUBTITLE_LIST = [
  'LANDING.HOME_ABOUT.SUBTITLE.0',
  'LANDING.HOME_ABOUT.SUBTITLE.1',
  'LANDING.HOME_ABOUT.SUBTITLE.2',
  'LANDING.HOME_ABOUT.SUBTITLE.3',
  'LANDING.HOME_ABOUT.SUBTITLE.4',
  'LANDING.HOME_ABOUT.SUBTITLE.5',
  'LANDING.HOME_ABOUT.SUBTITLE.6',
];

export enum LANDING_QUESTION_ID_ENUM {
  SELF_ESTEEM = 'SELF_ESTEEM',
  HELPING = 'HELPING',
  OFFER = 'OFFER',
  REMARK = 'REMARK',
  HELP = 'HELP',
}

export type LANDING_QUESTION_ID_TYPE = `${LANDING_QUESTION_ID_ENUM}`;

export interface LANDING_QUESTION_ID_PROPS {
  handleNavClick: (
    id: LANDING_QUESTION_ID_TYPE,
  ) => (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

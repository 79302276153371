import { useQuery } from 'react-query';
import { actionDay } from '../../data/time/action';
import { useState } from 'react';

interface USE_FETCH_DAY_PROPS {
  psychologistId: string;
  isShouldRefetch?: boolean;
  duration?: number;
}

export const DAY_MODULE_NAME = 'DAY_MODULE_NAME';

export const useFetchDay = ({
  psychologistId,
  isShouldRefetch,
  duration,
}: USE_FETCH_DAY_PROPS) => {
  const [dayOption, setDayOption] = useState<string[]>([]);

  const fetchDay = useQuery(
    [DAY_MODULE_NAME, isShouldRefetch, psychologistId],
    () => {
      return actionDay({
        psychologistId,
        duration,
      });
    },
    {
      onSuccess: (data: string[]) => {
        setDayOption(data);
      },
    },
  );

  return {
    dayArr: dayOption || undefined,
    isLoading: fetchDay.isLoading,
    isError: fetchDay.isError,
    isSuccess: fetchDay.isSuccess,
    refetch: fetchDay.refetch,
    status: fetchDay.status,
  };
};

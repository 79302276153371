import styled from 'styled-components';
import React from 'react';

import { Spacing } from '../../../theme/service';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text/index';
import { CONSULTATION_ITEM_DATA_INTER } from '../constant';
import { useHistory } from 'react-router';
import { ChipElem } from '../../../common/chip/index';
import { GridElem } from '../../../common/grid/index';
import {
  CHIP_STATUS_CONSULTATION_COLOR_BG,
  CHIP_STATUS_CONSULTATION_COLOR_TEXT,
  CHIP_STATUS_PAYMENT_COLOR_BG,
  CHIP_STATUS_PAYMENT_COLOR_TEXT,
} from '../../consultation-list/constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../../theme/size';
import { CONSULTATION_TYPE } from '../../../data/consultation/constant';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../../page/consultation';

export const Card: React.FC<CONSULTATION_ITEM_DATA_INTER> = ({
  id,
  createDate,
  price,
  user,
  psychologist,
  category,
  date,
  time,
  paymentStatus,
  duration,
  createDateView,
  createTimeView,
  typeView,
  type,
  consultationCount,
  status,
  statusView,
}) => {
  const href = CONSULTATION_PAGE_PATH_DYNAMIC(id);

  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  console.log(
    'CHIP_STATUS_PAYMENT_COLOR_TEXT',
    CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus],
  );

  return (
    <>
      <CardMobile href={href} onClick={onClick}>
        <Row>
          <Column>
            <TextElemStyled
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="CONSULTATION.LIST.CREATE_DATE"
            />

            <TextElemStyled
              size="input"
              tid="CONSULTATION.LIST.DATE_VALUE"
              tvalue={{ time: createTimeView, date: createDateView }}
              oneLine
              color="textPrimary"
            />
          </Column>
          <Column>
            <TextElemStyled
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="CONSULTATION.LIST.DATE_TITLE"
            />
            <TextElemStyled
              size="input"
              tid="CONSULTATION.LIST.DATE_VALUE"
              tvalue={{ time, date }}
              oneLine
              color="textPrimary"
            />
          </Column>
        </Row>
        <Divider />
        <Row>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.PRICE_TITLE"
            />
            <RowFlex>
              <TextElem
                size="input"
                tid="CONSULTATION.PRICE_TVALUE"
                tvalue={{ price }}
                oneLine
                color="default"
              />
              <TextElem
                oneLine
                size="semiSmall"
                color="textPrimary"
                tid="CONSULTATION.DURATION_TVALUE"
                tvalue={{ value: duration }}
              />
            </RowFlex>
          </Column>
          <FlexColumn>
            <TextElem
              className="paymentStatus"
              size="semiSmall"
              type="medium"
              tid={`CONSULTATION.PAYMENT.${paymentStatus}`}
              color={CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus]}
            />
            <TextElem
              className="paymentStatus"
              size="semiSmall"
              type="medium"
              tid={statusView}
              color={CHIP_STATUS_CONSULTATION_COLOR_TEXT[status]}
            />
          </FlexColumn>
        </Row>
        <Divider />
        <Row>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              tid="PAYMENT.ITEM.USER"
            />
            {/* <TextElem
              size="input"
              tid={
                user
                  ? `${user?.name ? user?.name : 'Без імені'} ${
                      user?.lastName ? user?.lastName : ''
                    }`
                  : 'Користувач не вказанний'
              }
              type="medium"
              color="textPrimary"
            /> */}
            {user && (
              <TextName
                className="userEmail"
                size="input"
                color="textSecondary"
                tid={user?.email}
              />
            )}
          </Column>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              tid="PAYMENT.ITEM.PSYCHOLOGIST"
            />

            {/* <TextElem
              size="input"
              tid={
                psychologist
                  ? `${psychologist?.name ? psychologist?.name : 'Без імені'} ${
                      psychologist?.lastName ? psychologist?.lastName : ''
                    }`
                  : 'Користувач не вказанний'
              }
              type="medium"
              color="textPrimary"
            /> */}
            {psychologist && (
              <TextName
                className="userEmail"
                size="input"
                color="textSecondary"
                tid={psychologist?.email}
              />
            )}
          </Column>
        </Row>
      </CardMobile>
      <CardDesktop key={id} href={href} onClick={onClick}>
        <div>
          <GridDate>
            <TextElemStyled
              oneLine
              size="semiSmall"
              color="textPrimary"
              tid="CONSULTATION.CREATE_DATE"
            />
            <GridDateColum>
              <TextElemStyled
                oneLine
                className="item"
                size="semiSmall"
                color="textPrimary"
                tid={createDateView}
              />
              <TextElemStyled
                oneLine
                className="item"
                size="semiSmall"
                color="textPrimary"
                tvalue={{ date: createTimeView }}
                tid="LANG.DATA.DATE_TVALUE"
              />
            </GridDateColum>
            <TextElemStyled
              oneLine
              size="semiSmall"
              color="textPrimary"
              tid="CONSULTATION.SESSION"
            />
            <GridDateColum>
              <TextElemStyled
                oneLine
                className="item"
                size="semiSmall"
                color="textPrimary"
                tid={date}
              />
              <TextElemStyled
                oneLine
                className="item"
                size="semiSmall"
                color="textPrimary"
                tid="LANG.DATA.DATE_TVALUE"
                tvalue={{ date: time }}
              />
            </GridDateColum>
          </GridDate>
        </div>

        <FlexColumn>
          <TextElem
            oneLine
            className="item"
            size="semiSmall"
            color="textPrimary"
            tid={`${category}`}
          />
          <TypeRow>
            <TextElem
              size="semiSmall"
              color="default"
              type="medium"
              tid="CONSULTATION.PRICE_TVALUE"
              tvalue={{ price }}
              oneLine
            />

            <TypeRow>
              <TextElem
                size="semiSmall"
                color="default"
                tid={typeView}
                oneLine
              />
              {type === CONSULTATION_TYPE.PACK && (
                <TextElem
                  size="semiSmall"
                  color="textSecondary"
                  tid="CONSULTATION.COUNT_TVALUE"
                  tvalue={{ value: consultationCount }}
                  oneLine
                />
              )}
            </TypeRow>
          </TypeRow>
        </FlexColumn>

        <FlexColumn>
          {/* <TextElem
            oneLine
            className="userEmail"
            size="semiSmall"
            color="textPrimary"
            tid={
              user
                ? `${user?.name ? user?.name : 'Без імені'} ${
                    user?.lastName ? user?.lastName : ''
                  }`
                : 'Користувач не вказанний'
            }
          /> */}
          {user && (
            <TextElem
              oneLine
              className="userEmail"
              size="semiSmall"
              color="textSecondary"
              tid={user?.email}
            />
          )}
        </FlexColumn>

        <FlexColumn>
          {/* <TextElem
            oneLine
            className="psychoEmail"
            size="semiSmall"
            color="textPrimary"
            tid={
              psychologist
                ? `${psychologist?.name ? psychologist?.name : 'Без імені'} ${
                    psychologist?.lastName ? psychologist?.lastName : ''
                  }`
                : 'Користувач не вказанний'
            }
          /> */}
          <TextElem
            oneLine
            className="psychoEmail"
            size="semiSmall"
            color="textSecondary"
            tid={psychologist?.email}
          />
        </FlexColumn>

        <FlexColumn>
          <TextElem
            className="paymentStatus"
            size="semiSmall"
            type="medium"
            tid={`CONSULTATION.PAYMENT.${paymentStatus}`}
            color={CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus]}
          />
          <TextElem
            className="paymentStatus"
            size="semiSmall"
            type="medium"
            tid={statusView}
            color={CHIP_STATUS_CONSULTATION_COLOR_TEXT[status]}
          />
        </FlexColumn>
      </CardDesktop>
    </>
  );
};

const TypeRow = styled.div`
  display: flex;
  gap: ${Spacing(2)};
`;

const TextElemStyled = styled(TextElem)`
  overflow: visible !important;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  justify-content: center;
`;

const GridDate = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(1)};
  row-gap: ${Spacing(3)};
`;

const GridDateColum = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(1)};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(216, 213, 199, 0.75);
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
  flex-wrap: wrap;
`;

const TextName = styled(TextElem)`
  @media screen and (max-width: 485px) {
    max-width: 160px;
  }
  @media screen and (max-width: 385px) {
    max-width: 130px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const RowFlex = styled.div`
  display: flex;
  width: 100%;
  gap: ${Spacing(2)};
`;

const CardMobile = styled.a`
  text-decoration: none;
  display: none;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: ${Spacing(4)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;

  @media screen and (max-width: 1374px) {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`;

const CardDesktop = styled.a`
  width: 100%;
  display: grid;

  grid-column: span 5;
  grid-template-columns: subgrid;

  justify-content: start;
  position: relative;
  align-items: center;
  padding: ${Spacing(5)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: 14px;
  text-decoration: none;

  @media screen and (max-width: 1374px) {
    display: none;
  }
`;

import React from 'react';
import styled, { css } from 'styled-components';

import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import closeIcon from '../../asset/svg/common/close.svg';

import { PROPS_TYPE } from './constant';
import { TextElem } from '../text';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../theme/value';

export const Elem: React.FC<PROPS_TYPE> = ({
  open,
  onClose,
  children,
  closeAction,
  className,
  titleTid,
  icon,
}) => {
  const onCloseAction = () => {
    onClose();
    closeAction && closeAction();
  };

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onCloseAction();
    }
  };

  return (
    <ModalWrapper open={open} onClick={handleOverlayClick}>
      <ModalContent className={className}>
        {titleTid && (
          <FilterHeader>
            <TitleContainer>
              {icon && <img src={icon} alt="modal image" />}
              <TextElem
                type="medium"
                size="label"
                color="textPrimary"
                tid={titleTid}
              />
            </TitleContainer>
            <CloseSvg
              onClick={onCloseAction}
              src={closeIcon}
              alt="close icon"
            />
          </FilterHeader>
        )}
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

const CloseSvg = styled.img`
  width: ${Spacing(4.5)};
  height: ${Spacing(4.5)};
  cursor: pointer;
  transition: ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
`;

const FilterHeader = styled.div`
  display: flex;
  margin-bottom: ${Spacing(6)};
  justify-content: space-between;
  align-items: center;
`;

const ModalWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: ${Spacing(5)};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(46, 60, 77, 0.6);
  backdrop-filter: blur(5px);
  z-index: 1000;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  ${({ open }) =>
    open
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${Spacing(4)};
  max-width: ${Spacing(150)};
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease;
  overflow: scroll;
  max-height: 60%;

  @keyframes fadeIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

import VALIDATION from './validation.json';
import ERROR from './error.json';
import AUTH from './auth.json';
import SETTINGS from './settings.json';
import WELLCOME from './wellcome.json';
import COMMON from './common.json';
import NAVIGATION from './navigation.json';
import LANG from './lang.json';
import PROJECT from './project.json';
import OFFICE from './office.json';
import USER from './user.json';
import LANDING from './landing.json';
import CATEGORY from './category.json';
import CARETAKER_LIST from './caretaker-list.json';
import PAYMENT from './payment.json';
import ORDER from './order.json';
import SCHEDULE from './schedule.json';
import KNOWLEDGE_LANDING from './knowledge-landing.json';
import KNOWLEDGE from './knowledge.json';
import CONSULTATION from './consultation.json';
import PRIVACY_POLICY from './privacy-policy.json';
import USER_AGREEMENT from './user-agreement.json';
import PUBLIC_CONTRACT from './public-contract.json';
import PUBLIC_AGREEMENT from './public-agreement.json';
import COOKIE_USE_POLICY from './cookie-use-policy.json';

export const UA = {
  translation: {
    VALIDATION,
    SCHEDULE,
    ERROR,
    AUTH,
    SETTINGS,
    WELLCOME,
    COMMON,
    NAVIGATION,
    LANG,
    PROJECT,
    OFFICE,
    USER,
    LANDING,
    CATEGORY,
    CARETAKER_LIST,
    PAYMENT,
    ORDER,
    KNOWLEDGE_LANDING,
    KNOWLEDGE,
    CONSULTATION,
    PRIVACY_POLICY,
    USER_AGREEMENT,
    PUBLIC_CONTRACT,
    PUBLIC_AGREEMENT,
    COOKIE_USE_POLICY,
    CONSULTATION,
  },
};

import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { PaddingContainer } from '../home-footer/component';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { PropsComponent } from './constant';

export const Component: React.FC<PropsComponent> = ({ svgSrc, longText }) => {
  return (
    <BodyContainer>
      <AboutContainer>
        <AboutBlock>
          <AboutText>
            <AboutHeadTitle fontFamily="Raleway">
              <HeadTitle>
                <HeadTitlePrimary>
                  {i18n.t('LANDING.HOME_WORK.HEAD_TITLE.TITLE_1')}
                </HeadTitlePrimary>

                <HeadTitleHighlight>
                  {i18n.t('LANDING.HOME_WORK.HEAD_TITLE.TITLE_2')}
                </HeadTitleHighlight>
              </HeadTitle>
            </AboutHeadTitle>

            <StepsContainer>
              <Grafic src={svgSrc} alt="graf svg" />
              <StepTextLeft>
                {i18n.t('LANDING.HOME_WORK.STEPS.ONE')}
              </StepTextLeft>

              <StepTextRight>
                {longText ? longText : i18n.t('LANDING.HOME_WORK.STEPS.TWO')}
              </StepTextRight>

              <StepTextLeft>
                {longText ? longText : i18n.t('LANDING.HOME_WORK.STEPS.THREE')}
              </StepTextLeft>

              <StepTextRight>
                {longText ? longText : i18n.t('LANDING.HOME_WORK.STEPS.FOUR')}
              </StepTextRight>

              <StepTextLeft>
                {longText ? longText : i18n.t('LANDING.HOME_WORK.STEPS.FIVE')}
              </StepTextLeft>
            </StepsContainer>
          </AboutText>
        </AboutBlock>
      </AboutContainer>
    </BodyContainer>
  );
};

const BodyContainer = styled(PaddingContainer)`
  background: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  gap: ${Spacing(2.5)};
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  padding: ${Spacing(25)} ${Spacing(25)} ${Spacing(12.75)} ${Spacing(25)};
  margin: 0 auto;
  min-height: 530px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: ${Spacing(350)};
`;

const AboutBlock = styled.div`
  display: grid;
  gap: ${Spacing(8)};
  text-align: center;
`;

const AboutText = styled.div`
  display: grid;
  gap: ${Spacing(6)};
`;

const AboutHeadTitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const HeadTitle = styled.div`
  display: grid;
  gap: ${Spacing(3)};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN_HEAD_TITLE]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]}px;
  margin-top: -83px;
`;

const HeadTitlePrimary = styled.p`
  display: flex;
  justify-content: center;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LOGO]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD]};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};

  @media (max-width: 430px) {
    display: inline-block;
    text-align: left;
    white-space: nowrap;
    transform: translateX(-90px);
  }
`;

const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 208px 60px;
  align-items: center;

  @media (max-width: 730px) {
    margin-top: 27px;
    grid-template-columns: 1fr;
    gap: 73px;
    text-align: right;
  }

  @media (max-width: 430px) {
    margin-top: 6px;
    margin-left: -17px;
    grid-template-columns: 1fr;
    gap: 51px;
    text-align: right;
  }
`;

const StepTextLeft = styled.p`
  display: flex;
  justify-content: flex-end;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.HEADING]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  line-height: 30px;
  text-align: left;
  transform: translateY(10px);

  @media (max-width: 730px) {
    justify-content: flex-start;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.HEADING]}px;
    transform: translateY(0);
    line-height: 28px;
  }

  @media (max-width: 430px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px;
  }
`;

const StepTextRight = styled.p`
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.HEADING]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  line-height: 30px;
  text-align: left;
  transform: translateY(135px);

  @media (max-width: 730px) {
    transform: translateY(0);
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.HEADING]}px;
    text-align: left;
    transform: translateY(0);
  }

  @media (max-width: 430px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px;
  }
`;

const HeadTitleHighlight = styled.span`
  display: flex;
  justify-content: center;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]}px;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};

  @media (max-width: 430px) {
    display: inline-block;
    white-space: nowrap;
    text-align: left;
    transform: translateX(-90px);
  }
`;

const Grafic = styled.img`
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10%;
  height: 80%;

  @media (max-width: 730px) {
    left: 0px;
    transform: translate(0px, -50%);
  }

  @media (max-width: 430px) {
    left: 13px;
    top: 305px;
    width: 55px;
    transform: translate(0px, -50%);
  }
`;

import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import deleteIcon from '../../../asset/svg/button/delete-weekend.svg';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

export const ScheduleItem: React.FC<{
  time: string[];
  day: string;
  deleteAction: () => void;
  isWeekend: boolean;
}> = ({ day, deleteAction, time, isWeekend }) => {
  return (
    <>
      <>
        <ContainerDesktop>
          <TextElem size="semiSmall" color="textPrimary" tid={day} />
          <TimeContainer>
            {!isWeekend && (
              <>
                {time.map((e) => (
                  <TextElem
                    key={e}
                    tid="SCHEDULE.WEEKEND.LIST.START_TIME_VALUE"
                    tvalue={{ value: e }}
                    size="input"
                    color="textPrimary"
                  />
                ))}
              </>
            )}
            {isWeekend && (
              <TextElem
                tid="SCHEDULE.WEEKEND.LIST.WEEKEND"
                size="input"
                color="textPrimary"
              />
            )}
          </TimeContainer>

          <ButtonStyled onClick={deleteAction}>
            <DeleteImg src={deleteIcon} alt="close menu" />
          </ButtonStyled>
        </ContainerDesktop>
        <ContainerMobile>
          <FlexContainer>
            <TextElem size="input" color="textPrimary" tid={day} />
            <TimeContainer>
              {!isWeekend && (
                <>
                  {time.map((e) => (
                    <TextElem
                      key={e}
                      tid="SCHEDULE.WEEKEND.LIST.START_TIME_VALUE"
                      tvalue={{ value: e }}
                      size="input"
                      color="textPrimary"
                    />
                  ))}
                </>
              )}
              {isWeekend && (
                <TextElem
                  tid="SCHEDULE.WEEKEND.LIST.WEEKEND"
                  size="input"
                  color="textPrimary"
                />
              )}
            </TimeContainer>
            <ButtonStyled
              onClick={(e) => {
                e.stopPropagation();
                deleteAction();
              }}
            >
              <DeleteImg src={deleteIcon} alt="close menu" />
            </ButtonStyled>
          </FlexContainer>
        </ContainerMobile>
      </>
    </>
  );
};

const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const DeleteImg = styled.img`
  min-width: 18px;
  min-height: 18px;

  max-width: 16px;
  max-height: 16px;

  @media screen and (max-width: 1160px) {
    min-width: 16px;
    min-height: 16px;

    max-width: 16px;
    max-height: 16px;
  }
`;

const TimeContainer = styled.div`
  display: flex;
  gap: ${Spacing(1)};
  flex-wrap: wrap;
`;

const ContainerDesktop = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr auto;
  width: 100%;
  padding: 14px ${Spacing(4)};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  align-items: center;

  @media screen and (max-width: 1160px) {
    display: none;
  }

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};

  :hover {
    cursor: pointer;
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const ContainerMobile = styled.div`
  display: none;
  width: 100%;
  padding: ${Spacing(3)};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  @media screen and (max-width: 1160px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${Spacing(3)};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1160px) {
    gap: ${Spacing(3)};
  }
`;

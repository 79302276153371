import React from 'react';

import { useDispatch } from '../../lib/store';
import { ACTION_ERROR_INTER, ACTION_RESPONSE_INTER } from './constant';

import { useMutation } from 'react-query';

import { useHistory } from 'react-router';
import { setAuthData } from '../../data/auth/action';
import styled from 'styled-components';
import { ButtonElem } from '../../common/button';
import { COLOR_ENUM } from '../../theme/color';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { fetch } from './action';
import { useGoogleLogin } from '@react-oauth/google';
import { AUTH_LOGIN_ORDER_LOCAL_STORE } from '../order-auth-signup/constant';
import googleIcon from '../../asset/svg/auth/google.svg';
import { SIZE_FONT_DATA, SIZE_FONT_ENUM } from '../../theme/size';
import { appPreloadAllLocalStateData } from '../../data/app';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';
import { CONSULTATION_LIST_PAGE_PATH } from '../../page/consultation-list';

export const Container: React.FC<{ id?: number }> = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSuccess = (d: any, values: any) => {
    const data: ACTION_RESPONSE_INTER = d as unknown as ACTION_RESPONSE_INTER;

    dispatch(setAuthData(data.accessToken)).then(async () => {
      const authLoginOrderPath = localStorage.getItem(
        AUTH_LOGIN_ORDER_LOCAL_STORE,
      );

      await dispatch(appPreloadAllLocalStateData()).then(() => {
        if (!id) {
          history.push(CONSULTATION_LIST_PAGE_PATH);
        }

        if (authLoginOrderPath) {
          history.push(authLoginOrderPath);
        } else if (id) {
          history.push(CONSULTATION_PAGE_PATH_DYNAMIC(id));
        } else {
          // history.push(CONSULTATION_LIST_PAGE_PATH);
          history.push('/specialist-list');
        }
      });
    });
  };

  const action = useMutation((...props) => fetch(...props, id), { onSuccess });

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      try {
        action.mutate(tokenResponse);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <Button
        tid="AUTH.LOGIN.GOOGLE"
        iconLeft={googleIcon}
        // iconSize="small"
        type="button"
        padding="15px 20px"
        onClick={login}
      />

      {isLoading() && <LoaderElem />}
      {isError() && <AlertActionElem type="error" tid={getErrorMessage()} />}
    </>
  );
};

const Button = styled(ButtonElem)`
  background: #f2f2f2 !important;

  div {
    justify-content: center;
  }

  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]} !important;
  }
`;

export const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const scrollToSectionCenter = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }
};

export const scrollToSectionAdaptive = (id: string) => {
  const element = document.getElementById(id);
  if (!element) return;

  const elementHeight = element.offsetHeight;
  const viewportHeight = window.innerHeight;

  element.scrollIntoView({
    block: elementHeight > viewportHeight ? 'start' : 'center',
    behavior: 'smooth',
  });
};

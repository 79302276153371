import { TIMEZONE_ENUM, TIMEZONE_FORMAT } from '../../data/user/timezone';

export function convert(
  data: {
    date: Date;
    time: { value: string; label: string }[];
  },
  timezone: TIMEZONE_ENUM,
): string[] {
  const year = data.date.getFullYear();
  const month = String(data.date.getMonth() + 1).padStart(2, '0');
  const day = String(data.date.getDate()).padStart(2, '0');

  const timezoneString = TIMEZONE_FORMAT[timezone];

  const result: string[] = data.time.map((timeObj) => {
    const [hours, minutes] = timeObj.value.split(':').map(Number);
    const seconds = 0;
    const milliseconds = 0;

    return `${year}-${month}-${day}T${String(hours).padStart(2, '0')}:${String(
      minutes,
    ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(
      milliseconds,
    ).padStart(3, '0')}${timezoneString}`;
  });

  return result;
}

import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import { CopyElem } from '../../../common/copy';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { i18n } from '../../../lib/lang';
import copyIcon from '../../../asset/svg/alert/copy.svg';

export const InviteContainer: React.FC<{ isUser?: boolean; id: string }> = ({
  isUser,
  id,
}) => {
  const link = i18n.t('CONSULTATION.ADMIN.INVITE_LINK', { value: id });
  return (
    <MainContainer>
      <TextElem
        lineHeight="0.8em"
        tid="CONSULTATION.ADMIN.INVITE_TITLE"
        type="medium"
        size="main"
        color="textPrimary"
      />
      {isUser && (
        <CopyElem value={link}>
          <LinkContainer>
            <TextElemStlyed
              tid="CONSULTATION.ADMIN.INVITE_LINK"
              tvalue={{ value: id }}
              size="semiSmall"
              color="default"
              lineHeight="1.2em"
            />
            <CopyIconStyled src={copyIcon} alt="copy" />
          </LinkContainer>
        </CopyElem>
      )}
      {!isUser && (
        <LinkContainer>
          <TextElem
            tid="CONSULTATION.ADMIN.INVITE_DESCRIPTION"
            size="semiSmall"
            color="textSecondary"
            lineHeight="1.2em"
          />
        </LinkContainer>
      )}
    </MainContainer>
  );
};

const TextElemStlyed = styled(TextElem)`
  text-decoration: underline;
`;

const CopyIconStyled = styled.img`
  min-width: ${Spacing(4)};
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${Spacing(3)};

  padding: ${Spacing(3)} ${Spacing(4)};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  min-height: 46px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${Spacing(3)};
  padding: ${Spacing(6)};
  gap: ${Spacing(4)};
  height: max-content;
`;

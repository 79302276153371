import React from 'react';
import styled, { css } from 'styled-components';

import swipeIcon from '../../asset/svg/menu-app/swipe.svg';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { SwipeableHandlers } from 'react-swipeable';
import { USER_ROLE } from '../../data/auth/constant';

export const Component: React.FC<{
  role: USER_ROLE;
  menuData: any;
  isActive: (path: string) => boolean;
  redirect: (path: string) => void;
  openDropdown: string | null;
  swipeHandlers: SwipeableHandlers;
  toggleMenu: (path: string) => void;
  // isAdmin: boolean;
}> = ({
  menuData,
  swipeHandlers,
  isActive,
  // isAdmin,
  redirect,
  openDropdown,
  toggleMenu,
  role,
}) => {
  const renderMenuItems = (items: any[], moreItems: any[]) =>
    items.map((item: any) => {
      if (item.isVisible && item.isVisible !== role) {
        return <></>;
      }
      return (
        <MenuElement
          key={item.tid}
          isActive={isActive(item.path)}
          onClick={(event) => {
            event.preventDefault();
            item.path ? redirect(item.path) : toggleMenu(item.tid);
          }}
        >
          <ImgStyled
            src={
              item.path
                ? isActive(item.path)
                  ? item.imgActive
                  : item.img
                : openDropdown === item.tid
                ? item.imgActive
                : item.img
            }
          />

          <TextElem
            color={isActive(item.path) ? 'default' : 'textSecondary'}
            size="verySmall"
            type={isActive(item.path) ? 'medium' : 'regular'}
            oneLine
            tid={item.tid}
          />

          {openDropdown === item.tid && (
            <DropDownMenu isVisible={openDropdown === item.tid}>
              <CloseContainer {...swipeHandlers}>
                <img
                  src={swipeIcon}
                  alt="swipe"
                  style={{ height: '5px', width: '42px' }}
                />
              </CloseContainer>
              {moreItems.map((mobailItem: any) => (
                <IconBlock
                  isActive={isActive(mobailItem.path)}
                  key={mobailItem.tid}
                  onClick={(event) => {
                    event.preventDefault();
                    redirect(mobailItem.path);
                  }}
                >
                  <ImgDropDown
                    src={
                      isActive(mobailItem.path)
                        ? mobailItem.imgActive
                        : mobailItem.img
                    }
                  />
                  <TextElemStyled
                    color={isActive(item.path) ? 'default' : 'textSecondary'}
                    size="default"
                    type={isActive(item.path) ? 'medium' : 'regular'}
                    oneLine
                    tid={mobailItem.tid}
                  />
                </IconBlock>
              ))}
            </DropDownMenu>
          )}
        </MenuElement>
      );
    });

  return (
    // <Wrapper>
    <Container>
      {role === USER_ROLE.ADMIN
        ? renderMenuItems(menuData.admin, menuData.adminMore)
        : renderMenuItems(menuData.user, menuData.userMore)}
    </Container>
    // </Wrapper>
  );
};

const DropDownMenu = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  padding: ${Spacing(4)};
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  border-top-left-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD
  ]}px;
  border-top-right-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD
  ]}px;
  opacity: 1;
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
  // border-top: 1px solid ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
`;

const ImgStyled = styled.img`
  height: 22px;
  width: 22px;
`;

const ImgDropDown = styled.img`
  height: 18px;
  width: 18px;
`;

const TextElemStyled = styled(TextElem)``;
const IconBlock = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: flex-start;
  align-items: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  padding: ${Spacing(4)};
  gap: ${Spacing(2.5)};

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${theme[COLOR_ENUM.MENU_TAB]};
      & ${TextElemStyled} {
        color: ${theme[COLOR_ENUM.DEFAULT]} !important;
      }
    `};

  ${({ isActive, theme }) =>
    !isActive &&
    css`
      &:hover {
        background: ${theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
        // & ${TextElemStyled} {
        //   color: ${theme[COLOR_ENUM.TEXT_PRIMARY]} !important;
        // }
      }
    `};
`;

const MenuElement = styled.div<{ isActive: boolean }>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: ${Spacing(2)};
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Container = styled.div<{}>`
  display: flex;
  gap: ${Spacing(7)};
  max-width: ${Spacing(400)};
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  width: 100%;

  @media (max-width: 360px) {
    gap: ${Spacing(4)};
  }
`;

const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${Spacing(4)};
`;

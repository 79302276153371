import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import {
  SIZE_GRID_TYPE,
  SIZE_GRID_DATA,
  SIZE_GRID_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';

export const Elem: React.FC<{
  children: ReactNode;
  size?: SIZE_GRID_TYPE;
  spacing?: number;
  className?: string;
  style?: Object;
  onClick?: (event: MouseEvent) => void;
  justifyContent?:
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'end';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'end';
  flexDirection?: 'row' | 'column';
}> = ({
  style,
  children,
  size,
  spacing,
  className,
  onClick,
  justifyContent = 'flex-start',
  alignItems = 'start',
  flexDirection = 'row',
}) => {
  return (
    <Flex
      className={className}
      size={size}
      style={style}
      spacing={spacing}
      onClick={onClick}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexDirection={flexDirection}
    >
      {children}
    </Flex>
  );
};

const Flex = styled.div<{
  size?: SIZE_GRID_TYPE;
  spacing?: number;
  onClick: any;
  justifyContent: string;
  alignItems: string;
  flexDirection: string;
}>`
  display: flex;
  width: 100%;
  ${({ justifyContent, alignItems, flexDirection }) => css`
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    flex-direction: ${flexDirection};
  `}
  ${({ size = SIZE_GRID_ENUM.DEFAULT, spacing }) =>
    spacing !== undefined
      ? css`
          grid-gap: ${Spacing(spacing)};
        `
      : css`
          grid-gap: ${SIZE_GRID_DATA[size]}px;
        `}
`;
